module App.Controllers {
    export class LateBlightIPMRecordController extends BaseRecordController<Models.LateBlightIPMRecord> {
        record: Models.LateBlightIPMRecord;

        editingManagementAction: boolean;
        managementAction: Models.LateBlightManagementAction;

        originalPlantingRecordId: number;
        originalPlantingRecordNumber: string;

        controls: {
            plantingRecords: Models.PlantingRecord[];
        }

        public addManagementAction() {
            this.editingManagementAction = true;
            this.managementAction = new Models.LateBlightManagementAction();
        }

        public disableAddManagementAction(): boolean {
            return this.isInEditMode || this.editingManagementAction || !this.record.record.canEdit;
        }

        public disableSaveManagementAction(): boolean {
            return this.isInEditMode || !this.editingManagementAction || !this.record.record.canEdit || !this.validateManagementAction();
        }

        public validateManagementAction(): boolean {
            let valid = this.managementAction && this.managementAction.actionDate && this.managementAction.actionTaken && this.managementAction.actionTaken.length > 0;
            return valid;
        }

        public saveManagementAction() {
            if (this.managementAction.id) {
                let myIdx = _.findIndex(this.record.lateBlightManagementActions, x => x.id === this.managementAction.id)
                if (myIdx >= 0) {
                    this.record.lateBlightManagementActions[myIdx] = angular.copy(this.managementAction);
                }
            }
            else {
                let ma = angular.copy(this.managementAction);
                this.record.lateBlightManagementActions.push(ma);
            }
            this.save();
            this.resetManagementAction();
        }

        public editManagementAction(actionId: number) {
            if (!this.isInEditMode && !this.editingManagementAction && this.record.record.canEdit) {
                this.editingManagementAction = true;
                let editThisOne = _.find(this.record.lateBlightManagementActions, { id: actionId });
                this.managementAction = angular.copy(editThisOne);
            }
        }

        public resetManagementAction() {
            this.editingManagementAction = false;
            this.clearManagementAction();
        }

        public clearManagementAction() {
            this.managementAction = null;
        }

        public deleteManagementAction(actionId: number) {
            this.record.lateBlightManagementActions = this.record.lateBlightManagementActions.filter(a => { return a.id !== actionId });
            this.save();
        }

        /**
         * Sets the controls.plantingRecords options
         */
        private _setAvailablePlantingRecords(accountId: number) {
            if (accountId) {
                let existing = this.record.id ? this.record.id : 0;
                this.RecordService.getRecords(Models.PlantingRecord, 'planting', { accountId: accountId, lateBlightIPMRecordId: existing, lateBlightIPM: false }).then(plantingRecords => {
                    this.controls.plantingRecords = plantingRecords;
                    // make sure existing one is in the list
                    if (this.record.PlantingRecord && this.record.PlantingRecord.id) {
                        let idx = _.findIndex(plantingRecords, pr => pr.id == this.record.PlantingRecord.id);
                        if (idx < 0) {
                            this.controls.plantingRecords.push(this.record.PlantingRecord);
                        }
                    }
                    // if only one, select it
                    else if (this.controls.plantingRecords.length === 1 && this.isInEditMode) {
                        this.record.PlantingRecord = plantingRecords[0];
                    }
                });
            } else {
                this.controls.plantingRecords = [];
                this.record.plantingRecord = null;
                this.record.record.Variety = null;
                this.record.record.Site = null;
                this.record.record.Location = null;
            }
        }

        static $inject = [
            'record',
            'plantingRecord',
            'accounts',
            'controls',
            'AccountService',
            'RecordService',
            '$state',
            '$scope',
            '$modal',
            'toaster'
        ];
        constructor(
            record: Models.LateBlightIPMRecord,
            plantingRecord: Models.PlantingRecord,
            accounts: Models.Account[],
            controls,
            private AccountService: Services.AccountService,
            private RecordService: Services.RecordService,
            $state: ng.ui.IStateService,
            $scope: ng.IScope,
            $modal: ng.ui.bootstrap.IModalService,
            toaster: ng.toaster.IToasterService
        ) {
            super(record, accounts, controls, $state, $modal, toaster);

            this.editingManagementAction = false;

            if (this.record.PlantingRecord == null) {
                this.originalPlantingRecordId = 0;
                this.originalPlantingRecordNumber = "";
            }
            else {
                this.originalPlantingRecordId = this.record.PlantingRecord.id;
                this.originalPlantingRecordNumber = this.record.PlantingRecord.number;
            }

            // if plantingRecord was passed in fill in info
            if (plantingRecord) {
                this.record.PlantingRecord = plantingRecord;
                this.record.record.Account = this.record.PlantingRecord.record.Account;
                this.record.record.Location = this.record.PlantingRecord.record.Location;
                this.record.record.Site = this.record.PlantingRecord.record.Site;
                this.record.record.Variety = this.record.PlantingRecord.record.Variety;
            }

            /**
             * watch record.accountId for changes and do stuff.
             */
            $scope.$watch(() => {
                return this.record.record.accountId;
            }, (newAccountId, oldAccountId) => {
                this._setAvailablePlantingRecords(newAccountId);

                if (parseInt(newAccountId) !== parseInt(oldAccountId) && oldAccountId) {
                    this.record.plantingRecord = null;
                    this.record.record.Variety = null;
                    this.record.record.Site = null;
                    this.record.record.Location = null;
                }
            });

            /**
             * watch planting report for changes and do stuff.
             */
            $scope.$watch(() => {
                return this.record.PlantingRecord;
            }, (newPlantingReport, oldPlantingReport) => {
                if (newPlantingReport) {
                        this.record.record.Location = newPlantingReport.record.Location;
                        this.record.record.Site     = newPlantingReport.record.Site;
                        this.record.record.Variety  = newPlantingReport.record.Variety;
                }
                else {
                    this.record.record.Variety = null;
                    this.record.record.Site = null;
                    this.record.record.Location = null;
                }
            });

        }

        /**
         * Resolve all promises before loading the controller
         * 
         * @Param isCreateMode  boolean
         */
        resolve(isCreateMode = false): { record: Function; plantingRecord: Function; accounts: Function; controls: Function; } {
            getRecord.$inject = ['$stateParams', 'RecordService'];

            function getPlantingRecord($stateParams: ng.ui.IStateParamsService, RecordService: Services.RecordService): Models.PlantingRecord | ng.IPromise<Models.PlantingRecord> {
                if (isCreateMode && $stateParams['plantingId']) {
                    return RecordService.getRecord<Models.PlantingRecord>($stateParams['plantingId'], Models.PlantingRecord, 'planting');
                }
                return null;
            }

            function getRecord($stateParams: ng.ui.IStateParamsService, RecordService: Services.RecordService): Models.LateBlightIPMRecord | ng.IPromise<Models.LateBlightIPMRecord> {
                if (isCreateMode) {
                    var record = new Models.LateBlightIPMRecord().load({});
                    return record;
                } else {
                    return RecordService.getRecord<Models.LateBlightIPMRecord>($stateParams['id'], Models.LateBlightIPMRecord, 'lateblightipm');
                }
            }

            getAccounts.$inject = ['AccountService', '$rootScope'];
            function getAccounts(AccountService: Services.AccountService, $rootScope: ng.IRootScopeService): Models.Account[] | ng.IPromise<Models.Account[]> {
                if ($rootScope.user.can.viewAccountList) {
                    return AccountService.getAccounts({ 'recordTypeName': 'Planting' });
                }
                else {
                    return [$rootScope.user.account];
                }
            }

            getControls.$inject = ['LookupService', '$q'];
            function getControls(LookupService: Services.LookupService, $q: ng.IQService) {
                return $q.all([LookupService.getGenericLookup('SigningMessage', true)]).then((data) => {
                    return {
                        signingMessage: data[0]
                    };
                });
            }

            return {
                record: getRecord,
                plantingRecord: getPlantingRecord,
                accounts: getAccounts,
                controls: getControls
            }
        }
    }
}