module App.Controllers {
    UserNotFoundRoute.$inject = ['$stateProvider'];
    export function UserNotFoundRoute($stateProvider: ng.ui.IStateProvider) {
        $stateProvider.state('user-not-found', {
            url: '/user-not-found',
            template: '<h3>Your user account has not been configured to use this application.</h3>',
            title: 'User Not Found'
        });
    }
}

angular.module('app').config(App.Controllers.UserNotFoundRoute);