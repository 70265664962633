module App.Controllers {
    export class ArchiveRecordsController {
        records: any; 

        controls: {
            recordTypes: string[];
            startDate: Date;
            endDate: Date;
            type: string;
            archiveChanged: boolean;
        }

        friendlyRecordTypeName(recordTypeName: string): string {
            // call the global helper
            return friendlyRecordTypeName(recordTypeName);
        }

        changeReportType() {
            let selectedRecordType = (this.controls.type === 'all') ? null : this.controls.type;
            this.RecordService.getRecords(null, selectedRecordType, { onlyArchivable: true }).then((records) => {
                this.records = records;
                this.controls.archiveChanged = false;
            });
        }

        changeArchiveFlag() {
            this.controls.archiveChanged = true;
        }

        archiveRecordsBulk() {
            this.RecordService.archiveRecordsBulk(this.controls.type, this.controls.startDate, this.controls.endDate).then(() => {
                // update records list on second tab in case they navigate there
                this.changeReportType();
            });
        }

        archiveRecordsUpdate() {
            // pull out the id and isArchived flag
            let recs: { id: number; isArchived: boolean }[] = [];
            for (var x of this.records) {
                recs.push({ id: x.record.id, isArchived: x.record.isArchived });
            }

            this.RecordService.archiveRecordsUpdate(recs)
                .then(() => {
                    // Need to retrieve records again to get updated LastArchivedOn in case it is resent
                    this.changeReportType();
                });
        }

        static $inject = ['records', 'controls', 'RecordService'];
        constructor(records, controls, private RecordService: Services.RecordService) {
            this.records = records;
            this.controls = controls;
        }

    }

    ArchiveRecordsControllerRoute.$inject = ['$stateProvider'];
    export function ArchiveRecordsControllerRoute($stateProvider: angular.ui.IStateProvider) {
        $stateProvider.state('archive-records', {
            url: '/archive-records',
            abstract: true,
            template: '<ui-view/>'
        })
            .state('archive-records.index', {
                url: '/:type',
                templateUrl: 'views/records-archive.html',
                controller: ArchiveRecordsController,
                controllerAs: 'vm',
                title: 'Archive Reports',
                resolve: {
                    records: ['$stateParams', 'RecordService', ($stateParams: ng.ui.IStateParamsService, RecordService: Services.RecordService) => {
                        return RecordService.getRecords(null, $stateParams['type'], { onlyArchivable: true });
                    }],
                    controls: ['$stateParams', 'LookupService', '$q',
                        ($stateParams: ng.ui.IStateParamsService, LookupService: Services.LookupService, $q: ng.IQService) => {
                        return $q.all([LookupService.getAllRecordTypes()]).then((data) => {
                            var _controls = {
                                recordTypes: data[0],
                                type: $stateParams['type'] || 'all',
                                archivedChanged: false
                            };
                            return _controls;
                        });
                    }]


                }
            });
    }


}

angular.module('app').config(App.Controllers.ArchiveRecordsControllerRoute);   