module App.Services {
    export class AuthService {

       getUser() {
            var authToken = localStorage.getItem('authToken');
            if (authToken) {
                this.setAuthToken(authToken);
            }
            return this.ApiService.getItem<Deregulated.IUser>('users', 'current')
                .then(
                    user => {
                        this.$rootScope.user = user;
                        this.addPermissions(user);
                        this.$state.go('user-not-found');
                        return user;
                    },
                    reason => {
                        if (reason.status === 0) // Redirect to ADFS
                        {
                            this.$window.location.href = '/login';
                        } else if (reason.status === 400) {
                            this.$state.go('user-not-found');
                        } else if (reason.status === 401) {
                            this.$state.go('login');
                        }
                    }
                );
        }

        private setUser = (user: Deregulated.IUser) => {
            if (user !== undefined) {
                this.$rootScope.user = user;
            }
        }

        authenticateUser = (username: string, password: string) => {
            return this.ApiService.post('users/login', { username: username, hashedpassword: password }, false).then(
                (token) => {
                    // Handle successful login.
                    localStorage.setItem('authToken', token);
                    this.setAuthToken(token);
                    return token;
                });
        }

        setAuthToken(token: string) {
            this.$http.defaults.headers.common.Authorization = 'Basic ' + btoa(token);
        }

        revokeAuthToken() {
            localStorage.clear();
            this.$http.defaults.headers.common.Authorization = '';
        }

        addPermissions(user) {
            var usablePermissions = {};

            for (var i = 0; i < user.role.permissions.length; i++) {
                usablePermissions[toCamelCase(user.role.permissions[i].name)] = true;
            }

            user.can = usablePermissions;
        }

        logout = () => {
            if (localStorage.getItem('authToken')) {
                this.revokeAuthToken();
                this.setUser(null);
                this.$state.go('login');
            } else {
                this.$window.location.href = '/adfslogout';
            }
        }

        externalLogin = () => {
            this.$state.go('login');
        }

        static ID = 'AuthService';
        static $inject = ['$window', 'ApiService', '$http', '$rootScope', '$state'];
        constructor(private $window: ng.IWindowService, private ApiService: App.Services.ApiService, private $http: ng.IHttpService, private $rootScope: ng.IRootScopeService, private $state: ng.ui.IStateService) {

        }
    }
}

angular.module('app').service('AuthService', App.Services.AuthService);



