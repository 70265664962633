module App.Controllers {
    export class SeedPlantingRecordController extends BaseRecordController<Models.SeedPlantingRecord> {
        model = Models.SeedPlantingRecord;

        record: Models.SeedPlantingRecord;

        editingLineItem: boolean;

        disposalNotesLabel: string = 'Disposal Notes';
        seedDisposalNotesLabel: string = 'Seed Disposal Notes';

        clearWasteQuestions() {
            this.record.WasteDisposalMethod = null;
            this.record.wasteProperlyDisposed = null;
            this.record.wasteDisposalNotes = null;
        }

        clearSeedQuestions() {
            this.record.SeedDisposalMethod = null;
            this.record.seedProperlyDisposed = null;
            this.record.seedDisposalNotes = null;
        }

        static $inject = ['record', 'accounts', 'controls', '$state', '$modal', '$scope', 'toaster'];
        constructor(
            record: Models.SeedPlantingRecord,
            accounts: Models.Account[],
            controls,
            $state: ng.ui.IStateService,
            $modal: ng.ui.bootstrap.IModalService,
            $scope: ng.IScope,
            toaster: ng.toaster.IToasterService
        ) {
            super(record, accounts, controls, $state, $modal, toaster);

            this.record.WasteDisposalMethod = <Deregulated.IDisposalMethod>_.findWhere(controls.disposalMethods, { id: record.wasteDisposalMethodId });
            this.record.SeedDisposalMethod = <Deregulated.IDisposalMethod>_.findWhere(controls.disposalMethods, { id: record.seedDisposalMethodId });

            $scope.$watch(() => {
                return this.record.WasteDisposalMethod;
            }, (newValue: Deregulated.IDisposalMethod, oldValue) => {
                this.disposalNotesLabel = BaseRecordController.getDisposalNotesLabel(newValue);
            });
            $scope.$watch(() => {
                return this.record.SeedDisposalMethod;
            }, (newValue: Deregulated.IDisposalMethod, oldValue) => {
                this.seedDisposalNotesLabel = BaseRecordController.getDisposalNotesLabel(newValue);
            });
        }
        
        /**
         * Resolve all promises before loading the controller
         * 
         * @Param isCreateMode  boolean
         */
        resolve(isCreateMode = false): { record: Function; accounts: Function; controls: Function; } {
            getRecord.$inject = ['$stateParams', 'RecordService'];
            function getRecord($stateParams: ng.ui.IStateParamsService, RecordService: Services.RecordService): Models.SeedPlantingRecord | ng.IPromise<Models.SeedPlantingRecord> {
                if (isCreateMode) {
                    var record = new Models.SeedPlantingRecord().load({});
                    return record;
                } else {
                    return RecordService.getRecord<Models.SeedPlantingRecord>($stateParams['id'], Models.SeedPlantingRecord, 'seedplanting');
                }
            }

            getAccounts.$inject = ['AccountService', '$rootScope'];
            function getAccounts(AccountService: Services.AccountService, $rootScope: ng.IRootScopeService): Models.Account[] | ng.IPromise<Models.Account[]> {
                if ($rootScope.user.can.viewAccountList) {
                    return AccountService.getAccounts({ 'recordTypeName': 'SeedPlanting' });
                }
                else {
                    return [$rootScope.user.account];
                }
            }

            getControls.$inject = ['LookupService', '$q'];
            function getControls(LookupService: Services.LookupService, $q: ng.IQService) {
                return $q.all([LookupService.getDisposalMethods('SeedPlanting'), LookupService.getFieldGenerations(), LookupService.getGenericLookup('SigningMessage', true)]).then((data) => {
                    return {
                        disposalMethods: data[0],
                        fieldGenerations: data[1],
                        signingMessage: data[2]
                    };
                });
            }

            return {
                record: getRecord,
                accounts: getAccounts,
                controls: getControls
            }
        }
    }
} 