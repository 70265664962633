module App.Directives {
    export function NumberMaskDirective() {

        var link: ng.IDirectiveLinkFn = function (scope, element, attrs) {
            var pattern = /^(\-?\d*(\.\d*)?)$/;
            if (attrs['numberMask'] && attrs['numberMask'].charAt(0) !== '/') {
                throw Error('RegEx pattern missing delimiter');
            }
            if (attrs['numberMask'] && attrs['numberMask'].charAt(0) === '/') {
                var match = attrs['numberMask'].match(/^\/(.+)\/([a-z]*)$/);
                if (match) {
                    pattern = new RegExp(match[1], match[2]);
                }
            }
            scope.$watch(() => scope['ngModel'], (ngModelValue: string, oldNgModelValue: string) => {
                if (ngModelValue && !pattern.test(ngModelValue)) {
                    scope['ngModel'] = oldNgModelValue;
                }
            });
        }

        return {
            scope: {
                ngModel: '='
            },
            link: link
        }
    }
}

angular.module('app').directive('numberMask', App.Directives.NumberMaskDirective);