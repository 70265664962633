module App.Controllers {
    export class PlantingRecordController extends BaseRecordController<Models.PlantingRecord> {
        model = Models.PlantingRecord;

        record: Models.PlantingRecord;

        disposalNotesLabel: string = 'Disposal Notes';
        seedDisposalNotesLabel: string = 'Seed Disposal Notes';

        clearSeedQuestions() {
            this.record.SeedDisposalMethod = null;
            this.record.seedProperlyDisposed = null;
            this.record.seedDisposalNotes = null;
        }

        clearWasteQuestions() {
            this.record.WasteDisposalMethod = null;
            this.record.wasteProperlyDisposed = null;
            this.record.wasteDisposalNotes = null;
        }

        showLateBlightRequired(): boolean {
            // if there isn't a late blight record
            // and the variety is set and it is late blight protected
            // and the location is set and (location's lb area is not set or is not other)
            return !this.record.lateBlightIPMRecord &&
                this.record.record.Variety && this.record.record.Variety.lateBlightProtected &&
                this.record.record.Location && (!this.record.record.Location.lateBlightArea ||
                    this.record.record.Location.lateBlightArea.name !== 'Other');
        }

        createLateBlightIPMRecord() {
            this.$state.go(('records.lateblightipm.create'), { plantingId: this.record.id });
        }

        static $inject = ['record', 'accounts', 'controls', '$state', '$modal', '$scope', 'toaster'];
        constructor(record: Models.PlantingRecord, accounts: Models.Account[], controls, $state: ng.ui.IStateService,
            $modal: ng.ui.bootstrap.IModalService, $scope: ng.IScope, toaster: ng.toaster.IToasterService
        ) {
            super(record, accounts, controls, $state, $modal, toaster);

            this.record.WasteDisposalMethod = <Deregulated.IDisposalMethod>_.findWhere(controls.disposalMethods, { id: record.wasteDisposalMethodId });
            this.record.SeedDisposalMethod = <Deregulated.IDisposalMethod>_.findWhere(controls.disposalMethods, { id: record.seedDisposalMethodId });
            
            $scope.$watch(() => {
                return this.record.WasteDisposalMethod;
            }, (newValue: Deregulated.IDisposalMethod, oldValue) => {
                this.disposalNotesLabel = BaseRecordController.getDisposalNotesLabel(newValue);
            });
            $scope.$watch(() => {
                return this.record.SeedDisposalMethod;
            }, (newValue: Deregulated.IDisposalMethod, oldValue) => {
                this.seedDisposalNotesLabel = BaseRecordController.getDisposalNotesLabel(newValue);
            });
        }


        /**
         * Resolve all promises before loading the controller
         * 
         * @Param isCreateMode  boolean
         */
        resolve(isCreateMode = false): { record: Function; accounts: Function; controls: Function; } {
            getRecord.$inject = ['$stateParams', 'RecordService'];
            function getRecord($stateParams: ng.ui.IStateParamsService, RecordService: Services.RecordService): Models.PlantingRecord | ng.IPromise<Models.PlantingRecord> {
                if (isCreateMode) {
                    var record = new Models.PlantingRecord().load({});
                    return record;
                } else {
                    return RecordService.getRecord<Models.PlantingRecord>($stateParams['id'], Models.PlantingRecord, 'planting');
                }
            }

            getAccounts.$inject = ['AccountService', '$rootScope'];
            function getAccounts(AccountService: Services.AccountService, $rootScope: ng.IRootScopeService) : Models.Account[] | ng.IPromise<Models.Account[]> {
                if ($rootScope.user.can.viewAccountList) {
                    return AccountService.getAccounts({ 'recordTypeName': 'Planting' });
                }
                else {
                    return [$rootScope.user.account];
                }
            }

            getControls.$inject = ['LookupService', '$q'];
            function getControls(LookupService: Services.LookupService, $q: ng.IQService) {
                return $q.all([LookupService.getDisposalMethods('Planting'), LookupService.getGenericLookup('SigningMessage', true)]).then((data) => {
                    return {
                        disposalMethods: data[0],
                        signingMessage: data[1]
                    };
                });
            }

            return {
                record: getRecord,
                accounts: getAccounts,
                controls: getControls
            }
        }
    }
}