module App.Directives {
    class SiteSelectController {
        sites: Deregulated.ISite[] = [];
        site: Deregulated.ISite;
        location: Deregulated.ILocation;
        siteFilter: (item: Deregulated.ISite) => boolean;

        getSite(sites: Deregulated.ISite[], siteId: number) {
            let site = _.find(sites, { id: siteId });
            return site ? site : null;
        }

        static $inject = ['AccountService', '$scope'];
        constructor(AccountService: App.Services.AccountService, $scope: ng.IScope) {
            $scope.$watch(() => this.location, (newLocation: Deregulated.ILocation, oldLocation: Deregulated.ILocation) => {
                if (newLocation) {
                    // Need to get the location off of the account.location and not the record.location because 
                    // only the account includes the whole list of sites
                    AccountService.getAccount(this.location.accountId)
                        .then(account => {
                            let currentLocation = _.findWhere(account.locations, { id: this.location.id });
                            this.sites = (this.siteFilter) ? currentLocation.sites.filter(this.siteFilter) : this.sites = currentLocation.sites;
                            if (this.site && !_.find(this.sites, { id: this.site.id })) {
                                this.site = null;
                            }
                            // If only one, select it
                            if ((oldLocation == null || newLocation.id !== oldLocation.id) && this.sites.length === 1) {
                                this.site = this.sites[0];
                            }
                        });
                } else {
                    this.sites = [];
                    this.site = null;
                }
            });
        }
    }

    export function SiteSelect() {

        let scope = {
            location: '=',
            account: '=',
            site: '=ngModel',
            ngDisabled: '=',
            siteFilter: '='
        }

        let template = `
            <select
                ng-options="site.displayName for site in ss.sites track by site.id"
                ng-model="ss.site"
                ng-disabled="ss.ngDisabled">
                <option value="">-- Select One --</option>
            </select>
        `;

        let linkFn: ng.IDirectiveLinkFn = function (scope, element, attrs) {
            angular.element(element)
                .find('select')
                .attr('class', attrs['class'])
                .attr('name', attrs['name']);

            angular.element(element)
                .removeAttr('class')
                .removeAttr('name');
        }

        return {
            scope: scope,
            restrict: 'E',
            template: template,
            link: linkFn,
            controller: SiteSelectController,
            controllerAs: 'ss',
            bindToController: true
        }
    }
}

angular.module('app').directive('siteSelect', App.Directives.SiteSelect);