module App {
    export class RunApplication {
        stateChangeStarted = (event: ng.IAngularEvent, current: ng.ui.IState, params, previous: ng.ui.IState) => {
            if (!this.$rootScope.user && !current.name.match(/^(login|user-not-found|passwordreset.ready|passwordreset.done|passwordchange|markup-lookup)$/)) {
                event.preventDefault();
                this.AuthService.getUser().then((u) => {
                    if (u) { reload(); }
                });
            }

            var $state = this.$state;
            function reload() {
                this.$state.transitionTo(current.name, params, {
                    reload: true, inherit: false, notify: true
                });
            }
        }

        stateChangeSuccess = (event: ng.IAngularEvent, current: ng.ui.IState, params, previous: ng.ui.IState) => {
            this.$rootScope.pageTitle = current['title'];
            this.$rootScope.title = this.$rootScope.pageTitle + ' | ' + this.$rootScope.siteTitle;
        }

        static $inject = [
            '$rootScope',
            '$state',
            '$location',
            'AuthService',
            'LookupService',
            'CONFIG'
        ];
        constructor(
            private $rootScope: ng.IRootScopeService,
            private $state: ng.ui.IStateService,
            private $location: ng.ILocationService,
            private AuthService: App.Services.AuthService,
            private LookupService: App.Services.LookupService,
            private CONFIG) {
            $rootScope.$on('$stateChangeStart', this.stateChangeStarted);
            $rootScope.$on('$stateChangeSuccess', this.stateChangeSuccess);

            $rootScope.siteTitle = CONFIG.SITE_NAME;
            $rootScope.title = 'Loading ... | ' + $rootScope.siteTitle;

            $rootScope.$state = $state;

            var logout = AuthService.logout;
            var externalLogin = AuthService.externalLogin;

            $rootScope.logout = logout;
            $rootScope.externalLogin = externalLogin;

            /**
             * Helper patterns for use with ngPattern
             */
            $rootScope.patterns = {
                latLong: {
                    pattern: /^(\-?\d{1,3}(\.\d{0,8})?)$/,
                    message: 'Numeric Value Required'
                },
                float: {
                    pattern: /^\d*(\.\d*)?$/,
                    message: 'Numeric Value Required'
                }
            };
        }
    }
}
angular.module('app').run(App.RunApplication);