module App.Controllers {

    export class AvailablePlantingRecords {
        selectedPlantingRecords: Models.PlantingRecord[];
        plantingRecords: Models.PlantingRecord[];
        pristinePlantingRecords: Models.PlantingRecord[];

        cancel(): void {
            this.$modalInstance.dismiss('cancel');
        }

        ok(): void {
            this.$modalInstance.close(this.selectedPlantingRecords);
        }

        toggleSelect(plantingRecord: Models.PlantingRecord) {
            var isSelectedIndex = _.findIndex(this.selectedPlantingRecords, { id: plantingRecord.id });
            if (isSelectedIndex > -1) {
                this.selectedPlantingRecords.splice(isSelectedIndex, 1);
            } else {
                this.selectedPlantingRecords.push(plantingRecord);
            }
            this.updatePlantingRecords();
        }

        recordSelected(plantingRecord: Models.PlantingRecord) {
            if (_.findWhere(this.selectedPlantingRecords, { id: plantingRecord.id })) {
                return true;
            }
            return false;
        }

        private updatePlantingRecords() {

            if (this.selectedPlantingRecords && this.selectedPlantingRecords.length) {
                this.plantingRecords = this.pristinePlantingRecords.filter(record => {
                    return recordPassedRequirements(record, this.selectedPlantingRecords[0]);
                });
            } else {
                this.plantingRecords = angular.copy(this.pristinePlantingRecords);
            }

            function recordPassedRequirements(record: Models.PlantingRecord, validRecord: Models.PlantingRecord): boolean {
                var matchKeys = ['location', 'site', 'variety'];
                var valid = true;

                matchKeys.forEach(key => {
                    if (valid) {
                        if ((record.record.hasOwnProperty(key) && !validRecord.record.hasOwnProperty(key)) || (!record.record.hasOwnProperty(key) && validRecord.record.hasOwnProperty(key))) {
                            valid = false;
                            return;
                        }
                        if ((record.record[key] && !validRecord.record[key]) || (!record.record[key] && validRecord.record[key])) {
                            valid = false;
                            return;
                        }
                    }
                });

                if (!valid) {
                    return false;
                }

                matchKeys.forEach(key => {
                    if (valid && (record.record[key] && validRecord.record[key])) {
                        if (record.record[key].id !== validRecord.record[key].id) {
                            valid = false;
                        }
                    }
                });
                return valid;
            }
        }

        static $inject = ['selectedPlantingRecords', 'plantingRecords', '$modalInstance'];
        constructor(
            selectedPlantingRecords,
            plantingRecords,
            private $modalInstance: ng.ui.bootstrap.IModalServiceInstance
            ) {
            this.selectedPlantingRecords = angular.copy(selectedPlantingRecords);
            this.plantingRecords = plantingRecords;
            this.pristinePlantingRecords = angular.copy(plantingRecords);
            this.updatePlantingRecords();
        }

        static config = {
            animation: true,
            templateUrl: 'views/record-harvest-select-planting.html',
            controller: AvailablePlantingRecords,
            controllerAs: 'modal',
            resolve: null,
            size: 'lg'
        }

        static getControllerConfig(selectedPlantingRecords: Models.PlantingRecord[], plantingRecords: Models.PlantingRecord[]) {
            this.config.resolve = {
                selectedPlantingRecords: function () {
                    return selectedPlantingRecords;
                },
                plantingRecords: function () {
                    return plantingRecords;
                }
            };

            return this.config;
        }
    }

}