module App.Models {
    export class SeedHarvestLineItem {
        id: number;
        lineItemNumber: number;
        harvestDate: Date;
        fieldGenerationId: number;
        fieldGeneration: Deregulated.ILookup;
        lot: string;
        quantityHarvested: number;
        quantityUnitOfMeasurement: string = 'cwt';
        acres: number;
        seedPlantingLineItemId: number;
        seedPlantingLineItem: Models.SeedPlantingLineItem;
        storageLocationNotes: string;

        /**
         * Getter for seedPlantingLineItem
         */
        get SeedPlantingLineItem() {
            return this.seedPlantingLineItem;
        }
        /**
         * Setter for seedPlantingLineItem
         */
        set SeedPlantingLineItem(pli: Models.SeedPlantingLineItem) {
            this.seedPlantingLineItem = pli || null;
            this.seedPlantingLineItemId = pli ? pli.id : null;
        }

        /**
         * Getter for fieldGeneration
         */
        get FieldGeneration() {
            return this.fieldGeneration;
        }
        /**
         * Setter for fieldGeneration
         */
        set FieldGeneration(fg: Deregulated.IFieldGeneration) {
            this.fieldGeneration = fg || null;
            this.fieldGenerationId = fg ? fg.id : null;
        }

        load(data: {}) {
            if (data) {
                for (var prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        if (prop === 'seedPlantingLineItem') {
                            data[prop] = new Models.SeedPlantingLineItem().load(data[prop]);
                        }
                        this[prop] = data[prop];
                    }
                }
            }
            return this;
        }

    }

    export class SeedHarvestRecord extends _BaseRecord {
        anyWasteMaterials: boolean;
        wasteProperlyDisposed: boolean;
        wasteDisposalMethodId: number;
        wasteDisposalMethod: Deregulated.IDisposalMethod;
        wasteDisposalNotes: string;
        machineryCleanedInspectedBeforeHarvest: boolean;
        machineryCleanedInspectedAfterHarvest: boolean;
        seedPlantingRecord: Models.SeedPlantingRecord;
        lineItems: Models.SeedHarvestLineItem[];
        nextLineItemNumber: number;

        /**
         * Getter for WasteDisposalMethod
         */
        get WasteDisposalMethod() {
            return this.wasteDisposalMethod;
        }

        /**
         * Setter for WasteDisposalMethod
         */
        set WasteDisposalMethod(wasteDisposalMethod: Deregulated.IDisposalMethod) {
            this.wasteDisposalMethod = wasteDisposalMethod || null;
            this.wasteDisposalMethodId = wasteDisposalMethod ? wasteDisposalMethod.id : null;
        }

        get TotalAcres() {
            let total = 0;
            for (var li of this.lineItems) {
                total += li.acres;
            }
            return total;
        }

        /**
         * Overriding super's 'load' method so correct 'this' is returned.
         */
        load(data: {}) {
            super.load(data);
            for (var prop in data) {
                if (data.hasOwnProperty(prop)) {
                    if (prop === 'seedPlantingRecord' && data[prop]) {
                        data[prop] = new Models.SeedPlantingRecord().load(data[prop]);
                    }
                    else if (prop === 'lineItems' && data[prop].length) {
                        data[prop] = <any[]>data[prop].map((li: Models.SeedHarvestLineItem) => {
                            li = new Models.SeedHarvestLineItem().load(li);
                            return li;
                        });
                    }


                    this[prop] = data[prop];
                }
            }
            return this;
        }

        constructor() {
            super();
            this.recordType = 'seedharvest';
        }
    }
}