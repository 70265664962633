module App.Services {
    export class NetworkService {
        private _muted: boolean;
        private _message: string;

        getNetworks(accountId?: number): ng.IPromise<Models.Network[]> {
            var filters: App.Models.Filters = accountId ? { accountId: accountId } : null;
            return this.ApiService.getItems<Models.Network>({ entity: 'networks', modelType: Models.Network, filters: filters });
        }

        getNetwork(id: number): ng.IPromise<Models.Network> {
            return this.ApiService.getItem<Models.Network>({ entity: 'networks', id: id, modelType: Models.Network });
        }

        edit(Network: Models.Network, mute?: boolean): ng.IPromise<Models.Network> {
            this._muted = mute;
            this._message = 'Network Saved';
            return this.ApiService.editItem<Models.Network>('networks', Network).then(this._success, this._error).finally(this._finally);
        }

        add(Network: Models.Network, mute?: boolean): ng.IPromise<Models.Network> {
            this._muted = mute;
            this._message = 'Network Created';
            return this.ApiService.addItem<Models.Network>('networks', Network).then(this._success, this._error).finally(this._finally);
        }

        remove(Network: Models.Network) {
            return this.ApiService.removeItem<Models.Network>('networks', Network);
        }

        private _success = (Network: Models.Network): Models.Network => {
            if (!this._muted) {
                this.toaster.success(this._message ? this._message : 'Done!');
            }
            return Network;
        }

        private _error = (reason) => {
            if (reason.data && reason.data.errors) {
                return (this.$q.reject(reason.data.errors));
            }
            return (this.$q.reject(reason));
        }

        private _finally = () => {
            this._muted = false;
            this._message = null;
        }

        static ID = 'NetworkService';
        static $inject = ['ApiService', 'toaster', '$q'];
        constructor(private ApiService: ApiService, private toaster: ng.toaster.IToasterService, private $q: ng.IQService) {
            Models.Network.prototype.NetworkService = this;
        }
    }
    angular.module('app').service('NetworkService', NetworkService);
}
 