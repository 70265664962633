module App.Controllers {

    export class HarvestRecordController extends BaseRecordController<Models.HarvestRecord> {
        model = Models.HarvestRecord;

        record: Models.HarvestRecord;

        editingDestination: boolean;

        disposalNotesLabel: string = 'Disposal Notes';

        clearWasteQuestions() {
            this.record.WasteDisposalMethod = null;
            this.record.wasteProperlyDisposed = null;
            this.record.wasteDisposalNotes = null;
        }

        controls: {
            disposalMethods: any[];
            states: any[];
            transferDestinationAccounts: Models.Account[];
            plantingRecords: Models.PlantingRecord[]
        }

        openAvailablePlantingRecordsModal(): void {
            var modalConfig = AvailablePlantingRecords.getControllerConfig(this.record.plantingRecords, this.controls.plantingRecords);
            var modal = this.$modal.open(modalConfig);
            modal.result.then((selectedPlantingRecords) => {
                this.record.plantingRecords = selectedPlantingRecords;
                if (this.record.plantingRecords && this.record.plantingRecords.length) {
                    var selectedRecord = this.record.plantingRecords[0];

                    // Set Location
                    this.record.record.Location = selectedRecord.record.Location;

                    // Set Site
                    this.record.record.Site = selectedRecord.record.Site;

                    // Set Variety
                    this.record.record.Variety = selectedRecord.record.Variety;
                } else {
                    // Set Location
                    this.record.record.Location = null;

                    // Set Site
                    this.record.record.Site = null;

                    // Set Variety
                    this.record.record.Variety = null;
                }
            }, reason => {
                // Dismissed
            });
        }

        /**
         * Sets the controls.processingDestinationsAccounts options
         */
        private _setTransferDestinationAccountOptions() {
            this.AccountService.getAccounts({ forTransferDestination: true, baseAccountId: this.record.record.accountId }).then(accounts => {
                for (var s of this.record.transferDestinations) {
                    if (s.Account && !_.find(accounts, { id: s.Account.id })) {
                        accounts.unshift(<Models.Account>s.Account);
                    }
                }
                this.controls.transferDestinationAccounts = accounts;
            });
        }

        /**
         * Sets the controls.plantingRecords options
         */
        private _setAvailablePlantingRecords(accountId: number) {
            if (accountId) {
                this.RecordService.getRecords(Models.PlantingRecord, 'planting', { accountId: accountId }).then(plantingRecords => {
                    this.controls.plantingRecords = plantingRecords;
                });
            } else {
                this.controls.plantingRecords = [];
                this.record.plantingRecords   = [];
                this.record.record.Variety    = null;
                this.record.record.Site       = null;
                this.record.record.Location   = null;
            }
        }

        static $inject = [
            'record',
            'accounts',
            'controls',
            'AccountService',
            'RecordService',
            '$state',
            '$scope',
            '$modal',
            'toaster'
        ];
        constructor(
            record: Models.HarvestRecord,
            accounts: Models.Account[],
            controls,
            private AccountService: Services.AccountService,
            private RecordService: Services.RecordService,
            $state: ng.ui.IStateService,
            $scope: ng.IScope,
            $modal: ng.ui.bootstrap.IModalService,
            toaster: ng.toaster.IToasterService
        ) {
            super(record, accounts, controls, $state, $modal, toaster);

            this.record.WasteDisposalMethod = <Deregulated.IDisposalMethod>_.findWhere(controls.disposalMethods, { id: record.wasteDisposalMethodId });
            this.editingDestination = false;
            this._setTransferDestinationAccountOptions();

            /**
             * watch record.accountId for changes and do stuff.
             */
            $scope.$watch(() => {
                return this.record.record.accountId;
            }, (newAccountId, oldAccountId) => {
                this._setAvailablePlantingRecords(newAccountId);

                if (parseInt(newAccountId) !== parseInt(oldAccountId) && oldAccountId) {
                    this.record.plantingRecords   = [];
                    this.record.record.Variety    = null;
                    this.record.record.Site       = null;
                    this.record.record.Location   = null;
                }

            });

            $scope.$on('editingDestination',(event, editFunction, record) => {
                this[editFunction](record);
            });

            $scope.$watch(() => {
                return this.record.WasteDisposalMethod;
            }, (newValue: Deregulated.IDisposalMethod, oldValue) => {
                this.disposalNotesLabel = BaseRecordController.getDisposalNotesLabel(newValue);
            });
        }

        /**
         * Resolve all promises before loading the controller
         * 
         * @Param isCreateMode  boolean
         */
        resolve(isCreateMode = false): { record: Function; accounts: Function; controls: Function; } {
            getRecord.$inject = ['$stateParams', 'RecordService'];
            function getRecord($stateParams: ng.ui.IStateParamsService, RecordService: Services.RecordService): Models.HarvestRecord | ng.IPromise<Models.HarvestRecord> {
                if (isCreateMode) {
                    var record = new Models.HarvestRecord().load({});
                    return record;
                } else {
                    return RecordService.getRecord<Models.HarvestRecord>($stateParams['id'], Models.HarvestRecord, 'harvest');
                }
            }

            getAccounts.$inject = ['AccountService', '$rootScope'];
            function getAccounts(AccountService: Services.AccountService, $rootScope: ng.IRootScopeService): Models.Account[] | ng.IPromise<Models.Account[]> {
                if ($rootScope.user.can.viewAccountList) {
                    return AccountService.getAccounts();
                }
                else {
                    return [$rootScope.user.account];
                }
            }

            getControls.$inject = ['LookupService', '$q'];
            function getControls(LookupService: Services.LookupService, $q: ng.IQService) {
                return $q.all([LookupService.getDisposalMethods(), LookupService.getGenericLookup('SigningMessage', true)]).then((data) => {
                    return {
                        disposalMethods: data[0],
                        signingMessage: data[1]
                    };
                });
            }

            return {
                record: getRecord,
                accounts: getAccounts,
                controls: getControls
            }
        }
    }
}
