module App.Services
{
    export class LookupService
    {
        private _muted: boolean;
        private _message: string;

        getGenericLookup(name: string, restricted: boolean) {
            if (restricted === true)
                return this.ApiService.getItems('lookup/generics', true, true, { name: name });
            else
                return this.ApiService.getItems('lookup/unrestrictedgenerics', true, true, { name: name });
        }

        getAccountTypes(onlyActive: boolean): ng.IPromise<Models.AccountType[]> {
            var filters: App.Models.Filters = { onlyActive: onlyActive }
            return this.ApiService.getItems({ entity: 'lookup/accounttypes', modelType: Models.AccountType, filters: filters });
        }

        getSiteTypes(onlyActive: boolean): ng.IPromise<Models.SiteType[]> {
            var filters: App.Models.Filters = { onlyActive: onlyActive }
            return this.ApiService.getItems({ entity: 'lookup/sitetypes', modelType: Models.SiteType, filters: filters });
        }

        getNetworkTypes(onlyActive: boolean): ng.IPromise<Models.NetworkType[]> {
            var filters: App.Models.Filters = { onlyActive: onlyActive }
            return this.ApiService.getItems({ entity: 'lookup/networktypes', modelType: Models.NetworkType, filters: filters });
        }

        getVarieties(onlyActive: boolean): ng.IPromise<Models.Variety[]> {
            var filters: App.Models.Filters = { onlyActive: onlyActive }
            return this.ApiService.getItems({ entity: 'lookup/varieties', modelType: Models.Variety, filters: filters });
        }

        getRoles(forSimplotCompany?: boolean): ng.IPromise<any> {
            var filters: App.Models.Filters = forSimplotCompany ? { forSimplotCompany: forSimplotCompany } : null;
            return this.ApiService.getItems('lookup/roles', false, true, filters);
        }

        getStates(): ng.IPromise<any> {
            //var filters: App.Models.Filters = (typeof country === 'string') ? { countryName: country } : { countryId: country };
            return this.ApiService.getItems('lookup/statesProvinces', true, true);//, filters);
        }
        
        getCountries(): ng.IPromise<any> {
            return this.ApiService.getItems('lookup/countries');
        }

        getCounties(stateProvinceId: number): ng.IPromise<Deregulated.ICounty[]> {
            var filters: App.Models.Filters = { stateProvinceId: stateProvinceId };

            return this.ApiService.getItems('lookup/counties', false, true, filters);
        }

        getLateBlightAreas(): ng.IPromise<any> {
            return this.ApiService.getItems('lookup/lateblightareas');
        }

        getDisposalMethods(recordType?: string) {
            var filters: App.Models.Filters = recordType ? { recordTypeName: recordType } : null;

            return this.ApiService.getItems('lookup/disposalmethods', true, true, filters);
        }

        getFieldGenerations() {
            return this.ApiService.getItems('lookup/fieldgenerations');
        }

        getControlMethods() {
            return this.ApiService.getItems('lookup/controlmethods');
        }

        // Standard record types excludes Incident Record
        getStandardRecordTypes() {
            return this.ApiService.getItems('lookup/recordtypes?excludeIncident=true');
        }

        getAllRecordTypes() {
            return this.ApiService.getItems('lookup/recordtypes?excludeIncident=false');
        }

        getNetworkPriorities() {
            var deferred = this.$q.defer();
            
            var networkPriorities = [
                { id: 1, name: 'Primary' },
                { id: 2, name: 'Secondary' }
            ];


            deferred.resolve(networkPriorities);

            return deferred.promise;
        }

        getUserStatuses() {
            return this.ApiService.getItems('lookup/userstatuses');
        }

        edit<T extends Models._BaseLookup>(lookup: T, mute = false): ng.IPromise<T> {
            let url = lookup.lookupUrl;
            this._message = lookup.lookupType + ' Saved';
            return this.ApiService.editItem<T>(url, lookup)
                .then(record => this._success(record), reason => this._error(reason)).finally(this._finally);
        }
        add<T extends Models._BaseLookup>(lookup: T, mute = false): ng.IPromise<T> {
            let url = lookup.lookupUrl;
            this._message = lookup.lookupType + ' Created';
            return this.ApiService.addItem<T>(url, lookup)
                .then(record => this._success(record), reason => this._error(reason)).finally(this._finally);
        }
        remove<T extends Models._BaseLookup>(lookup: T, mute = false): ng.IPromise<T> {
            let url = lookup.lookupUrl;
            this._message = lookup.lookupType + ' Removed';
            return this.ApiService.removeItem<T>(url, lookup)
                .then(record => this._success(record), reason => this._error(reason)).finally(this._finally);
        }

        private _success<T>(Record: T): T {
            if (!this._muted) {
                this.toaster.success(this._message ? this._message : 'Done!');
            }
            return Record;
        }

        private _error = (reason) => {
            if (reason.data && reason.data.errors) {
                return (this.$q.reject(reason.data.errors));
            }
            return (this.$q.reject(reason));
        }

        private _finally = () => {
            this._muted = false;
            this._message = null;
        }

        static $inject = ['ApiService', 'toaster', '$q'];
        constructor(private ApiService: ApiService, private toaster: ng.toaster.IToasterService, private $q: ng.IQService) {

        }
    }
    angular.module('app').service('LookupService', LookupService);
}