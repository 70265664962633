module App.Controllers
{
    export class AccountsController
    {
        // TODO - create function to make sure the links in the accounts work.
        accounts: Models.Account[];

        static $inject = ['accounts', 'AccountService', '$state'];
        constructor(accounts, private AccountService: Services.AccountService, private $state: ng.ui.IStateService)
        {
            this.accounts = accounts;
        }
    }

    AccountsControllerRoute.$inject = ['$stateProvider'];
    export function AccountsControllerRoute($stateProvider: angular.ui.IStateProvider)
    {
        $stateProvider.state('accounts', {
            url: '/accounts',
            abstract: true,
            template: '<ui-view/>'
        })
        .state('accounts.index', {
            url: '',
            templateUrl: 'views/accounts.html',
            controller: AccountsController,
            controllerAs: 'vm',
            title: 'Accounts',
            resolve: {
                accounts: ['AccountService', (AccountService: Services.AccountService) => {
                    return AccountService.getAccounts().then(accounts => accounts.map(a => {
                        return {
                            id: a.id,
                            number: a.number,
                            name: a.name,
                            typeName: a.typeName,
                            seasonalityStatus: a.seasonalityStatus
                        };
                    }));
                }]
            }
        });
    }
}

angular.module('app').config(App.Controllers.AccountsControllerRoute); 