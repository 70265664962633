namespace App.Directives {

    const enum drawerState { CLOSED, OPEN };

    interface IDrawers {
        [id: string]: drawerState;
    }

    class DrawersController {
        drawers: IDrawers = {};

        doAction(action: string, cbName: string, id: string, context: ng.IScope) {
            this.$scope.$apply(() => {
                let promise: ng.IPromise<{}> = this.$parse(action)(context);

                if (typeof promise === 'boolean') {
                    if (promise) {
                        let previousState = this.drawers[id];
                        this._closeDrawers(() => {
                            //toggle;
                            if (previousState === drawerState.OPEN) {
                                this.drawers[id] = drawerState.CLOSED;
                            } else {
                                this.drawers[id] = drawerState.OPEN;
                            }
                        });
                    }
                } else if (promise === undefined) {
                    if (cbName === 'openDrawer') {
                        this._openDrawer(id);
                    } else {
                        this._closeDrawer(id);
                    }
                } else {
                    promise.then(() => {
                        if (cbName === 'openDrawer') {
                            this._openDrawer(id);
                        } else {
                            this._closeDrawer(id);
                        }
                    }, (error) => {
                        // do nothing
                    });
                }
            });
        }

        private _openDrawer(id: string, cb?: Function) {
            this._closeDrawers(() => {
                this.drawers[id] = drawerState.OPEN;
                if (cb) {
                    cb();
                }
            });
        }

        private _closeDrawer(id: string, cb?: Function) {
            this.drawers[id] = drawerState.CLOSED;
            if (cb) {
                cb();
            }
        }

        private _closeDrawers(cb?: Function) {
            _.forIn(this.drawers, (state: drawerState, key: string) => {
                this.drawers[key] = drawerState.CLOSED;
            });
            if (cb) {
                cb();
            }
        }

        registerDrawer(id: string, watch?: any, context?) {
            if (this.drawers.hasOwnProperty(id)) {
                throw new Error('Drawer with id already exists: ' + id);
                //return;
            }
            this.drawers[id] = drawerState.CLOSED;
            if (watch) {
                let parsedWatch = this.$parse(watch)(context);
                this.$scope.$watch(() => parsedWatch, (val) => {
                    if (!!(val)) {
                        this.drawers[id] = drawerState.OPEN;
                    } else {
                        this.drawers[id] = drawerState.CLOSED;
                    }
                });
            }
        }

        static $inject = ['$scope', '$element', '$parse'];
        constructor(private $scope: ng.IScope, private $element: ng.IAugmentedJQuery, private $parse: ng.IParseService) {
        }
    }

    export function DrawersDirective(): ng.IDirective {
        return {
            restrict: 'A',
            controller: DrawersController,
            controllerAs: 'drawersCtrl',
            bindToController: true,
            scope: true
        }
    }

    DrawerDirective.$inject = ['$compile'];
    export function DrawerDirective($compile: ng.ICompileService) {

        let linkFn: ng.IDirectiveLinkFn = function (scope: ng.IScope, element: ng.IAugmentedJQuery, attrs: ng.IAttributes, drawersCtrl: DrawersController) {

            let columnCnt = element[0].children.length;

            let drawerId = 'drawer' + (Object.keys(drawersCtrl.drawers).length + 20);

            let watch = attrs['openOn'] ? attrs['openOn'] : null;

            let context = element.scope();

            drawersCtrl.registerDrawer(drawerId, watch, context);

            let tableRow = `
                <tr class="drawer-content" drawer-id="${drawerId}">
                    <td colspan="${columnCnt}" style="padding: 0;">
                        <div ng-include="${attrs['drawer']}" style="padding: 8px;" ng-show="drawersCtrl.drawers['${drawerId}'] === 1"></div>
                    </td>
                </tr>
            `;

            angular.element(element).find('[open-drawer]').each((i, e) => {
                let action = angular.element(e).attr('open-drawer');
                if (!action) {
                    throw new Error('open-drawer attribute requires a function');
                    //return;
                }
                setClickEvent('openDrawer', e, action);
            });

            angular.element(element).find('[close-drawer]').each((i, e) => {
                let action = angular.element(e).attr('close-drawer');
                if (!action) {
                    throw new Error('close-drawer attribute requires a function');
                    //return;
                }
                setClickEvent('closeDrawer', e, action);
            });

            angular.element(element).find('[toggle-drawer]').each((i, e) => {
                let action = angular.element(e).attr('toggle-drawer');
                //angular.element(e).attr('style', 'cursor: pointer');
                if (!action) {
                    throw new Error('toggle-drawer attribute requires a function');
                    //return;
                }
                setClickEvent('toggleDrawer', e, action);
            });

            $compile(angular.element(tableRow).insertAfter(element))(scope);

            function setClickEvent(functionName: string, clickElement: Element, action: string) {
                let ele = angular.element(clickElement);
                if (ele.attr('ng-click')) {
                    throw new Error('ngClick cannot be used with open-drawer or close-drawer');
                    //return;
                }

                ele.bind('click', () => {
                    drawersCtrl.doAction(action, functionName, drawerId, context);
                });
            }
        }

        return {
            restrict: 'A',
            link: linkFn,
            require: '^drawers'
        }
    }
}

angular.module('app').directive('drawers', App.Directives.DrawersDirective).directive('drawer', App.Directives.DrawerDirective);

/**
 * USAGE
 ****
<table>
    <thead>
        <tr>
            <th>content</th>
            <th>buttons</th>
        </tr>
    </thead>
    <tbody drawers>
        <tr ng-repeat="row in ctrl.rows" drawer="'views/template.html'" open-on="row.someBoolean">
            <td ng-click="row.someBoolean = !row.someBoolean">some content</td>
            <td>
                <button open-drawer="ctrl.beforeOpenAction()">open</button>
                <button close-drawer="ctrl.beforeCloseAction()">close</button>
            </td>
        </tr>
    </tbody>
</table>

*/