module App.Models {
    export class ReceiptLineItem {
        id: number;
        lineItemNumber: number;
        receiptRecordId: number;
        shipmentLineItemId: number;
        shipmentLineItem: Models.ShipmentLineItem;
        actualQuantityReceived: number;
        quantityReceivedUnitOfMeasurement: string = 'cwt';

        /**
         * Getter for shipmentLineItem
         */
        get ShipmentLineItem() {
            return this.shipmentLineItem;
        }
        /**
         * Setter for shipmentLineItem
         */
        set ShipmentLineItem(pli: Models.ShipmentLineItem) {
            this.shipmentLineItem = pli || null;
            this.shipmentLineItemId = pli ? pli.id : null;
        }

        load(data: {}) {
            if (data) {
                for (var prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        if (prop === 'shipmentLineItem') {
                            data[prop] = new Models.ShipmentLineItem().load(data[prop]);
                        }
                        this[prop] = data[prop];
                    }
                }
            }
            return this;
        }

    }

    export class ReceiptRecordFlattened extends BaseFlattened {
        shipmentAccountName: string;
        shipmentLocationName: string;
        shipmentRecordNumber: string;
    }

    export class ReceiptRecord extends _BaseRecord {
        receivedFromAccountId: number;
        receivedFromAccount: Models.Account;
        receivedFromLocationId: number;
        receivedFromLocation: Deregulated.ILocation;
        shipmentRecordId: number;
        shipmentRecord: Models.ShipmentRecord;
        dateTime: Date;
        carrierName: string;
        sealTrackingNumbers: string;
        productSegregated: boolean;
        productProperlyIdentifiedLabeled: boolean;
        equipmentCleanedBeforeUnloading: boolean;
        equipmentCleanedAfterUnloading: boolean;
        anyWasteMaterials: boolean;
        wasteProperlyDisposed: boolean;
        wasteDisposalMethodId: number;
        wasteDisposalMethod: Deregulated.IDisposalMethod;
        wasteDisposalNotes: string;
        lineItems: Models.ReceiptLineItem[];
        nextLineItemNumber: number;

        receiptDate: any; // just so we can blank it out during copy

        /**
         * Getter for WasteDisposalMethod
         */
        get WasteDisposalMethod() {
            return this.wasteDisposalMethod;
        }

        /**
         * Setter for WasteDisposalMethod
         */
        set WasteDisposalMethod(wasteDisposalMethod: Deregulated.IDisposalMethod) {
            this.wasteDisposalMethod = wasteDisposalMethod || null;
            this.wasteDisposalMethodId = wasteDisposalMethod ? wasteDisposalMethod.id : null;
        }

        generateReport() {
            var blobPromise = this.recordService.getRecordReport('receipt', this.id);
            blobPromise.then((blob) => {
                saveAs(blob, this.record.type.name + '-Report-' + this.number + '.pdf');
            });
        }

        load(data: {}) {
            super.load(data);
            if (data) {
                for (var prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        if (prop === 'shipmentRecord' && data[prop]) {
                            data[prop] = new Models.ShipmentRecord().load(data[prop]);
                        }
                        if (prop === 'lineItems' && data[prop].length) {
                            data[prop] = <any[]>data[prop].map((li: Models.ReceiptLineItem) => {
                                li = new Models.ReceiptLineItem().load(li);
                                return li;
                            });
                        }
                        this[prop] = data[prop];
                    }
                }
            }
            return this;
        }

        get flattened(): ReceiptRecordFlattened {
            return {
                id: this.id,
                recordId: this.recordId,
                accountName: this.accountName,
                number: this.number,
                transactionDate: this.transactionDate,
                locationName: this.locationName,
                siteName: this.siteName,
                varietyName: this.varietyName,
                createdOn: this.record.createdOn,
                recordStatus: this.record.status.name,
                recordType: this.record.type.name,
                shipmentAccountName: this.shipmentRecord ? this.shipmentRecord.accountName : '',
                shipmentLocationName: this.shipmentRecord ? this.shipmentRecord.locationName : '',
                shipmentRecordNumber: this.shipmentRecord ? this.shipmentRecord.number : ''
            }
        }

        constructor() {
            super();
            this.recordType = 'receipt';
        }
    }
}