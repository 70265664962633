module App.Models {
    export class SeedPlantingLineItem {
        id: number;
        lineItemNumber: number;
        plantingDate: Date;
        varietyId: number;
        variety: Models.Variety;
        fieldGenerationId: number;
        fieldGeneration: Deregulated.ILookup;
        lot: string;
        quantityPlanted: number;
        quantityUnitOfMeasurement: string = 'cwt';
        acres: number;
        associatedSeedHarvestLineItems: App.Models.SeedHarvestLineItem[];

        /**
         * Getter for fieldGeneration
         */
        get FieldGeneration() {
            return this.fieldGeneration;
        }
        /**
         * Setter for fieldGeneration
         */
        set FieldGeneration(fg: Deregulated.IFieldGeneration) {
            this.fieldGeneration   = fg || null;
            this.fieldGenerationId = fg ? fg.id : null;
        }

        /**
         * Getter for variety
         */
        get Variety() {
            return this.variety;
        }
        /**
         * Setter for variety
         */
        set Variety(v: Models.Variety) {
            this.variety = v || null;
            this.varietyId = v ? v.id : null;
        }

        load(data: {}) {
            if (data) {
                for (var prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        this[prop] = data[prop];
                    }
                }
            }
            return this;
        }

    }

    export class SeedPlantingRecord extends _BaseRecord {
        machineryCleanedInspectedBeforePlanting: boolean;
        machineryCleanedInspectedAfterPlanting: boolean;
        anyWasteMaterials: boolean;
        wasteProperlyDisposed: boolean;
        wasteDisposalMethodId: number;
        wasteDisposalMethod: Deregulated.IDisposalMethod;
        wasteDisposalNotes: string;
        anySeedRemaining: boolean;
        seedProperlyDisposed: boolean;
        seedDisposalMethodId: number;
        seedDisposalMethod: Deregulated.IDisposalMethod;
        seedDisposalNotes: string;
        lineItems: Models.SeedPlantingLineItem[];
        harvestRecord: Models.HarvestRecord;
        nextLineItemNumber: number;

        /**
         * Getter for WasteDisposalMethod
         */
        get WasteDisposalMethod() {
            return this.wasteDisposalMethod;
        }

        /**
         * Setter for WasteDisposalMethod
         */
        set WasteDisposalMethod(wasteDisposalMethod: Deregulated.IDisposalMethod) {
            this.wasteDisposalMethod = wasteDisposalMethod || null;
            this.wasteDisposalMethodId = wasteDisposalMethod ? wasteDisposalMethod.id : null;
        }

        /**
         * Getter for SeedWasteDisposalMethod
         */
        get SeedDisposalMethod() {
            return this.seedDisposalMethod;
        }

        /**
         * Setter for SeedWasteDisposalMethod
         */
        set SeedDisposalMethod(seedDisposalMethod: Deregulated.IDisposalMethod) {
            this.seedDisposalMethod = seedDisposalMethod || null;
            this.seedDisposalMethodId = seedDisposalMethod ? seedDisposalMethod.id : null;
        }

        get TotalAcres() {
            let total = 0;
            for (var li of this.lineItems) {
                total += li.acres;
            }
            return total;
        }

        /**
         * Overriding super's 'load' method so correct 'this' is returned.
         */
        load(data: {}) {
            super.load(data);
            if (data) {
                for (var prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        if (prop === 'lineItems' && data[prop].length) {
                            data[prop] = <any[]>data[prop].map((li: Models.SeedPlantingLineItem) => {
                                li = new Models.SeedPlantingLineItem().load(li);
                                return li;
                            });
                        }
                        this[prop] = data[prop];
                    }
                }
            }
            return this;
        }

        constructor() {
            super();
            this.recordType = 'seedplanting';
        }
    }
} 