module App.Controllers {

    export class AvailableSites {
        selectedSites: Deregulated.IShipmentSite[];
        sites: Deregulated.IAvailableSite[];

        cancel(): void {
            this.$modalInstance.dismiss('cancel');
        }

        ok(): void {
            this.selectedSites = [];
            this.sites.forEach(site => {
                if (site.selected) {
                    var selectedSite = {
                        id: site.shipmentSiteId,
                        shipmentRecordId: site.shipmentRecordId,
                        siteId: site.id,
                        site: site
                    };
                    this.selectedSites.push(selectedSite);
                }
            });
            this.$modalInstance.close(this.selectedSites);
        }

        static $inject = ['selectedSites', 'sites', '$modalInstance'];
        constructor(
            selectedSites,
            sites,
            private $modalInstance: ng.ui.bootstrap.IModalServiceInstance
            ) {
            this.selectedSites = selectedSites || [];
            this.sites = sites || [];
            if (this.selectedSites.length && this.sites.length) {
                this.selectedSites.forEach(site => {
                    var matchedIndex = _.findIndex(this.sites, { id: site.siteId });
                    if (matchedIndex > -1) {
                        this.sites[matchedIndex].selected = true;
                        this.sites[matchedIndex].shipmentRecordId = site.shipmentRecordId;
                        this.sites[matchedIndex].shipmentSiteId = site.id;
                    }
                });
            }
        }



        static config = {
            animation: true,
            templateUrl: 'views/record-shipment-select-site.html',
            controller: AvailableSites,
            controllerAs: 'modal',
            resolve: null,
            size: 'lg'
        }

        static getControllerConfig(selectedSites: Deregulated.IShipmentSite[], sites: Deregulated.ISite[]) {
            this.config.resolve = {
                selectedSites: function () {
                    return selectedSites;
                },
                sites: function () {
                    return sites;
                }
            };

            return this.config;
        }
    }

}