module App.Models {
    export class GenericRecord extends _BaseRecord {
        constructor() {
            super();
        }

        load(data: {}) {
            super.load(data);
            return this;
        }
    }
}