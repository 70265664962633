module App.Directives
{
    export function LoadTemplate()
    {
        return {
            restrict: 'A',
            templateUrl: (element, attrs) =>
            {
                return attrs.loadTemplate;
            }
        }
    }
}

angular.module('app').directive('loadTemplate', App.Directives.LoadTemplate);