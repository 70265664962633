module App.Controllers {
    export class IncidentRecordController extends BaseRecordController<Models.IncidentRecord> {
        model = Models.IncidentRecord;
        record: Models.IncidentRecord;
        signingMessage: string;

        static $inject = [
            'record',
            'accounts',
            'controls',
            'AccountService',
            '$state',
            '$scope',
            '$modal',
            'toaster'
        ];
        constructor(
            record: Models.IncidentRecord,
            accounts: Models.Account[],
            controls,
            private AccountService: Services.AccountService,
            $state: ng.ui.IStateService,
            $scope: ng.IScope,
            $modal: ng.ui.bootstrap.IModalService,
            toaster: ng.toaster.IToasterService
        ) {
            super(record, accounts, controls, $state, $modal, toaster);
        }

        /**
         * Resolve all promises before loading the controller
         * 
         * @Param isCreateMode  boolean
         */
        resolve(isCreateMode = false): { record: Function; accounts: Function; controls: Function } {
            getRecord.$inject = ['$stateParams', 'RecordService'];
            function getRecord($stateParams: ng.ui.IStateParamsService, RecordService: Services.RecordService): Models.IncidentRecord | ng.IPromise<Models.IncidentRecord> {
                if (isCreateMode) {
                    var incident = new Models.IncidentRecord().load({});
                    return incident;
                } else {
                    return RecordService.getRecord<Models.IncidentRecord>($stateParams['id'], Models.IncidentRecord, 'incident');
                }
            }

            getAccounts.$inject = ['AccountService', '$rootScope'];
            function getAccounts(AccountService: Services.AccountService, $rootScope: ng.IRootScopeService): Models.Account[] | ng.IPromise<Models.Account[]> {
                if ($rootScope.user.can.viewAccountList) {
                    return AccountService.getAccounts();
                }
                else {
                    return [$rootScope.user.account];
                }
            }

            getControls.$inject = ['LookupService', '$q'];
            function getControls(LookupService: Services.LookupService, $q: ng.IQService) {
                return $q.all([LookupService.getGenericLookup('IncidentSigningMessage', true)]).then((data) => {
                    return {
                        signingMessage: data[0]
                    };
                });
            }

            return {
                record: getRecord,
                accounts: getAccounts,
                controls: getControls
            }
        }

    }
}