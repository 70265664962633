namespace App.Directives {
    
    class ImmediateDestinationsController {
        parentVm: Controllers.HarvestRecordController;
        record: Models.HarvestRecord;

        get destinations(): any[] {
            return new Array().concat(this.record.storageDestinations, this.record.transferDestinations, this.record.otherDestinations);
        };

        // Grid values
        getDestinationType(destination: Models.TransferDestination & Models.StorageDestination & Models.OtherDestination): string {
            if (getFnName(destination.constructor) === 'StorageDestination') {
                return 'Storage';
            } else if (getFnName(destination.constructor) === 'TransferDestination') {
                return 'Transfer';
            } else if (getFnName(destination.constructor) === 'OtherDestination') {
                return 'Other';
            } else {
                throw new Error('Unhandled destinationType: ' + getFnName(destination.constructor));
                //return null;
            }
        }
        
        getToAccount(destination: Models.TransferDestination & Models.StorageDestination & Models.OtherDestination): string {
            if (getFnName(destination.constructor) === 'StorageDestination') {
                return this.record.record.Account.name;
            } else if (getFnName(destination.constructor) === 'TransferDestination') {
                return (destination.Account) ? destination.Account.name : '';
            } else if (getFnName(destination.constructor) === 'OtherDestination') {
                return destination.company;
            } else {
                throw new Error('Unhandled destinationType: ' + getFnName(destination.constructor));
                //return null;
            }
        }

        getLocation(destination: Models.TransferDestination & Models.StorageDestination & Models.OtherDestination): string {
            if (getFnName(destination.constructor) === 'StorageDestination' || getFnName(destination.constructor) === 'TransferDestination') {
                return (destination.Location) ? destination.Location.name : '';
            } else if (getFnName(destination.constructor) === 'OtherDestination') {
                return '';
            } else {
                throw new Error('Unhandled destinationType: ' + getFnName(destination.constructor));
                //return null;
            }
        }

        getSite(destination: Models.TransferDestination & Models.StorageDestination & Models.OtherDestination): string {
            if (getFnName(destination.constructor) === 'StorageDestination' || getFnName(destination.constructor) === 'TransferDestination') {
                return (destination.Site) ? destination.Site.name : '';
            } else if (getFnName(destination.constructor) === 'OtherDestination') {
                return '';
            } else {
                throw new Error('Unhandled destinationType: ' + getFnName(destination.constructor));
                //return null;
            }
        }

        getEstimatedQuantity(destination: Models.TransferDestination & Models.StorageDestination & Models.OtherDestination): string {
                if (!destination.estimatedQuantity) return '';
                return (destination.estimatedQuantity + '') + ' ' + (destination.estimatedQuantity ? destination.quantityUnitOfMeasurement : '');
        }

        getTemplate(destination: Models.TransferDestination & Models.StorageDestination & Models.OtherDestination): string {
            if (getFnName(destination.constructor) === 'StorageDestination') {
                return 'views/storage-drawer.html';
            } else if (getFnName(destination.constructor) === 'TransferDestination') {
                return 'views/transfer-drawer.html';
            } else if (getFnName(destination.constructor) === 'OtherDestination') {
                return 'views/other-drawer.html';
            } else {
                throw new Error('Unhandled destinationType: ' + getFnName(destination.constructor));
                //return null;
            }
        }

        canAddDestination(): boolean {
            if (this.parentVm.isInEditMode) {
                return false;
            } else if (this.parentVm.editingDestination) {
                return false;
            } else if (!this.record.record || !this.record.record.canEdit) {
                return false;
            } else if (this.parentVm.record.plantingRecords.length === 0) {
                return false;
            } else {
                return true;
            }
        }

        canEditDestination(destination: { currentlyEditing: boolean; }) {
            if (!this.record.record.canEdit) {
                return false;
            }
            if (this.parentVm.isInEditMode) {
                return false;
            }
            if (this.parentVm.editingDestination) {
                return false;
            }
            if (destination.currentlyEditing) {
                return false;
            }
            return true;
        }

        // Storage Destination
        private addStorageDestination() {
            this.parentVm.editingDestination = true;

            var storageDestination = new Models.StorageDestination();
            storageDestination.harvestAccount = this.record.record.Account;
            storageDestination.harvestRecordId = this.record.id;

            storageDestination['currentlyEditing'] = true;

            // if only one option, set it
            // (we need to do this manually since the timing isn't right for oldAccount to be null on the watch
            if (storageDestination.harvestAccount.locations.length === 1) {
                storageDestination.Location = storageDestination.harvestAccount.locations[0];
                if (storageDestination.Location.sites.length == 1) {
                    storageDestination.Site = storageDestination.Location.sites[0];
                }
            }

            this.record.storageDestinations.push(storageDestination);
        }

        // Transfer Destination
        private addTransferDestination() {
            this.parentVm.editingDestination = true;
            var transferDestination = new Models.TransferDestination();
            transferDestination.harvestRecordId = this.record.id;

            transferDestination['currentlyEditing'] = true;

            // if only one option, set it
            if (this.parentVm.controls.transferDestinationAccounts.length === 1) {
                transferDestination.Account = this.parentVm.controls.transferDestinationAccounts[0];
            }

            this.record.transferDestinations.push(transferDestination);
        }

        // Other Destination
        private addOtherDestination() {
            this.parentVm.editingDestination = true;
            var otherDestination = new Models.OtherDestination();

            otherDestination['currentlyEditing'] = true;

            this.record.otherDestinations.push(otherDestination);
        }

        addDestination(destinationType: string) {
            this._prepareForAddEdit();
            if (destinationType === 'storage') {
                this.addStorageDestination();
            } else if (destinationType === 'transfer') {
                this.addTransferDestination();
            } else if (destinationType === 'other') {
                this.addOtherDestination();
            } else {
                throw new Error('Unhandled destinationType: ' + destinationType);
            }
        }

        editDestination(destination: { currentlyEditing: boolean }) {
            this._prepareForAddEdit();
            destination.currentlyEditing = true;
        }

        saveDestination() {
            this.parentVm.editingDestination = false;
            this.parentVm.save();
        }

        deleteDestination(destination: Models.TransferDestination & Models.StorageDestination & Models.OtherDestination) {
            if (getFnName(destination.constructor) === 'StorageDestination') {
                let index = _.findIndex(this.record.storageDestinations, (dest) => {
                    return (dest === destination)
                });
                this.record.storageDestinations.splice(index, 1);
            } else if (getFnName(destination.constructor) === 'TransferDestination') {
                let index = _.findIndex(this.record.transferDestinations, (dest) => {
                    return (dest === destination);
                });
                this.record.transferDestinations.splice(index, 1);
            } else if (getFnName(destination.constructor) === 'OtherDestination') {
                let index = _.findIndex(this.record.otherDestinations, (record) => {
                    return (record === destination)
                });
                this.record.otherDestinations.splice(index, 1);
            } else {
                throw new Error('Unhandled destinationType: ' + getFnName(destination.constructor));
                //return;
            }
            this.parentVm.save();
        }

        resetRecord() {
            let pristineRecord = angular.copy(this.record.copy);
            this.record.load(pristineRecord);
            this.parentVm.editingDestination = false;
        }

        // allowed unless editing a destination
        toggleDestination(destination: Models.TransferDestination & Models.StorageDestination & Models.OtherDestination) {
            return !this.parentVm.editingDestination;
        }

        private _prepareForAddEdit() {
            this.parentVm.editingDestination = true;
            this.destinations.forEach(destination => {
                destination.currentlyEditing = false;
            });
        }

        static $inject = ['$scope'];
        constructor(private $scope: ng.IScope) {
            this.parentVm = $scope['vm'];
            this.record = this.parentVm.record;

            this.record.transferDestinations.forEach(dest => {
                dest['currentlyEditing'] = false;
                return dest;
            });

            this.record.storageDestinations.forEach(dest => {
                dest['currentlyEditing'] = false;
                return dest;
            });

            this.record.otherDestinations.forEach(dest => {
                dest['currentlyEditing'] = false;
                return dest;
            });
        }
    }

    export function ImmediateDestinationsDirective() {
        let template = `
            <div class="row">
                <div class="col-sm-4">
                    <h4>
                        Immediate Destination of Harvest
                    </h4>
                </div>
                <div class="col-sm-8">
                    <button class="btn btn-info btn-sm pull-right" ng-click="im.addDestination('other')" ng-disabled="!im.canAddDestination()">
                        <span class="glyphicon glyphicon-plus"></span> Add Other
                    </button>
                    <button class="btn btn-info btn-sm pull-right margin-right" ng-click="im.addDestination('transfer')" ng-disabled="!im.canAddDestination()">
                        <span class="glyphicon glyphicon-plus"></span> Add Transfer to Other Licensee
                    </button>
                    <button class="btn btn-info btn-sm pull-right margin-right" ng-click="im.addDestination('storage')" ng-disabled="!im.canAddDestination()">
                        <span class="glyphicon glyphicon-plus"></span> Add Storage (Internal)
                    </button>
                    <div class="clearfix"></div>
                </div>
            </div>
            <hr />
            <div class="row table-responsive">
                <table class="table">
                    <thead>
                        <tr>
                            <th style="width: 140px;">Destination Type</th>
                            <th style="width: 140px;">To Account</th>
                            <th style="width: 100px;">Location</th>
                            <th style="width: 100px;">Site</th>
                            <th style="width: 150px;">Estimated Quantity</th>
                            <th>Notes</th>
                            <th style="min-width: 100px;"></th>
                        </tr>
                    </thead>
                    <tbody drawers>
                        <tr ng-repeat="destination in im.destinations" drawer="im.getTemplate(destination)" open-on="destination.currentlyEditing">
                            <td toggle-drawer="im.toggleDestination(destination)">{{im.getDestinationType(destination)}}</td>
                            <td toggle-drawer="im.toggleDestination(destination)">{{im.getToAccount(destination)}}</td>
                            <td toggle-drawer="im.toggleDestination(destination)">{{im.getLocation(destination)}}</td>
                            <td toggle-drawer="im.toggleDestination(destination)">{{im.getSite(destination)}}</td>
                            <td toggle-drawer="im.toggleDestination(destination)">{{im.getEstimatedQuantity(destination)}}</td>
                            <td toggle-drawer="im.toggleDestination(destination)">{{destination.notes | limitTo: 100}}<span ng-show="destination.notes.length > 100">...</span></td>
                            <td class="pull-right">
                                <div ng-show="!destination.currentlyEditing">
                                    <button on-confirm="im.deleteDestination(destination)" class="btn btn-danger btn-sm" ng-disabled="!im.canEditDestination(destination)" >
                                        <span class="glyphicon glyphicon-trash"></span>
                                    </button>
                                    <button open-drawer="im.editDestination(destination)" class="btn btn-edit btn-sm" ng-disabled="!im.canEditDestination(destination)">
                                        <span class="glyphicon glyphicon-pencil"></span>
                                    </button>
                                </div>
                                <div ng-show="destination.currentlyEditing">
                                    <button close-drawer="im.resetRecord()" class="btn btn-cancel btn-sm">
                                        <span class="glyphicon glyphicon-remove"></span>
                                    </button>
                                    <button close-drawer="im.saveDestination()" class="btn btn-save btn-sm">
                                        <span class="glyphicon glyphicon-ok"></span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        `;
        return {
            restrict: 'E',
            controller: ImmediateDestinationsController,
            controllerAs: 'im',
            bindToController: true,
            template: template
        }
    }
}

angular.module('app').directive('immediateDestinations', App.Directives.ImmediateDestinationsDirective);