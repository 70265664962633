namespace App.Directives {
    AutoFocusDirective.$inject = ['$timeout'];
    export function AutoFocusDirective($timeout: ng.ITimeoutService) {
        var linkFn: ng.IDirectiveLinkFn = function (_scope, _element) {
            $timeout(function () {
                _element[0].focus();
            }, 0);
        }
        return {
            link: linkFn,
            restrict: 'AC'
        };
    }
}
angular.module('app').directive('autoFocus', App.Directives.AutoFocusDirective);
