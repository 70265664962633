module App.Models {
    export class PlantingRecordFlattened extends BaseFlattened {
        acres: number;
    }

    export class PlantingRecord extends _BaseRecord {
        startDate: Date;
        endDate: Date;
        acres: number;
        lot: string;
        estimatedYield: number;
        fieldProperlyIdentified: boolean;
        fieldEntryPointLatitude: number;
        fieldEntryPointLongitude: number;
        fieldEntryPointDescription: string;
        machineryCleanedInspectedBeforePlanting: boolean;
        machineryCleanedInspectedAfterPlanting: boolean;
        anyWasteMaterials: boolean;
        wasteProperlyDisposed: boolean;
        wasteDisposalMethodId: number;
        wasteDisposalMethod: Deregulated.IDisposalMethod;
        wasteDisposalNotes: string;
        anySeedRemaining: boolean;
        seedProperlyDisposed: boolean;
        seedDisposalMethodId: number;
        seedDisposalMethod: Deregulated.IDisposalMethod;
        seedDisposalNotes: string;
        harvestRecords: Models.HarvestRecord[];
        lateBlightIPMRecord: Models.LateBlightIPMRecord;

        /**
         * Getter for WasteDisposalMethod
         */
        get WasteDisposalMethod() {
            return this.wasteDisposalMethod;
        }

        /**
         * Setter for WasteDisposalMethod
         */
        set WasteDisposalMethod(wasteDisposalMethod: Deregulated.IDisposalMethod) {
            this.wasteDisposalMethod = wasteDisposalMethod || null;
            this.wasteDisposalMethodId = wasteDisposalMethod ? wasteDisposalMethod.id : null;
        }

        /**
         * Getter for SeedWasteDisposalMethod
         */
        get SeedDisposalMethod() {
            return this.seedDisposalMethod;
        }

        /**
         * Setter for SeedWasteDisposalMethod
         */
        set SeedDisposalMethod(seedDisposalMethod: Deregulated.IDisposalMethod) {
            this.seedDisposalMethod = seedDisposalMethod || null;
            this.seedDisposalMethodId = seedDisposalMethod ? seedDisposalMethod.id : null;
        }

        /**
         * Overriding super's 'load' method so correct 'this' is returned.
         */
        load(data: {}) {
            super.load(data);
            return this;
        }

        get flattened(): PlantingRecordFlattened {
            return {
                id: this.id,
                recordId: this.recordId,
                accountName: this.accountName,
                number: this.number,
                transactionDate: this.transactionDate,
                locationName: this.locationName,
                siteName: this.siteName,
                varietyName: this.record.variety ? this.record.variety.name : '',
                createdOn: this.record.createdOn,
                recordStatus: this.record.status.name,
                recordType: this.record.type.name,
                acres: this.acres
            }
        }

        constructor() {
            super();
            this.recordType = 'planting';
        }
    }
}