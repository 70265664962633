module App.Directives {
    Calendar.$inject = ['$filter'];
    export function Calendar($filter) {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                ngModel: '=calendar',
                defaultDate: '=',
                ngDisabled: '=',
                change: '&calendarChange',
                placeholder: '@'
            },
            templateUrl: 'views/calendar.html',
            link: (scope, element, attrs) => {
                // Normalize the date value --- prevents Date fields from being marked as invalid
                if (scope.ngModel && typeof(scope.ngModel) == 'string') {
                    scope.ngModel = new Date(scope.ngModel);

                    // Fix it up for the calendar control so the right date is shown
                    // since our ng-model will not have a timezone, and the Date object
                    // insists on applying the local timezone (which pushes us back a day)
                    let offset = scope.ngModel.getTimezoneOffset();
                    scope.ngModel.setMinutes(offset);
                }

                scope.opened = false;

                scope.open = ($event) => {
                    $event.preventDefault();
                    $event.stopPropagation();

                    scope.opened = true;
                }

                scope.$watch(() => {
                    return scope.defaultDate
                }, (newValue, oldValue) => {
                    if (scope.defaultDate && !scope.ngModel) {
                        scope.ngModel = scope.defaultDate;
                    }
                });
            }
        }
    }
}

angular.module('app').directive('calendar', App.Directives.Calendar);
