module App.Models {
    export class Record {
        id: number;
        typeId: number;
        type: {
            id: number;
            name: string;
        };
        accountId: number;
        account: Models.Account;
        statusId: number;
        coordinates: Deregulated.ISiteCoordinates[] = [];
        locationId: number;
        location: Deregulated.ILocation;
        siteId: number;
        site: Deregulated.ISite;
        varietyId: number;
        variety: Models.Variety;
        canUnlock: boolean;
        canSign: boolean;
        canEdit: boolean;
        signingNotes: string;
        isArchived: boolean;
        createdOn: Date;
        updatedOn: Date;

        // these are included just so we can null them out (during copy)
        lastArchivedOn: any;
        originallySignedOn: any;
        originallySignedBy: any;
        lastSignedOn: any;
        lastSignedBy: any;
        numberOfSignings: any;
        status: any;
        history: any;

        /**
         * Getter for account
         */
        get Account() {
            return this.account;
        }
        /**
         * Setter for account
         */
        set Account(account: Models.Account) {
            this.account = account || null;
            this.accountId = account ? account.id : null;
        }

        /**
         * Getter for location
         */
        get Location() {
            return this.location;
        }
        /**
         * Setter for location
         */
        set Location(location: Deregulated.ILocation) {
            this.location = location || null;
            this.locationId = location ? location.id : null;
        }

        /**
         * Getter for site
         */
        get Site() {
            return this.site;
        }
        /**
         * Setter for site
         */
        set Site(site: Deregulated.ISite) {
            this.site = site || null;
            this.siteId = site ? site.id : null;
        }

        /**
         * Getter for variety
         */
        get Variety() {
            return this.variety;
        }
        /**
         * Setter for variety
         */
        set Variety(variety: Models.Variety) {
            this.variety = variety || null;
            this.varietyId = variety ? variety.id : null;
        }

        load(data?: {}) {
            if (data) {
                for (let prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        this[prop] = data[prop];
                        if (prop === 'createdOn' || prop === 'updatedOn') {
                            this[prop] = new Date(data[prop]);
                        }
                    }
                }
            }
            return this;
        }
    }
} 