module App.Models {
    export class OtherDestination {
        id: number;
        company: string;
        address: string;
        estimatedQuantity: number;
        quantityUnitOfMeasurement: string = 'cwt';
        notes: string;
        harvestRecordId: number;

        load(data: {}) {
            for (var prop in data) {
                if (data.hasOwnProperty(prop)) {
                    this[prop] = data[prop];
                }
            }
            return this;
        }
    }

    export class StorageDestination {
        id: number;
        harvestRecordId: number;
        harvestAccount: Models.Account; // in order to lookup available Locations from Account

        locationId: number;
        location: Deregulated.ILocation;

        siteId: number;
        site: Deregulated.ISite;

        storageAreasCleanedFullyPriorToStorage: boolean;
        segregationMaintained: boolean;
        innateStorageAreasClearlyIdentified: boolean;
        storageLocationsSecure: boolean;

        estimatedQuantity: number;
        quantityUnitOfMeasurement: string = 'cwt';
        notes: string;

        load(data: {}) {
            for (var prop in data) {
                if (data.hasOwnProperty(prop)) {
                    this[prop] = data[prop];
                }
            }
            return this;
        }

        /**
         * Getter for location
         */
        get Location() {
            return this.location;
        }
        /**
         * Setter for location
         */
        set Location(location: Deregulated.ILocation) {
            this.location = location || null;
            this.locationId = location ? location.id : null;
        }

        /**
         * Getter for site
         */
        get Site() {
            return this.site;
        }
        /**
         * Setter for site
         */
        set Site(site: Deregulated.ISite) {
            this.site = site || null;
            this.siteId = site ? site.id : null;
        }
    }

    export class TransferDestination {
        id: number;
        harvestRecordId: number;

        accountId: number;
        account: Models.Account;

        locationId: number;
        location: Deregulated.ILocation;

        siteId: number;
        site: Deregulated.ISite;

        estimatedQuantity: number;
        quantityUnitOfMeasurement: string = 'cwt';
        notes: string;

        load(data: {}) {
            for (var prop in data) {
                if (data.hasOwnProperty(prop)) {
                    this[prop] = data[prop];
                }
            }
            return this;
        }

        /**
         * Getter for account
         */
        get Account() {
            return this.account;
        }
        /**
         * Setter for account
         */
        set Account(account: Models.Account) {
            this.account = account || null;
            this.accountId = account ? account.id : null;
        }

        /**
         * Getter for location
         */
        get Location() {
            return this.location;
        }
        /**
         * Setter for location
         */
        set Location(location: Deregulated.ILocation) {
            this.location = location || null;
            this.locationId = location ? location.id : null;
        }

        /**
         * Getter for site
         */
        get Site() {
            return this.site;
        }
        /**
         * Setter for site
         */
        set Site(site: Deregulated.ISite) {
            this.site = site || null;
            this.siteId = site ? site.id : null;
        }
    }

    export class HarvestRecordFlattened extends BaseFlattened {
        acres: number;
    }

    export class HarvestRecord extends _BaseRecord {
        startDate: Date;
        endDate: Date;
        acres: number;
        lot: string;
        estimatedQuantity: number;
        anyWasteMaterials: boolean;
        wasteProperlyDisposed: boolean;
        wasteDisposalMethodId: number;
        wasteDisposalMethod: Deregulated.IDisposalMethod;
        wasteDisposalNotes: string;
        fieldProperlyIdentified: boolean;
        machineryCleanedInspectedBeforeHarvest: boolean;
        machineryCleanedInspectedAfterHarvest: boolean;
        storageDestinations: Models.StorageDestination[] = [];
        transferDestinations: Models.TransferDestination[] = [];
        otherDestinations: Models.OtherDestination[] = [];

        plantingRecords: PlantingRecord[] = [];

        /**
         * Getter for WasteDisposalMethod
         */
        get WasteDisposalMethod() {
            return this.wasteDisposalMethod;
        }

        /**
         * Setter for WasteDisposalMethod
         */
        set WasteDisposalMethod(wasteDisposalMethod: Deregulated.IDisposalMethod) {
            this.wasteDisposalMethod = wasteDisposalMethod || null;
            this.wasteDisposalMethodId = wasteDisposalMethod ? wasteDisposalMethod.id : null;
        }

        /**
         * Overriding super's 'load' method so correct 'this' is returned.
         */
        load(data: {}) {
            super.load(data);
            for (var prop in data) {
                if (data.hasOwnProperty(prop)) {
                    if (prop === 'plantingRecords' && data[prop].length) {
                        var plantingRecords: any[] = data[prop];
                        data[prop] = plantingRecords.map(record => {
                            return new PlantingRecord().load(record);
                        });
                    }
                    if (prop === 'transferDestinations' && data[prop].length) {
                        var transferDestinations: any[] = data[prop];
                        data[prop] = transferDestinations.map(destination => {
                            let x = new TransferDestination().load(destination);
                            return x;
                        });
                    }
                    if (prop === 'storageDestinations' && data[prop].length) {
                        var storageDestinations: any[] = data[prop];
                        data[prop] = storageDestinations.map(destination => {
                            let x = new StorageDestination().load(destination);
                            x.harvestAccount = this.record.account; // so we can get Locations list in storage-drawer
                            return x;
                        });
                    }

                    if (prop === 'otherDestinations' && data[prop].length) {
                        var otherDestinations: any[] = data[prop];
                        data[prop] = otherDestinations.map(destination => {
                            let x = new OtherDestination().load(destination);
                            return x;
                        });
                    }

                    this[prop] = data[prop];
                }
            }
            return this;
        }

        get flattened(): HarvestRecordFlattened {
            return {
                id: this.id,
                recordId: this.recordId,
                accountName: this.accountName,
                number: this.number,
                transactionDate: this.transactionDate,
                locationName: this.locationName,
                siteName: this.siteName,
                varietyName: this.record.variety ? this.record.variety.name : '',
                createdOn: this.record.createdOn,
                recordStatus: this.record.status.name,
                recordType: this.record.type.name,
                acres: this.acres
            }
        }

        constructor() {
            super();
            this.recordType = 'harvest';
        }
    }
}