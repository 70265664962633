module App.Controllers {
    export class RecordsController {
        // TODO - create function to make sure the links in the accounts work.
        records: any[];
        controls = {
            includeArchived: false,
            filterDateOptions: [
                { field: 'createdOn', label: 'Create Date' },
                { field: 'transactionDate', label: 'Report Date' }
            ],
            type: 'all',
            recordTypes: []
        };
        user: Deregulated.IUser;
        disabledAddReason: string;
        searchString = '';

        friendlyRecordTypeName(recordTypeName: string): string {
            // call the global helper
            return friendlyRecordTypeName(recordTypeName);
        }

        changeFilter(notify = true, account?: string) {
            let model: any;
            this.records = null;

            this._getFlattenedRecords(this.controls.type, this.controls.includeArchived)
                .then(records => {
                    this.records = records;
                });

            if (account) {
                this.searchString = account;
                this.$timeout(() => {
                    angular.element('#searchString').trigger('input'); // hack!
                });
            }

            // Change the URL in the address bar but don't reload the page { notify: false }
            if (!notify) {
                this.$state.go(`records.${this.controls.type}.index`, undefined, { notify: false });
            }
        }
        get state(): string {
            switch (this.controls.type) {
                case 'harvest':
                    return 'Harvest';
                case 'postharvest':
                    return 'Post Harvest';
                case 'planting':
                    return 'Planting';
                case 'receipt':
                    return 'Receipt';
                case 'lateblightipm':
                    return 'Late Blight IPM';
                case 'seedharvest':
                    return 'Seed Harvest';
                case 'seedplanting':
                    return 'Seed Planting';
                case 'shipment':
                    return 'Shipment';
                case 'storage':
                    return 'Storage/Inv Adj';
                case 'processing':
                    return 'Processing';
                default:
                    return '';
            } 
        }
        get title(): string {
            return `${this.state} Reports`;
        }
        get loadingMessage(): string {
            if (this.records == null) {
                return `Loading ${this.state} Reports ......`;
            }
            else {
                if (this.controls.type === 'all')
                    return 'There are no reports matching the filter';
                else
                    return `There are no ${this.state} Reports`;
            }
        }
        get createReportLabel(): string {
            return `Add ${this.state} Report`;
        }
        createReport() {
            this.$state.go(`records.${this.controls.type}.create`);
        }

        hideRecordTypeName(recordTypeName: string): boolean {
            // Simplot
            if (this.user.can.viewAccountList) return false;

            // Only Seed Producer can see SeedPlanting and SeedHarvest
            if ((recordTypeName === 'seedplanting' || recordTypeName === 'seedharvest') && this.user.account.type.name !== 'Seed Producer') return true;

            return false;
        }

        disableAdd(): boolean {
            this.disabledAddReason = '';

            // Non-specific type
            if (this.controls.type === 'all') {
                this.disabledAddReason = 'Select a Report Type to add a report';
                return true;
            }

            // Looking at user.can.viewAccountList instead of user.account.isSimplotCompany, since, if 
            // the user can view all the accounts, then they could potentially pick a Simplot Company 
            // for which to add this type of record, even if their own account is not a Simplot Company 
            if (this.user.can.viewAccountList) return false;

            // Account
            if (this.controls.type !== 'planting' && this.controls.type !== 'harvest' && this.controls.type !== 'processing') return false;

            // (Harvest or Planting) and (Seed Producer or Grower)
            if (this.controls.type === 'planting' || this.controls.type === 'harvest') {
                if (this.user.account && this.user.account.type &&
                    (this.user.account.type.name === 'Seed Producer' || this.user.account.type.name === 'Grower')) {
                    return false;
                }
                else {
                    this.disabledAddReason = 'Account type ' + this.user.account.type.name + ' cannot create Planting or Harvest reports';
                }
            }
            // Processing and (Processor or Lab)
            else {
                if (this.user.account && this.user.account.type &&
                    (this.user.account.type.name === 'Processor' || this.user.account.type.name === 'Lab')) {
                    return false;
                }
                else {
                    this.disabledAddReason = 'Account type ' + this.user.account.type.name + ' cannot create Processing reports';
                }
            }

            return true;
        }

        private _getFlattenedRecords(type: string, includeArchived = false) { // use inferred return type
            let model: new () => Models._BaseRecord;

            switch (type) {
                case 'harvest':
                    model = Models.HarvestRecord;
                    break;
                case 'postharvest':
                    model = Models.PostHarvestRecord;
                    break;
                case 'planting':
                    model = Models.PlantingRecord;
                    break;
                case 'receipt':
                    model = Models.ReceiptRecord;
                    break;
                case 'lateblightipm':
                    model = Models.LateBlightIPMRecord;
                    break;
                case 'seedharvest':
                    model = Models.SeedHarvestRecord;
                    break;
                case 'seedplanting':
                    model = Models.SeedPlantingRecord;
                    break;
                case 'shipment':
                    model = Models.ShipmentRecord;
                    break;
                case 'storage':
                    model = Models.StorageRecord;
                    break;
                case 'processing':
                    model = Models.ProcessingRecord;
                    break;
                default:
                    model = Models.GenericRecord;
                    break;
            }

            return this.RecordService.getRecords(model, this.controls.type,
                {
                    includeArchived: includeArchived
                })
                .then(records => records.map(r => r.flattened))
                .then(records => {
                    switch (type) {
                        case 'shipment':
                            return records.map((r: any) => {
                                let record: Models.ShipmentRecordFlattened = r;
                                return {
                                    id: record.id,
                                    accountName: record.accountName,
                                    locationName: record.locationName,
                                    shipToAccount: record.shipToAccount,
                                    shipToLocation: record.shipToLocation,
                                    number: record.number,
                                    createdOn: record.createdOn,
                                    transactionDate: record.transactionDate,
                                    recordType: record.recordType,
                                    recordStatus: record.recordStatus
                                }
                            });
                        case 'receipt':
                            return records.map((r: any) => {
                                let record: Models.ReceiptRecordFlattened = r;
                                return {
                                    id: record.id,
                                    accountName: record.accountName,
                                    locationName: record.locationName,
                                    shipmentAccountName: record.shipmentAccountName,
                                    shipmentLocationName: record.shipmentLocationName,
                                    shipmentRecordNumber: record.shipmentRecordNumber,
                                    number: record.number,
                                    createdOn: record.createdOn,
                                    transactionDate: record.transactionDate,
                                    recordType: record.recordType,
                                    recordStatus: record.recordStatus
                                }
                            });
                        case 'lateblightipm':
                            return records.map((r: any) => {
                                let record: Models.LateBlightIPMRecordFlattened = r;
                                return {
                                    id: record.id,
                                    accountName: record.accountName,
                                    number: record.number,
                                    plantingRecordNumber: record.plantingRecordNumber,
                                    createdOn: record.createdOn,
                                    transactionDate: record.transactionDate,
                                    recordType: record.recordType,
                                    recordStatus: record.recordStatus
                                }
                            });
                        case 'harvest':
                        case 'planting':
                            return records.map((r: any) => {
                                let record: Models.HarvestRecordFlattened | Models.PlantingRecordFlattened = r;
                                return {
                                    id: record.id,
                                    accountName: record.accountName,
                                    locationName: record.locationName,
                                    siteName: record.siteName,
                                    varietyName: record.varietyName,
                                    acres: record.acres,
                                    number: record.number,
                                    createdOn: record.createdOn,
                                    transactionDate: record.transactionDate,
                                    recordType: record.recordType,
                                    recordStatus: record.recordStatus
                                }
                            })
                        case 'all':
                            return records.map((r: any) => {
                                let record: Models.BaseFlattened = r;
                                return {
                                    id: record.id,
                                    accountName: record.accountName,
                                    locationName: record.locationName,
                                    siteName: record.siteName,
                                    number: record.number,
                                    createdOn: record.createdOn,
                                    transactionDate: record.transactionDate,
                                    recordType: record.recordType,
                                    recordStatus: record.recordStatus
                                }
                            });
                        default:
                            return records.map((r: any) => {
                                let record: Models.BaseFlattened = r;
                                return {
                                    id: record.id,
                                    accountName: record.accountName,
                                    locationName: record.locationName,
                                    siteName: record.siteName,
                                    number: record.number,
                                    createdOn: record.createdOn,
                                    transactionDate: record.transactionDate,
                                    recordType: record.recordType,
                                    recordStatus: record.recordStatus
                                }
                            });
                    }
                })
                .then(data => this.records = data);
        }

        private _getRecordTypes() {
            return this.LookupService.getStandardRecordTypes();
        }

        static $inject = ['RecordService', 'LookupService', '$state', '$rootScope', '$stateParams', '$timeout'];
        constructor(
            private RecordService: Services.RecordService,
            private LookupService: Services.LookupService,
            private $state: ng.ui.IStateService,
            private $rootScope: ng.IRootScopeService,
            private $stateParams: ng.ui.IStateParamsService,
            private $timeout: ng.ITimeoutService) {
            this.user = $rootScope.user;

            this.controls.type = this.$state.current.name.substring(this.$state.current.name.indexOf('.') + 1, this.$state.current.name.lastIndexOf('.'));
            
            this.changeFilter(undefined, $stateParams['account']);

            this._getRecordTypes().then(rt => this.controls.recordTypes = rt);
        }
    }

    RecordsControllerRoute.$inject = ['$stateProvider'];
    export function RecordsControllerRoute($stateProvider: angular.ui.IStateProvider) {
        $stateProvider.state('records', {
            url: '/records',
            abstract: true,
            template: '<ui-view/>'
        })
            .state('records.all', {
                abstract: true,
                url: '/all',
                template: '<ui-view/>'
            })
            .state('records.all.index', {
                url: '?account',
                templateUrl: 'views/records.html',
                controller: RecordsController,
                controllerAs: 'vm',
                title: 'Reports'
            })
            .state('records.planting', {
                abstract: true,
                url: '/planting',
                template: '<ui-view/>',
            })
            .state('records.planting.index', {
                url: '?account',
                templateUrl: 'views/records.html',
                controller: RecordsController,
                controllerAs: 'vm',
                title: 'Crop Production Reports – Planting' // note - this is a dash, not a hyphen
            })
            .state('records.lateblightipm', {
                abstract: true,
                url: '/lateblightipm',
                template: '<ui-view/>'
            })
            .state('records.lateblightipm.index', {
                url: '?account',
                templateUrl: 'views/records.html',
                controller: RecordsController,
                controllerAs: 'vm',
                title: 'Late Blight IPM Reports'
            })
            .state('records.harvest', {
                abstract: true,
                url: '/harvest',
                template: '<ui-view/>'
            })
            .state('records.harvest.index', {
                url: '?account',
                templateUrl: 'views/records.html',
                controller: RecordsController,
                controllerAs: 'vm',
                title: 'Crop Production Reports – Harvest'
            })
            .state('records.shipment', {
                abstract: true,
                url: '/shipment',
                template: '<ui-view/>'
            })
            .state('records.shipment.index', {
                url: '?account',
                templateUrl: 'views/records.html',
                controller: RecordsController,
                controllerAs: 'vm',
                title: 'Crop Movement Reports – Shipment'
            })
            .state('records.receipt', {
                abstract: true,
                url: '/receipt',
                template: '<ui-view/>'
            })
            .state('records.receipt.index', {
                url: '?account',
                templateUrl: 'views/records.html',
                controller: RecordsController,
                controllerAs: 'vm',
                title: 'Crop Movement Reports – Receipt'
            })
            .state('records.storage', {
                abstract: true,
                url: '/storage',
                template: '<ui-view/>'
            })
            .state('records.storage.index', {
                url: '?account',
                templateUrl: 'views/records.html',
                controller: RecordsController,
                controllerAs: 'vm',
                title: 'Storage/Inventory Adjustment Reports'
            })
            .state('records.processing', {
                abstract: true,
                url: '/processing',
                template: '<ui-view/>'
            })
            .state('records.processing.index', {
                url: '?account',
                templateUrl: 'views/records.html',
                controller: RecordsController,
                controllerAs: 'vm',
                title: 'Processing Reports'
            })
            .state('records.postharvest', {
                abstract: true,
                url: '/postharvest',
                template: '<ui-view/>'
            })
            .state('records.postharvest.index', {
                url: '?account',
                templateUrl: 'views/records.html',
                controller: RecordsController,
                controllerAs: 'vm',
                title: 'Post Harvest Reports'
            })
            .state('records.seedplanting', {
                abstract: true,
                url: '/seedplanting',
                template: '<ui-view/>'
            })
            .state('records.seedplanting.index', {
                url: '?account',
                templateUrl: 'views/records.html',
                controller: RecordsController,
                controllerAs: 'vm',
                title: 'Seed Planting Reports'
            })
            .state('records.seedharvest', {
                abstract: true,
                url: '/seedharvest',
                template: '<ui-view/>'
            })
            .state('records.seedharvest.index', {
                url: '?account',
                templateUrl: 'views/records.html',
                controller: RecordsController,
                controllerAs: 'vm',
                title: 'Seed Harvest Reports'
            })
            ;
    }
}

angular.module('app').config(App.Controllers.RecordsControllerRoute);  