module App.Controllers {
    export class UserPasswordResetController {
        username: string;
        requestSubmitted: boolean;
        requestSuccess: boolean;

        submitResetRequest = () => {
            // call users/passwordreset (put user object in header)
            this.UserService.passwordReset(this.username).then(() => {
                this.requestSubmitted = true;
                this.requestSuccess = true;
            }, (errors) => {
                this.requestSubmitted = true;
                this.requestSuccess = false;
            });
        }

        cancel = () => {
            this.$state.go('login');
        }

        static $inject = ['$state', 'UserService'];
        constructor(
            private $state: ng.ui.IStateService,
            private UserService: Services.UserService
        ) {
            this.requestSubmitted = false;
        }
    }

    UserPasswordResetControllerRoute.$inject = ['$stateProvider'];
    export function UserPasswordResetControllerRoute($stateProvider: angular.ui.IStateProvider) {
        $stateProvider
            .state('passwordreset', {
                abstract: true,
                url: '/passwordreset',
                template: '<ui-view/>'
            })
            .state('passwordreset.ready', {
                url: '',
                templateUrl: 'views/user-passwordreset.html',
                controller: UserPasswordResetController,
                controllerAs: 'vm',
                title: 'Password Reset'
            });
    }
}

angular.module('app').config(App.Controllers.UserPasswordResetControllerRoute);
 