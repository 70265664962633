module App.Services
{
    export class UserService
    {
        getUsers(filters: Models.Filters = null): ng.IPromise<Models.User[]> {
            return this.ApiService.getItems<Models.User>({ entity: 'users', modelType: Models.User, filters: filters });
        }

        getUser(id: number): ng.IPromise<Models.User> {
            return this.ApiService.getItem<Models.User>({ entity: 'users', id: id, modelType: Models.User });
        }

        getUserByKey(key: string): ng.IPromise<Models.User> {
            return this.ApiService.get('users/bykey?key=' + key);
        }

        editUser(user: Models.User) {
            return this.ApiService.editItem<Models.User>('users', user);
        }

        addUser(user: Models.User) {
            return this.ApiService.addItem<Models.User>('users', user);
        }

        removeUser(user: Models.User) {
            return this.ApiService.removeItem<Models.User>('users', user);
        }

        activateUser(user: Models.User) {
            return this.ApiService.post('users/activate/' + user.id, user);
        }

        deactivateUser(user: Models.User) {
            return this.ApiService.post('users/deactivate/' + user.id, user);
        }

        passwordChange(username: string, password: string, key: string) : ng.IPromise<any> {
            return this.ApiService.post('users/passwordchange', { username: username, hashedPassword: password, token: key }, false);
        }

        passwordReset(username: string): ng.IPromise<any> {
            return this.ApiService.post('users/passwordreset', { username: username } );
        }

        static ID = 'UserService';
        static $inject = ['ApiService'];
        constructor(private ApiService: ApiService) {
            Models.User.prototype.UserService = this;
        }
    }
    angular.module('app').service('UserService', UserService);
}