module App.Directives {
    class CountySelectController {
        counties: Deregulated.ICounty[] = [];
        county: Deregulated.ICounty;
        stateProvinceId: number;

        static $inject = ['LookupService', '$scope'];
        constructor(LookupService: App.Services.LookupService, $scope: ng.IScope) {
            $scope.$watch(() => { return this.stateProvinceId }, (newId, oldId) => {
                //console.log('County... StateId change newId: ' + newId + ', oldId: ' + oldId);

                if (!newId || (parseInt(newId) !== parseInt(oldId))) {
                    this.counties = [];
                }

                if (newId) {
                    LookupService.getCounties(this.stateProvinceId).then((counties) => {
                        //console.log('got counties: ' + counties.length);
                        this.counties = counties;
                        if (!this.county || _.findIndex(this.counties, { id: this.county.id }) == -1) {
                            //console.log('set county to null');
                            this.county = null;
                        }
                    });
                }
                else {
                    this.county = null;
                }
            });
        }
    }

    CountySelect.$inject = ['LookupService'];
    export function CountySelect(LookupService: App.Services.LookupService)
    {
        let scope = {
            stateProvinceId: '=',
            county: '=ngModel',
            ngDisabled: '='
        }

        let template = `
            <select
                ng-options="county.name for county in co.counties track by county.id"
                ng-model="co.county"
                ng-disabled="co.ngDisabled">
                <option value="">-- Select One --</option>
            </select>
        `;

        let linkFn: ng.IDirectiveLinkFn = function (scope, element, attrs) {
            angular.element(element)
                .find('select')
                .attr('class', attrs['class'])
                .attr('name', attrs['name']);

            angular.element(element)
                .removeAttr('class')
                .removeAttr('name');
        }

        return {
            scope: scope,
            restrict: 'E',
            template: template,
            link: linkFn,
            controller: CountySelectController,
            controllerAs: 'co',
            bindToController: true
        }
    }
}

angular.module('app').directive('countySelect', App.Directives.CountySelect);