module App.Controllers {

    enum RecordTypeEnum {
        Harvest,
        Incident,
        Planting,
        PostHarvest,
        Processing,
        Receipt,
        SeedHarvest,
        SeedPlanting,
        Shipment,
        Storage,
        LateBlightIPM
    }

    export class AccountController {
        account: Models.Account;
        isInEditMode: boolean = false;
        editingSite: boolean = false;
        errors: string[];

        controls: {
            accountTypes: Models.AccountType[];
            userStatuses: Models.UserStatus[];
            siteTypes: Models.SiteType[];
            states: Deregulated.IStateProvince[];
            lateBlightAreas: Deregulated.ILookup;
        }

        getRecordTypeString(recordType: RecordTypeEnum) {
            return RecordTypeEnum[recordType].toLowerCase();
        }

        getFriendlyRecordTypeName(recordType: RecordTypeEnum) {
            return friendlyRecordTypeName(RecordTypeEnum[recordType]);
        }

        saveNetwork() {
            // saves whole account
            return this.save(true).then(() => {
                this.toaster.success('Network Saved');
            }, (error) => {
                this.toaster.error('Network Save Failed');
                return this.$q.reject(error);
            });
        }

        saveLocation() {
            // saves whole account
            return this.save(true).then(() => {
                this.toaster.success('Location Saved');
            }, (error) => {
                this.toaster.error('Location Save Failed');
                return this.$q.reject(error);
            });
        }

        saveUser(user: Models.User) {
            return user.save().then(() => {
                this.toaster.success('User Saved');
                this.isInEditMode = false;
                this.editingSite = false;
                this.errors = null;
                // update the copy so Cancel doesn't mess up user edits
                this.account.copy.users = angular.copy(this.account.users); 
            }, (reason) => {
                this.toaster.error('User Save Failed');
                if (reason && reason.data && reason.data.errors && reason.data.errors.length) {
                    this.errors = reason.data.errors;
                    return this.$q.reject(reason.data.errors);
                }
                return this.$q.reject(reason);
            });
        }

        save(mute = false) {
            var activeLocationIdx = _.findIndex(this.account.locations, location => {
                return location['active'];
            });
            var activeUserIdx = _.findIndex(this.account.users, user => {
                return user['active'];
            });
            return this.account.save(mute).then((account) => {
                if (this.$state.current.name === 'accounts.create') {
                    this.$state.go('accounts.account', { id: account.id });
                } else {
                    this.isInEditMode = false;
                    this.editingSite = false;
                }
                this.errors = null;

                // Keep our place in the vertical tabs
                if (activeUserIdx > -1) this.account.users[activeUserIdx]['active'] = true;
                if (activeLocationIdx > -1) this.account.locations[activeLocationIdx]['active'] = true;

                return true;
            }, (errors) => {    
                if (errors.length) {
                    this.errors = errors;
                    //this.account.reset();
                }
                return this.$q.reject(errors);
            });
        }

        cancel = () => {
            if (this.$state.current.name === 'accounts.create') {
                this.$state.go('accounts.index');
            } else {
                var activeLocationIdx = _.findIndex(this.account.locations, location => {
                    return location['active'];
                });
                var activeUserIdx = _.findIndex(this.account.users, user => {
                    return user['active'];
                });
                this.errors = null;
                this.account.reset();
                this.isInEditMode = false;

                // Keep our place in the vertical tabs (unless we are canceling an add)
                if (activeUserIdx > -1 && this.account.users[activeUserIdx]) this.account.users[activeUserIdx]['active'] = true;
                if (activeLocationIdx > -1 && this.account.locations[activeLocationIdx]) this.account.locations[activeLocationIdx]['active'] = true;
            }
        }

        // Locations
        addLocation = () => {
            this.account.locations = this.account.locations || [];
            var contactInformation = new Models.ContactInformation();
            var Location: Deregulated.ILocation = {
                accountId: null,
                name: null,
                type: null,
                contactInformation: contactInformation,
                sites: [],
                lateBlightArea: null
            };
            Location['active'] = true;
            this.isInEditMode = true;
            this.account.locations.push(Location);
        }

        copyAccountAddressToLocation = (index) => {
            this.copyAddress(this.account.contactInformation, this.account.locations[index].contactInformation);
        }

        deleteLocation = (index) => {
            var removedLocation = this.account.locations.splice(index, 1)[0];
            this.save(true).then(() => {
                this.toaster.success('Location Deleted');
            }, (errors) => {
                this.toaster.error('Location Delete Failed');
                this.account.locations.splice(index, 0, removedLocation);
            });
        }

        // Users
        addUser = () => {
            this.account.users = this.account.users || [];
            let user = new Models.User();
            user['active'] = true; // Sets Tab to active when new user is added

            // Set the starting status id, but don't show the name of it until the save occurs
            var startStatus: Models.UserStatus = (this.account.isSimplotCompany) ?
                <Models.UserStatus>_.find(this.controls.userStatuses, { name: 'Active' }) :
                <Models.UserStatus>_.find(this.controls.userStatuses, { name: 'Pending' });
            if (startStatus) user.statusId = startStatus.id;

            user.accountId = this.account.id;

            this.account.users.push(user);
            this.isInEditMode = true;
        }

        openCopyLocationAddressModal(userIndex: number): void {
            if (this.account.locations.length === 1) {
                this.copyLocationAddressToUser(userIndex, this.account.locations[0]);
            }
            else if (this.account.locations.length > 1) {
                // pop up modal to choose location
                var modal = this.$modal.open(AccountLocations.getControllerConfig(this.account.locations));

                modal.result.then(location => this.copyLocationAddressToUser(userIndex, location), reason => {
                    // Dismissed.
                });
            }
        }

        copyLocationAddressToUser(userIndex: number, location: Deregulated.ILocation) {
            this.copyAddress(location.contactInformation, this.account.users[userIndex].contactInformation);
        }

        deleteUser = (index) => {
            var user = <Models.User>this.account.users[index];
            user.remove().then(() => {
                this.account.users.splice(index, 1)[0];
                this.toaster.success('User Deleted');
                this.isInEditMode = false;
                this.editingSite = false;
                this.errors = null;
            }, (reason) => {
                this.toaster.error('User Delete Failed');
                if (reason && reason.data && reason.data.errors && reason.data.errors.length) {
                    this.errors = reason.data.errors;
                    return this.$q.reject(reason.data.errors);
                }
                return this.$q.reject(reason);
            });
        }

        activateUser = (index) => {
            if (this.account.users[index].hasOwnProperty('id')) {
                this.UserService.activateUser(<Models.User>this.account.users[index]).then((user) => {
                    if (this.account.isSimplotCompany) {
                        this.toaster.success('User Activated');
                    }
                    else {
                        this.toaster.success('User Activation Initiated');
                    }
                    this.account.users[index] = new Models.User().load(user);
                    this.isInEditMode = false;
                    this.account.users[index]['active'] = true; // this is tab item, not user status
                }, (errors) => {
                    this.toaster.success('User Activation Failed');
                    if (errors.length) {
                        this.errors = errors;
                    }
                    this.account.users[index]['active'] = true; // this is tab item, not user status
                });
            }
        }

        deactivateUser = (index) => {
            if (this.account.users[index].hasOwnProperty('id')) {
                this.UserService.deactivateUser(<Models.User>this.account.users[index]).then((user) => {
                    this.toaster.success('User Deactivated');
                    this.account.users[index] = new Models.User().load(user);
                    this.isInEditMode = false;
                    this.account.users[index]['active'] = true; // this is tab item, not user status
                }, (errors) => {
                    this.toaster.success('User Deactivation Failed');
                    if (errors.length) {
                        this.errors = errors;
                    }
                    this.account.users[index]['active'] = true; // this is tab item, not user status
                });
            }
        }

        canEditThisRole(role: Deregulated.IRole, checkCompleteAccess: boolean = false): boolean {
            var currentUser = this.$rootScope.user;

            // Check if currentUser can edit the users of this role
            if (role.name === 'Simplot Admin' && !currentUser.can.editSimplotAdmin) {
                return false;
            } else if (role.name === 'Simplot User' && !currentUser.can.editSimplotUser) {
                return false;
            } else if (role.name === 'Account Admin' && !currentUser.can.editAccountAdmin && (checkCompleteAccess || !currentUser.can.editAccountUserRestricted)) {
                return false;
            } else if (role.name === 'Account User' && !currentUser.can.editAccountUser && (checkCompleteAccess || !currentUser.can.editAccountUserRestricted)) {
                return false;
            } else {
                return true;
            }
        }

        canEditUsername(user: Deregulated.IUser): boolean {
            // if new user allow edit
            if (user.id == null) return true;

            // cannot edit own username, ever
            let currentUser = this.$rootScope.user;
            if (user.id === currentUser.id) return false;

            if (!this.canEditThisRole(user.role, true)) return false;

            // cannot edit existing users' username unless isSimplotCompany
            return this.account.isSimplotCompany;
        }

        canEditRoleAndEmailFields(user: Deregulated.IUser): boolean {
            // if new user allow edit
            if (user.id == null) return true;

            let currentUser = this.$rootScope.user;
            if (currentUser.id == user.id) return false;

            // not interested in "restricted" access, want to know complete access
            return this.canEditThisRole(user.role, true);
        }

        prepareLockMessage(recordNumbers: string[]): string {
            if (recordNumbers == undefined) return '';
            let msg = recordNumbers.join(', ');
            let output = [];
            let reportTypes = {
                PL: 'Planting',
                HV: 'Harvest',
                SH: 'Shipment',
                ST: 'Storage',
                RE: 'Receipt',
                PR: 'Processing',
                PO: 'Post Harvest',
                PS: 'Seed Planting',
                HS: 'Seed Harvest'
            }

            if (recordNumbers.length > 10) {
                for (let rtype in reportTypes) {
                    let typeCheck = new RegExp(rtype + '_');
                    if (typeCheck.test(msg)) {
                        output.push(reportTypes[rtype]);
                    }
                }
            }

            return output.length ? output.join(', ') : msg;
        }

        filterUserRoles = (role) => {
            if (this.account.isSimplotCompany) {
                if (role.name.indexOf('Simplot') > -1) return true;
            }
            else {
                if (role.name.indexOf('Account') > -1) return true;
            }
            return false;
        }

        filterStates = (allStates, countryId) => {
            var filteredStates = this.controls.states.filter(s => {
                return s.countryId == countryId;
            });
            return filteredStates;
        }

        filterAvailableSiteTypes = (existingId: number): Deregulated.ILookup[] => {
            var filteredSiteTypes = this.controls.siteTypes.filter(st => {
                return st.id == existingId || st.active;
            });
            return filteredSiteTypes;
        }

        getFieldTypeId = (): number => {
            return _.find(this.controls.siteTypes, { name: 'Field' }).id;
        }

        copyAddress(source: Deregulated.IContactInformation, destination: Deregulated.IContactInformation) {
            destination.country = source.country;
            destination.countryId = source.countryId;
            destination.stateProvince = source.stateProvince;
            destination.stateProvinceId = source.stateProvinceId;
            destination.address1 = source.address1;
            destination.address2 = source.address2;
            destination.phone = source.phone;
            destination.phone2 = source.phone2;
            destination.fax = source.fax;
            destination.city = source.city;
            destination.postalCode = source.postalCode;
            destination.county = source.county;
            destination.countyId = source.countyId;
        }

        changeCountry(location: Deregulated.ILocation) {
            location.contactInformation.stateProvinceId = undefined;
            location.contactInformation.countyId = undefined; // takes care of county info in User - which has no County control
        }

        static $inject = ['account', 'controls', 'AccountService', 'UserService', '$state', '$filter', '$rootScope', 'toaster', '$q', '$modal', '$timeout'];
        constructor(
            account,
            controls,
            private AccountService: Services.AccountService,
            private UserService: Services.UserService,
            private $state: ng.ui.IStateService,
            private $filter: ng.IFilterService,
            private $rootScope: ng.IRootScopeService,
            private toaster: ng.toaster.IToasterService,
            private $q: ng.IQService,
            private $modal: ng.ui.bootstrap.IModalService,
            private $timeout: ng.ITimeoutService
        ) {

            this.account = account;
            this.controls = controls;

            if ($state.current.name === 'accounts.create') {
                this.isInEditMode = true;
            }

            // filter out inactive account types unless it is being used here
            this.controls.accountTypes = this.controls.accountTypes.filter(at => at.active || at.id == this.account.typeId);
        }
    }

    AccountControllerRoute.$inject = ['$stateProvider'];
    export function AccountControllerRoute($stateProvider: angular.ui.IStateProvider) {
        $stateProvider
            .state('accounts.create', {
                url: '/add',
                templateUrl: 'views/account.html',
                controller: AccountController,
                controllerAs: 'vm',
                title: 'Create Account',
                resolve: {
                    account: function () {
                        var account = new Models.Account().load({});
                        return account;
                    },
                    controls: ['LookupService', '$q', (LookupService: Services.LookupService, $q: ng.IQService) => {
                        return $q.all([LookupService.getRoles(), LookupService.getAccountTypes(false),
                            LookupService.getSiteTypes(false), LookupService.getStates(), LookupService.getCountries(),
                            LookupService.getNetworkPriorities(), LookupService.getUserStatuses(),
                            LookupService.getLateBlightAreas()]).then((data) => {
                            var _controls = {
                                roles: data[0],
                                accountTypes: data[1],
                                siteTypes: data[2],
                                states: data[3],
                                countries: data[4],
                                networkPriorities: data[5],
                                userStatuses: data[6],
                                lateBlightAreas: data[7]
                            };
                            return _controls;
                        });
                    }]
                }
            })
            .state('accounts.account', {
                url: '/:id',
                templateUrl: 'views/account.html',
                controller: AccountController,
                controllerAs: 'vm',
                title: 'Account',
                resolve: {
                    account: ['$stateParams', 'AccountService', ($stateParams: ng.ui.IStateParamsService, AccountService: Services.AccountService) => {
                        return AccountService.getAccount($stateParams['id']).then(function (_account) {
                            return _account;
                        });
                    }],
                    controls: ['LookupService', '$q', (LookupService: Services.LookupService, $q: ng.IQService) => {
                        return $q.all([LookupService.getRoles(), LookupService.getAccountTypes(false),
                            LookupService.getSiteTypes(false), LookupService.getStates(), LookupService.getCountries(),
                            LookupService.getNetworkPriorities(), LookupService.getUserStatuses(),
                            LookupService.getLateBlightAreas()]).then((data) => {
                            var _controls = {
                                roles: data[0],
                                accountTypes: data[1],
                                siteTypes: data[2],
                                states: data[3],
                                countries: data[4],
                                networkPriorities: data[5],
                                userStatuses: data[6],
                                lateBlightAreas: data[7]
                            };
                            return _controls;
                        });
                    }]
                }
            });
    }
}

angular.module('app').config(App.Controllers.AccountControllerRoute);
