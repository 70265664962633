module App.Models {
    export class IncidentRecordFlattened extends BaseFlattened {}
    export class IncidentRecord extends _BaseRecord {
        contactName: string;
        contactPhone: string;
        contactEmail: string;
        incidentDate: Date;
        incidentLocation: string;
        productsInvolved: string;
        lotNumbers: string;
        incidentDetails: string;
        simplotNotified: boolean;
        notificationName: string;
        notificationDate: Date;
        responseDetails: string;
        correctiveAction: string;
        notes: string;

        load(data: {}) {
            super.load(data);
            return this;
        }

        constructor() {
            super();
            this.recordType = 'incident';
        }
    }
} 