module App.Services {
    export class InventoryService {

        getInventory(filters?: App.Models.Filters): ng.IPromise<Models.InventoryRow[]> {
            return this.ApiService.getItems({ entity: 'inventory', messageProperty: 'inventoryRows', modelType: Models.InventoryRow, filters: filters });
        }

        getInventoryReport(filters?: App.Models.Filters): ng.IPromise<any> {
            return this.ApiService.getExcelReport('inventory/report', filters);
        }
        
        static $inject = ['ApiService', 'toaster', '$q'];
        constructor(private ApiService: ApiService, private toaster: ng.toaster.IToasterService, private $q: ng.IQService) {
        }
    }
    angular.module('app').service('InventoryService', InventoryService);
}
