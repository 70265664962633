module App.Directives
{
    class LocationSelectController {
        locations: Deregulated.ILocation[] = [];
        locationModel: Deregulated.ILocation;
        showAddress: boolean;

        account: Deregulated.IAccount;

        getLocation(locations: Deregulated.ILocation[], locationId: number) {
            let location = _.find(locations, { id: locationId });
            return location ? location : null;
        }

        getDisplayName(location: Deregulated.ILocation): string {
            let display = location.name;

            if (this.showAddress) {
                display += ' - ' + location.contactInformation.address1 + ', ' +
                    location.contactInformation.city + ' ' +
                    location.contactInformation.stateProvince.code;
            }

            return display;
        }

        static $inject = ['AccountService', '$scope'];
        constructor(private AccountService: App.Services.AccountService, $scope: ng.IScope) {
            $scope.$watch(() => this.account, (newAccount: Deregulated.IAccount, oldAccount: Deregulated.IAccount) => {
                if (newAccount) {
                    this.AccountService.getAccount(newAccount.id)
                        .then(account => {
                            this.locations = account.locations;

                            if (this.locationModel && !_.find(this.locations, { id: this.locationModel.id })) {
                                this.locationModel = null;
                            }
                            // If only one, select it
                            if ((oldAccount == null || newAccount.id != oldAccount.id) && this.locations.length === 1) {
                                this.locationModel = this.locations[0];
                            }
                        });
                } else {
                    this.locations = [];
                    this.locationModel = null;
                }
            });
        }
    }

    LocationSelect.$inject = ['AccountService'];
    export function LocationSelect(AccountService: App.Services.AccountService)
    {
        let scope = {
            account: '=',
            locationModel: '=ngModel',
            showAddress: '=',
            ngDisabled: '='
        }

        let template = `
            <select
                ng-options="lo.getDisplayName(location) for location in lo.locations track by location.id"
                ng-model="lo.locationModel"
                ng-disabled="lo.ngDisabled">
                <option value="">-- Select One --</option>
            </select>
        `;


        let linkFn: ng.IDirectiveLinkFn = function (scope, element, attrs) {
            angular.element(element)
                .find('select')
                .attr('class', attrs['class'])
                .attr('name', attrs['name']);

            angular.element(element)
                .removeAttr('class')
                .removeAttr('name');
        }

        return {
            scope: scope,
            restrict: 'E',
            template: template,
            link: linkFn,
            controller: LocationSelectController,
            controllerAs: 'lo',
            bindToController: true
        }
    }
}

angular.module('app').directive('locationSelect', App.Directives.LocationSelect);