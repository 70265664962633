angular.module('app').directive('stDateRange', ['$timeout', function ($timeout) {
    return {
        restrict: 'E',
        require: '^stTable',
        scope: {
            before: '=?',
            after: '=?',
            predicate: '='
        },
        template: `
<div class="row">
<div class="col-sm-6">
    <label>from</label>
    <div class="input-group">
	    <input type="text" class="form-control" datepicker-popup="MM/dd/yyyy" ng-model="after" is-open="isAfterOpen"
				     close-text="Close"/>
	    <span class="input-group-btn"> 
                    <button type="button" class="btn btn-default" ng-click="openAfter($event)"><i
									    class="glyphicon glyphicon-calendar"></i></button>
                    </span>
    </div>
</div>
<div class="col-sm-6">
    <label>to</label>
    <div class="input-group">
	    <input type="text" class="form-control" datepicker-popup="MM/dd/yyyy" ng-model="before" is-open="isBeforeOpen"
				     close-text="Close"/>
	    <span class="input-group-btn"> 
                    <button type="button" class="btn btn-default" ng-click="openBefore($event)"><i
									    class="glyphicon glyphicon-calendar"></i></button>
                    </span>
    </div>
</div>
</div>
`,

        link: function (scope, element, attr, table) {

            var inputs = element.find('input');
            var inputBefore = angular.element(inputs[0]);
            var inputAfter = angular.element(inputs[1]);

            var predicate: { field: string; label: string; };
            var query: any = {};

            scope.$watch('predicate', function (newValue) {
                if (predicate && newValue !== predicate) {
                    table.search('', predicate.field);
                }
                if (newValue) {
                    predicate = newValue;
                    table.search(query, predicate.field);
                }
            });

            [inputBefore, inputAfter].forEach(function (input) {

                $(document).on('click', 'ul.dropdown-menu button', function (this, event) {
                    input.trigger('blur');
                });

                input.bind('blur', function () {

                    if (!scope.isBeforeOpen && !scope.isAfterOpen) {

                        if (scope.before) {
                            query.before = scope.before;
                        }

                        if (scope.after) {
                            query.after = scope.after;
                        }

                        scope.$apply(function () {
                            if (predicate && scope.predicate !== predicate) {
                                table.search('', predicate.field);
                            }
                            if (scope.predicate) {
                                predicate = scope.predicate;
                                table.search(query, predicate.field);
                            }
                        });
                    }
                });
            });

            function open(before: boolean = false) {
                return function ($event) {
                    $event.preventDefault();
                    $event.stopPropagation();

                    if (before) {
                        scope.isBeforeOpen = true;
                    } else {
                        scope.isAfterOpen = true;
                    }
                }
            }

            scope.openBefore = open(true);
            scope.openAfter = open();
        }
    }
}]);