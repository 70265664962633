module SmartTable
{
    SmartTableTemplate.$inject = ['$templateCache'];
    export function SmartTableTemplate($templateCache: ng.ITemplateCacheService)
    {
        var paginationTemplate = [
            '<nav ng-if="pages.length >= 2"><ul class="pagination">',
                '<li ng-class="{disabled: currentPage==1}" ng-if="numPages > pages.length"><a aria-label="Previous" ng-click="selectPage(currentPage - 1)"><span aria-hidden="true">&laquo;</span></a></li>',
                '<li ng-repeat="page in pages" ng-class="{active: page==currentPage}"><a ng-click="selectPage(page)">{{page}}</a></li>',
                '<li ng-if="numPages > pages.length" class="disabled total"><a>of {{numPages}}</a></li>',
                '<li ng-class="{disabled: currentPage==numPages}" ng-if="numPages > pages.length"><a aria-label="Next" ng-click="selectPage(currentPage + 1)"><span aria-hidden="true">&raquo;</span></a></li>',
            '</ul></nav>'
        ].join('');
        $templateCache.put('template/smart-table/pagination.html', paginationTemplate);
    }
}

angular.module('smart-table').run(SmartTable.SmartTableTemplate);