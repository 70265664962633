module App.Services {
    export class ApiService {
        private ApiUrl: string;
        

        getItems<T extends Abstractions.IDataLoader>(request: { entity: string; messageProperty?: string; filters?: Models.Filters; modelType: { new (): T; } }): ng.IPromise<T[]>;

        getItems<T>(entity: string, loading?: boolean, cache?: boolean, filters?: Models.Filters): ng.IPromise<T[]>;

        getItems<T>(request: any, loading?: boolean, cache?: boolean, filters?: Models.Filters): ng.IPromise<T[]> {
            var url = this.ApiUrl,
                modelType,
                entity;
            if (typeof request === 'string') {
                url = url + request;
                entity = request.split('/').pop();
            } else if (typeof request === 'object') {
                url = url + request.entity;
                filters = request.filters;
                entity = request.messageProperty || request.entity;
                if (request.hasOwnProperty('modelType') && typeof request.modelType !== 'undefined') {
                    modelType = request.modelType;
                }
            }

            loading = (typeof loading === 'undefined') ? true : loading; // Default to show the loading indicator.

            var r;

            if (modelType) {
                return this._makeRequest({ method: 'get', url: url, cache: cache, params: filters }, loading, false, entity).then((data) => {
                    data = data || [];
                    if (data.length) {
                        return data.map((d) => {
                            var model = new modelType();
                            return model.load(d);
                        });
                    }
                    else {
                        return data;
                    }
                });
            } else {
                return this._makeRequest({ method: 'get', url: url, cache: cache, params: filters }, loading, false, entity);
            }
        }

        getItem<T extends Abstractions.IDataLoader>(request: { entity: string; id: number | string; messageProperty?: string; modelType?: { new (): T; }; }): ng.IPromise<T>;

        getItem<T>(entity: string, id: number|string): ng.IPromise<T>;

        getItem<T>(request: any, id?: number|string, loading?: boolean): ng.IPromise<T> {
            var url = this.ApiUrl,
                modelType,
                entity;

            if (typeof request === 'string') {
                url = url + request + '/' + id;
                entity = request;
            } else if (typeof request === 'object') {
                url = url + request.entity + '/' + request.id;
                entity = request.messageProperty || request.entity;
                if (request.hasOwnProperty('modelType') && typeof request.modelType !== 'undefined') {
                    modelType = request.modelType;
                }
            }

            loading = (typeof loading === 'undefined') ? true : loading; // Default to show the loading indicator.

            if (modelType) {
                return this._makeRequest({ method: 'get', url: url }, loading, true, entity).then((data) => {
                    var model = new modelType();
                    return model.load(data);
                });
            } else {
                return this._makeRequest({ method: 'get', url: url }, loading, true, entity);
            }
        }

        addItem<T>(entity: string, item: T, loading?: boolean): ng.IPromise<T> {
            var url = this.ApiUrl + entity;

            loading = (typeof loading === 'undefined') ? true : loading; // Default to show the loading indicator.

            return this._makeRequest({ method: 'post', url: url, data: item }, loading);
        }

        editItem<T>(entity: string, item: T, loading?: boolean): ng.IPromise<T> {
            var id = getId(item);
            var url = this.ApiUrl + entity + '/' + id;

            loading = (typeof loading === 'undefined') ? true : loading; // Default to show the loading indicator.

            return this._makeRequest({ method: 'put', url: url, data: item }, loading);
        }

        removeItem<T>(entity: string, item: T, loading?: boolean): ng.IPromise<T> {
            var id = getId(item);
            var url = this.ApiUrl + entity + '/' + id;

            loading = (typeof loading === 'undefined') ? true : loading; // Default to show the loading indicator.

            return this._makeRequest({ method: 'delete', url: url }, loading);
        }

        getPDFReport(url: string) {
            return this.getReport(url, 'application/pdf');
        }

        getExcelReport(url: string, filters?: App.Models.Filters) {
            return this.getReport(url, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', filters);
        }

        getReport(url: string, format: string, filters?: App.Models.Filters) {
            return this.$http<Blob>({
                url: url,
                method: "GET",
                responseType: 'arraybuffer',
                params: filters
            }).then((data) => {
                var blob = new Blob([data.data], { type: format });
                return blob;
            }, this._handleError);
        }

        get(endpoint: string, loading?: boolean) {
            var url = this.ApiUrl + endpoint;

            loading = (typeof loading === 'undefined') ? true : loading; // Default to show the loading indicator.

            return this._makeRequest({ method: 'get', url: url }, loading);
        }

        post(endpoint: string, data, loading?: boolean) {
            var url = this.ApiUrl + endpoint;

            loading = (typeof loading === 'undefined') ? true : loading; // Default to show the loading indicator.

            return this._makeRequest({ method: 'post', url: url, data: data }, loading);
        }

        private _handleError = (response: ng.IHttpPromiseCallbackArg<any>) => {
            var reason = {
                status: response.status,
                data: null
            };

            if (response && response.hasOwnProperty('data')) {
                reason.data = response.data;
                if (reason.data && reason.data.hasOwnProperty('errors')) {
                    reason.data = reason.data.errors;
                }
            } else {
                reason.data = response;
            }

            if (response.status == 0 && this.$rootScope.user) {
                // don't set user to null in order to keep current page up

                // keep up for 10 minutes
                this.toaster.pop({
                    body: 'Session Expired',
                    type: 'warning',
                    showCloseButton: true,
                    timeout: 600000,
                    clickHandler: this._sessionTimeoutToastHandler,                    
                });
            }
            /*
             * Never toast 422 errors (used for validation handling)
             * Never toast /users/current 400 & 401 errors (used for redirecting)
             * Never toast 0 status codes (ADFS redirect results in this status code)
             **/
            else if (response.status && !(/users\/current/.test(response.config.url) && (response.status === 400 || response.status === 401)) && response.status !== 422) {
                this.toaster.error(getErrorPopParams(response));
            }

            return (this.$q.reject(reason));
        }
        private _sessionTimeoutToastHandler(): boolean {
            // When toast is acknowledged go to ADFS login
            window.location.href = '/login';
            return true;
        }

        private _handleSuccess(response: ng.IHttpPromiseCallbackArg<{ data?: any }>, single?: boolean, entity?: string) {
            if (response.data !== null && response.data !== undefined) {
                if (response.data.data !== null && response.data.data !== undefined) {
                    var data = response.data.data;
                    if (single && angular.isArray(data) && data.length) {
                        return data[0];
                    }
                    return data;
                }

                if (entity && response.data[entity]) {
                    if (single) {
                        return response.data[entity][0];
                    } else {
                        return response.data[entity];
                    }
                }

                if (single && response.data[0]) {
                    return response.data[0]
                }

                return response.data;
            }
            return response;
        }

        private _makeRequest(config: ng.IRequestConfig, loading: boolean, single?: boolean, entity?: string) {
            var request;
            if (loading) {
                request = this.$rootScope['loadingData'] = this.$http(config);
            } else {
                request = this.$http(config);
            }

            return (request.then((data) => {
                return this._handleSuccess(data, single, entity);
            }, this._handleError));;
        }

        static ID = 'ApiService';
        static $inject = ['$http', 'CONFIG', '$rootScope', '$q', 'toaster'];
        constructor(private $http: ng.IHttpService, CONFIG, private $rootScope: ng.IRootScopeService, private $q: ng.IQService, private toaster: ng.toaster.IToasterService) {
            this.ApiUrl = CONFIG.API_URL;
        }
    }
}

angular.module('app').service('ApiService', App.Services.ApiService);
