module App.Directives {
    class VarietySelectController {
        varieties: Models.Variety[];
        variety: Models.Variety;
        varietyFilter: (item: Models.Variety) => boolean;
        ensure: Models.Variety;

        static $inject = ['LookupService', '$scope'];
        constructor(LookupService: App.Services.LookupService, $scope: ng.IScope) {
            // need all of them in case the currently selected one has become inactive
            // we'll filter it out later
            this.varieties = [];
            LookupService.getVarieties(false).then(varieties => {
                this.varieties = this.varietyFilter === undefined ? varieties : varieties.filter(this.varietyFilter);
                if (this.ensure && !_.find(this.varieties, { id: this.ensure.id })) {
                    this.varieties.unshift(this.ensure);
                }
                if (this.varieties.length === 1) {
                    this.variety = this.varieties[0];
                }
            });

        //            this.variety = null;

        }
    }

    VarietySelect.$inject = ['LookupService'];
    export function VarietySelect(LookupService: App.Services.LookupService) {

        let scope = {
            variety: '=ngModel',
            ngDisabled: '=',
            varietyFilter: '=',
            ensure: '='
        }

        let template = `
            <select
                ng-options="variety.name for variety in va.varieties track by variety.id"
                ng-model="va.variety"
                ng-disabled="va.ngDisabled">
                <option value="">-- Select One --</option>
            </select>
        `;

        let linkFn: ng.IDirectiveLinkFn = function (scope, element, attrs) {
            angular.element(element)
                .find('select')
                .attr('class', attrs['class'])
                .attr('name', attrs['name']);

            angular.element(element)
                .removeAttr('class')
                .removeAttr('name');
        }

        return {
            scope: scope,
            restrict: 'E',
            template: template,
            link: linkFn,
            controller: VarietySelectController,
            controllerAs: 'va',
            bindToController: true
        }
    }
}

angular.module('app').directive('varietySelect', App.Directives.VarietySelect);