angular.module('app').filter('customFilter', ['$filter', function ($filter) {
    function dateRange(predicate, field: string) {
        return function (row) {
            if (!predicate)
                return true;

            const before = predicate.before ? new Date(predicate.before) : new Date(Date.now() + 1000000);
            const after = predicate.after ? new Date(predicate.after) : new Date(-Math.pow(10, 15));

            return row[field] <= before && row[field] >= after;

        }
    }

    var filterFilter = $filter('filter');
    return function (array, expression) {
        var standard = angular.copy(expression);
        var keys: string[] = [];

        if (angular.isObject(expression)) {
            for (var k in expression) {
                if (angular.isObject(expression[k])) {
                    keys.push(k);
                    delete standard[k];
                }
            }
        }

        var filter = filterFilter(array, standard);

        if (keys.length) {
            for (var k of keys) {
                if (angular.isObject(expression[k]) && (expression[k].hasOwnProperty('before') || expression[k].hasOwnProperty('after'))) {
                    filter = filter.filter(dateRange(expression[k], k));
                }
            }
        }

        return filter;
    }
}]);