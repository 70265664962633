module App.Controllers {
    export class NetworksController {
        networks: Models.NetworkFlattened[];

        static $inject = ['networks', '$state'];
        constructor(networks: Models.NetworkFlattened[], private $state: ng.ui.IStateService) {
            this.networks = networks;
        }
    }

    NetworksControllerRoute.$inject = ['$stateProvider'];
    export function NetworksControllerRoute($stateProvider: angular.ui.IStateProvider) {
        $stateProvider.state('networks', {
            url: '/networks',
            abstract: true,
            template: '<ui-view/>'
        })
            .state('networks.index', {
            url: '',
            templateUrl: 'views/networks.html',
            controller: NetworksController,
            controllerAs: 'vm',
            title: 'Networks',
            resolve: {
                networks: ['NetworkService', (NetworkService: Services.NetworkService) => {
                    return NetworkService.getNetworks().then(networks => networks.map(n => n.flattened));
                }]
            }
        });
    }
}

angular.module('app').config(App.Controllers.NetworksControllerRoute);   