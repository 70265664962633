module App.Models {
    export class InventoryRow {
        date: Date;
        accountName: string;
        location: string;
        site: string;
        variety: string;
        reportNumber: string;
        lotNumber: string;
        inAdjustment: number;
        outAdjustment: number;
        adjustedInventory: number;
        unitOfMeasure: string;
        toFromAccount: string;
        lastSignedBy: string;
        isArchived: string;

        load(data?: {}) {
            if (data) {
                for (let prop in data) {
                    this[prop] = data[prop];
                }
            }
            return this;
        }
    }
}