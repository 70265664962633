module App.Controllers {
    export class PostHarvestRecordController extends BaseRecordController<Models.PostHarvestRecord> {
        model = Models.PostHarvestRecord;

        record: Models.PostHarvestRecord;

        editingTransaction: boolean;
        monitoringTransaction: Models.MonitoringTransaction;

        controls: {
            controlMethods: any[];    
            contacts: any[];        
        }

        addTransaction() {
            this.editingTransaction = true;
            this.monitoringTransaction = new Models.MonitoringTransaction();

            let currentUser = this.$rootScope.user;
            // if user can't see all accounts then set to current user
            // or, if only one, select it (by definition, it has to be the current user)
            if (!currentUser.can.viewAccountList || this.controls.contacts.length === 1) {
                this.monitoringTransaction.contactName = currentUser.username;
            }
        }

        disableAddTransaction(): boolean {
            return this.isInEditMode || this.editingTransaction || !this.record.record.canEdit ||
                !(this.record.record.siteId > 0) ||
                (this.record.monitoringTransactions && this.record.monitoringTransactions.length >= 20);
        }

        saveTransaction() {
            if (this.monitoringTransaction.id) {
                let myIdx = _.findIndex(this.record.monitoringTransactions, x => x.id === this.monitoringTransaction.id)
                if (myIdx >= 0) {
                    this.record.monitoringTransactions[myIdx] = angular.copy(this.monitoringTransaction);
                }
            }
            else {
                let mt = angular.copy(this.monitoringTransaction);
                this.record.monitoringTransactions.push(mt);
            }

            this.save();
            this.resetTransaction();
        }

        resetTransaction() {
            this.editingTransaction = false;
            // clear out fields
            this.clearTransaction();
        }

        editTransaction(transactionId: number) {
            if (!this.isInEditMode && !this.editingTransaction && this.record.record.canEdit && this.record.record.siteId > 0) {
                this.editingTransaction = true;
                let editThisOne = _.find(this.record.monitoringTransactions, { id: transactionId });
                this.monitoringTransaction = angular.copy(editThisOne);
                // Prevents date from being marked invalid
                this.monitoringTransaction.monitoringDate = new Date(this.monitoringTransaction.monitoringDate.toString());
            }
        }

        deleteTransaction(transactionId: number) {
            this.record.monitoringTransactions = this.record.monitoringTransactions.filter(t => { return t.id !== transactionId });
            this.save();
        }

        clearTransaction() {
            // clear out fields
            this.monitoringTransaction = null;
        }

        disableSaveTransaction(): boolean {
            return this.isInEditMode || !this.editingTransaction || !this.record.record.canEdit || !this.validateTransaction();
        }

        validateTransaction(): boolean {
            let valid = this.monitoringTransaction &&
                this.monitoringTransaction.contactName &&
                this.monitoringTransaction.monitoringDate &&
                this.monitoringTransaction.currentCropType && this.monitoringTransaction.currentCropType.length > 0 &&
                this.monitoringTransaction.anyVolunteerPlantsFound !== undefined &&
                (this.monitoringTransaction.anyVolunteerPlantsFound === false ||
                 (this.monitoringTransaction.anyVolunteerPlantsFound === true && this.monitoringTransaction.volunteerControlMethodId > 0)) &&
                this.monitoringTransaction.notes && this.monitoringTransaction.notes.length > 0;
            return valid;
        }

        // Only Fields are allowed
        siteFilter = (item: Deregulated.ISite) : boolean => {
            return item['type'] && item['type'].name === 'Field';
        }

        enterEditMode() {
            this.isInEditMode = true;
        }

        static $inject = [
            'record',
            'accounts',
            'controls',
            'AccountService',
            'UserService',
            'RecordService',
            '$state',
            '$scope',
            '$rootScope',
            '$modal',
            'toaster'
        ];
        constructor(
            record: Models.PostHarvestRecord,
            accounts: Models.Account[],
            controls,
            private AccountService: Services.AccountService,
            private UserService: Services.UserService,
            private RecordService: Services.RecordService,
            $state: ng.ui.IStateService,
            $scope: ng.IScope,
            private $rootScope: ng.IRootScopeService,
            $modal: ng.ui.bootstrap.IModalService,
            toaster: ng.toaster.IToasterService
        ) {
            super(record, accounts, controls, $state, $modal, toaster);

            /**
            * watch record.record.accountId for changes so we can update the available contacts
            */
            $scope.$watch(() => {
                return this.record.record.accountId;
            }, (newId, oldId) => {
                //if (parseInt(newId) !== parseInt(oldId)) {
                    if (newId) {
                        UserService.getUsers({ accountId: newId, forMonitoringTransaction: true }).then(users => {
                                this.controls.contacts = [];
                                users.forEach(item => {
                                    this.controls.contacts.push(item.username);
                                });
                            });
                    }
                    else {
                        this.controls.contacts = null;
                    }
                //}
            });

            /**
             * watch record.record.siteId for changes so we can update the volunteer monitoring dates
             * by looking for the most recent harvest and the site's volunteermonitoringmonths
             */
            $scope.$watch(() => {
                return this.record.record.siteId;
            }, (newId, oldId) => {
                if (parseInt(newId) !== parseInt(oldId)) {
                    if (newId !== null && newId !== undefined && newId !== '') {
                        RecordService.calculateVolunteerMonitoringDates(newId)
                            .then(volunteerDates => {
                                this.record.volunteerMonitoringStartDate =
                                    volunteerDates.startDate == null ? null : new Date(volunteerDates.startDate);
                                this.record.volunteerMonitoringEndDate =
                                    volunteerDates.endDate == null ? null : new Date(volunteerDates.endDate);
                                if (volunteerDates.startDate == null || volunteerDates.endDate == null) {
                                    toaster.warning("Not enough information to determine volunteer monitoring dates at this time");
                                }
                            });
                    }
                    else {
                        this.record.record.site = null;
                        this.record.volunteerMonitoringStartDate = null;
                        this.record.volunteerMonitoringEndDate = null;
                    }
                }
            });
        }

        /**
         * Resolve all promises before loading the controller
         * 
         * @Param isCreateMode  boolean
         */
        resolve(isCreateMode = false): { record: Function; accounts: Function; controls: Function; } {
            getRecord.$inject = ['$stateParams', 'RecordService'];
            function getRecord($stateParams: ng.ui.IStateParamsService, RecordService: Services.RecordService): Models.PostHarvestRecord | ng.IPromise<Models.PostHarvestRecord> {
                if (isCreateMode) {
                    var record = new Models.PostHarvestRecord().load({});
                    return record;
                } else {
                    return RecordService.getRecord<Models.PostHarvestRecord>($stateParams['id'], Models.PostHarvestRecord, 'postharvest');
                }
            }

            getAccounts.$inject = ['AccountService', '$rootScope'];
            function getAccounts(AccountService: Services.AccountService, $rootScope: ng.IRootScopeService): Models.Account[] | ng.IPromise<Models.Account[]> {
                if ($rootScope.user.can.viewAccountList) {
                    return AccountService.getAccounts();
                }
                else {
                    return [$rootScope.user.account];
                }
            }

            getControls.$inject = ['LookupService', '$q'];
            function getControls(LookupService: Services.LookupService, $q: ng.IQService) {
                return $q.all([LookupService.getControlMethods(), LookupService.getGenericLookup('SigningMessage', true)]).then((data) => {
                    return {
                        controlMethods: data[0],
                        signingMessage: data[1]
                    };
                });
            }

            return {
                record: getRecord,
                accounts: getAccounts,
                controls: getControls
            }
        }


    }
} 