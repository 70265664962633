module App.Models {
    export abstract class _BaseLookup implements Deregulated.IManagedLookup {
        id: number;
        name: string;
        description: string;
        active: boolean;
        lookupType: string;
        lookupUrl: string;

        lookupService: App.Services.LookupService;

        save(mute = false): ng.IPromise<Models._BaseLookup> {
            var saveLookup = this.id ?
                this.lookupService.edit(this, mute) :
                this.lookupService.add(this, mute);
            return saveLookup;
        }

        load(data: {}) {
            for (var prop in data) {
                if (data.hasOwnProperty(prop)) {
                    this[prop] = data[prop];
                }
            }
            return this;
        }

    }
}