module App.Models {
    export class ShipmentLineItem {
        id: number;
        lineItemNumber: number;
        varietyId: number;
        variety: Models.Variety;
        shipperLotNumber: string;
        actualQuantityShipped: number;
        estimatedQuantityShipped: number;
        quantityShippedUnitOfMeasurement: string = 'cwt';
        associatedReceiptLineItems: App.Models.ReceiptLineItem[];

        quantityShipped: number; // view model only
        quantityIsEstimated: boolean; // view model only


        /**
         * Getter for variety
         */
        get Variety() {
            return this.variety;
        }
        /**
         * Setter for variety
         */
        set Variety(v: Models.Variety) {
            this.variety = v || null;
            this.varietyId = v ? v.id : null;
        }

        load(data: {}) {
            if (data) {
                for (var prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        this[prop] = data[prop];
                    }
                }
            }

            // set actual and estimated from backend model
            if (this.estimatedQuantityShipped == null && this.actualQuantityShipped == null) {
                this.quantityIsEstimated = false;
                this.quantityShipped = null;
            }
            else if (this.actualQuantityShipped != null) {
                this.quantityIsEstimated = false;
                this.quantityShipped = this.actualQuantityShipped;
            }
            else {
                this.quantityIsEstimated = true;
                this.quantityShipped = this.estimatedQuantityShipped;
            }

            return this;
        }
    }

    export class ShipmentRecordFlattened extends BaseFlattened {
        shipToAccount: string;
        shipToLocation: string;
    }

    export class ShipmentRecord extends _BaseRecord {
        treatAsRecord: boolean;
        sites: Deregulated.IShipmentSite[];
        shipToAccountId: number;
        shipToAccount: Deregulated.IAccount;
        shipToLocationId: number;
        shipToLocation: Deregulated.ILocation;

        carrierName: string;
        sealTrackingNumbers: string;
        equipmentCleanedBeforeLoading: boolean;
        equipmentCleanedAfterLoading: boolean;
        anyWasteMaterials: boolean;
        wasteProperlyDisposed: boolean;
        wasteDisposalMethodId: number;
        wasteDisposalMethod: Deregulated.IDisposalMethod;
        wasteDisposalNotes: string;
        harvestRecordId: number;
        receiptRecords: Models.ReceiptRecord[];
        lineItems: Models.ShipmentLineItem[];
        nextLineItemNumber: number;

        shipmentDate: any; // just so we can blank it out during copy

        /**
         * Getter for shipToAccount
         */
        get ShipToAccount() {
            return this.shipToAccount;
        }
        /**
         * Setter for shipToAccount
         */
        set ShipToAccount(shipToAccount: Deregulated.IAccount) {
            this.shipToAccount = shipToAccount || null;
            this.shipToAccountId = shipToAccount ? shipToAccount.id : null;
        }

        /**
         * Getter for shipToLocation
         */
        get ShipToLocation() {
            return this.shipToLocation;
        }
        /**
         * Setter for shipToLocation
         */
        set ShipToLocation(shipToLocation: Deregulated.ILocation) {
            this.shipToLocation = shipToLocation || null;
            this.shipToLocationId = shipToLocation ? shipToLocation.id : null;
        }

        /**
         * Getter for WasteDisposalMethod
         */
        get WasteDisposalMethod() {
            return this.wasteDisposalMethod;
        }

        /**
         * Setter for WasteDisposalMethod
         */
        set WasteDisposalMethod(wasteDisposalMethod: Deregulated.IDisposalMethod) {
            this.wasteDisposalMethod = wasteDisposalMethod || null;
            this.wasteDisposalMethodId = wasteDisposalMethod ? wasteDisposalMethod.id : null;
        }

        generateReport() {
            var blobPromise = this.recordService.getRecordReport('shipment', this.id);
            blobPromise.then((blob) => {
                saveAs(blob, this.record.type.name + '-Report-' + this.number + '.pdf');
            });
        }

        /**
         * Overriding super's 'load' method so correct 'this' is returned.
         */
        load(data: {}) {
            super.load(data);
            if (data) {
                for (var prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        if (prop === 'lineItems' && data[prop].length) {
                            data[prop] = <any[]>data[prop].map((li: Models.ShipmentLineItem) => {
                                li = new Models.ShipmentLineItem().load(li);
                                return li;
                            });
                        }
                        this[prop] = data[prop];
                    }
                }
            }
            return this;
        }

        save(mute = false) {
            return super.save(mute);
        }

        constructor() {
            super();
            this.recordType = 'shipment';
        }
          
        get flattened(): ShipmentRecordFlattened {
            return {
                id: this.id,
                recordId: this.recordId,
                accountName: this.accountName,
                number: this.number,
                transactionDate: this.transactionDate,
                locationName: this.locationName,
                siteName: this.siteName,
                varietyName: this.varietyName,
                shipToAccount: this.shipToAccount ? this.shipToAccount.name : '',
                shipToLocation: this.shipToLocation ? this.shipToLocation.name : '',
                createdOn: this.record.createdOn,
                recordStatus: this.record.status.name,
                recordType: this.record.type.name
            }
        }
    }

}