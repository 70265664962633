module App.Controllers {
    export class ReceiptRecordController extends BaseRecordController<Models.ReceiptRecord> {
        model = Models.ReceiptRecord;

        record: Models.ReceiptRecord;
        controls: {
            disposalMethods: any[];
            availableShipmentRecords: Models.ShipmentRecord[];
        }

        disposalNotesLabel: string = 'Disposal Notes';

        editingLineItem: boolean;

        availableShipmentLineItems: Models.ShipmentLineItem[];

        clearWasteQuestions() {
            this.record.WasteDisposalMethod = null;
            this.record.wasteProperlyDisposed = null;
            this.record.wasteDisposalNotes = null;
        }

        generateReport() {
            this.record.generateReport();
        }

        // Start a new report from current one
        copyReport() {
            // Blank out what we don't want to carry over
            this.record.id = null;
            this.record.carrierName = null;
            this.record.sealTrackingNumbers = null;
            this.record.productSegregated = null;
            this.record.productProperlyIdentifiedLabeled = null;
            this.record.equipmentCleanedAfterUnloading = null;
            this.record.equipmentCleanedBeforeUnloading = null;
            this.record.wasteProperlyDisposed = null;
            this.record.wasteDisposalMethodId = null;
            this.record.wasteDisposalMethod = null;
            this.record.wasteDisposalNotes = null;
            this.record.receiptDate = null;

            this.record.lineItems = [];

            this.record.record.id = null;
            this.record.record.signingNotes = null;
            this.record.record.isArchived = false;
            this.record.record.lastArchivedOn = null;
            this.record.record.statusId = null;

            this.record.record.originallySignedOn = null;
            this.record.record.originallySignedBy = null;
            this.record.record.lastSignedOn = null;
            this.record.record.lastSignedBy = null;
            this.record.record.numberOfSignings = null;
            this.record.record.status = null;
            this.record.record.canUnlock = null;
            this.record.record.history = []; // serializer needs the array to exist

            this.record.number = null;

            // if only one, select it ("effectively" - actually, this just strategically blanks out fields)
            if (this.controls.availableShipmentRecords.length !== 1 || this.record.shipmentRecordId !== this.controls.availableShipmentRecords[0].id) {
                this.record.shipmentRecord = null;
                this.record.shipmentRecordId = null; // triggers other watch to clear From Account, From Location, From Variety, Lot Numbers
            }

            this.isInEditMode = true;

            // Change state without all the razzmatazz
            this.$state.go(('records.receipt.create'), {}, { notify: false });

            this.toaster.success('Copy Prepared');
        }

        static $inject = [
            'record',
            'accounts',
            'controls',
            'RecordService',
            '$state',
            '$scope',
            '$modal',
            'toaster',
            '$q'
        ];
        constructor(
            record: Models.ReceiptRecord,
            accounts: Models.Account[],
            controls,
            private RecordService: Services.RecordService,
            $state: ng.ui.IStateService,
            $scope: ng.IScope,
            $modal: ng.ui.bootstrap.IModalService,
            toaster: ng.toaster.IToasterService,
            private $q: ng.IQService
        ) {
            super(record, accounts, controls, $state, $modal, toaster);

            this.record.WasteDisposalMethod = <Deregulated.IDisposalMethod>_.findWhere(controls.disposalMethods, { id: record.wasteDisposalMethodId });

            /**
             * watch record.locationId for changes and update available shipment records
             */
            $scope.$watch(() => {
                return this.record.record.locationId;
            },(newLocationId, oldLocationId) => {
                if (newLocationId) {
                    this.RecordService.getRecords(Models.ShipmentRecord, 'shipment', { shipToLocationId: newLocationId, includeExistingForReceipt: this.record.id }).then(availableShipmentRecords => {
                        this.controls.availableShipmentRecords = availableShipmentRecords;
                        // if only one, select it
                        if (newLocationId != oldLocationId && this.controls.availableShipmentRecords.length === 1) {
                            this.record.shipmentRecord = this.controls.availableShipmentRecords[0];
                            this.record.shipmentRecordId = this.record.shipmentRecord.id; // triggers other watch
                        }
                    });
                } else {
                    this.controls.availableShipmentRecords = [];
                    this.record.shipmentRecordId = null; // should trigger other watch
                }
            });

            /**
             * watch record.shipmentRecordId for changes and do stuff.
             */
            $scope.$watch(() => {
                return this.record.shipmentRecordId;
            }, (newId, oldId) => {
                if (this.controls.availableShipmentRecords != null) { // happens on initial load
                    this.record.shipmentRecord = _.find(this.controls.availableShipmentRecords, { id: parseInt(newId) });

                    if (parseInt(newId) !== parseInt(oldId) /*????  && oldId*/) {
                        if (newId !== null) {
                            // Set the other values
                            this.record.receivedFromAccount = this.record.shipmentRecord.record.account;
                            this.record.receivedFromAccountId = this.record.shipmentRecord.record.accountId;
                            this.record.receivedFromLocation = this.record.shipmentRecord.record.location;
                            this.record.receivedFromLocationId = this.record.shipmentRecord.record.locationId;
                        }
                        else {
                            this.record.receivedFromAccountId = null;
                            this.record.receivedFromAccount = null;
                            this.record.receivedFromLocationId = null;
                            this.record.receivedFromLocation = null;
                        }
                    }
                }
            });

            $scope.$watch(() => {
                return this.record.WasteDisposalMethod;
            }, (newValue: Deregulated.IDisposalMethod, oldValue) => {
                this.disposalNotesLabel = BaseRecordController.getDisposalNotesLabel(newValue);
            });
        }

        /**
         * Resolve all promises before loading the controller
         * 
         * @Param isCreateMode  boolean
         */
        resolve(isCreateMode = false): { record: Function; accounts: Function; controls: Function; } {
            getRecord.$inject = ['$stateParams', 'RecordService'];
            function getRecord($stateParams: ng.ui.IStateParamsService, RecordService: Services.RecordService): Models.ReceiptRecord | ng.IPromise<Models.ReceiptRecord> {
                if (isCreateMode) {
                    var record = new Models.ReceiptRecord().load({});
                    return record;
                } else {
                    return RecordService.getRecord<Models.ReceiptRecord>($stateParams['id'], Models.ReceiptRecord, 'receipt');
                }
            }

            getAccounts.$inject = ['AccountService', '$rootScope'];
            function getAccounts(AccountService: Services.AccountService, $rootScope: ng.IRootScopeService): Models.Account[] | ng.IPromise<Models.Account[]> {
                if ($rootScope.user.can.viewAccountList) {
                    return AccountService.getAccounts();
                }
                else {
                    return [$rootScope.user.account];
                }
            }

            getControls.$inject = ['LookupService', '$q'];
            function getControls(LookupService: Services.LookupService, $q: ng.IQService) {
                return $q.all([LookupService.getDisposalMethods('Receipt'), LookupService.getGenericLookup('SigningMessage', true)]).then((data) => {
                    return {
                        disposalMethods: data[0],
                        signingMessage: data[1]
                    };
                });
            }

            return {
                record: getRecord,
                accounts: getAccounts,
                controls: getControls
            }
        }

        // allowed unless editing a destination
        toggleLineItem(lineItem: Models.ReceiptLineItem) {
            this._setAvailableShipmentLineItems();

            return !this.editingLineItem;
        }

        private _setAvailableShipmentLineItems() {
            this.availableShipmentLineItems = [];
            if (this.record.shipmentRecord && this.record.shipmentRecord.lineItems) {
                for (var x of this.record.shipmentRecord.lineItems) {
                    this.availableShipmentLineItems.push(x);
                }
            }
        }

        public addLineItem() {
            this.editingLineItem = true;

            let lineItem = new Models.ReceiptLineItem();
            lineItem["currentlyEditing"] = true;
            lineItem.lineItemNumber = this.record.nextLineItemNumber;

            this.record.lineItems.push(lineItem);

            this._setAvailableShipmentLineItems();

            // if only one, select it
            if (this.record.shipmentRecord != null && this.record.shipmentRecord.lineItems != null &&
                this.record.shipmentRecord.lineItems.length === 1) {
                lineItem.ShipmentLineItem = this.record.shipmentRecord.lineItems[0];
            }
        }

        get lineItems(): Models.ReceiptLineItem[] { return this.record.lineItems; }

        private _prepareForAddEdit() {
            this.editingLineItem = true;
            this.lineItems.forEach(li => { li['currentlyEditing'] = false; });
        }

        resetRecord() {
            let pristineRecord = angular.copy(this.record.copy);
            this.record.load(pristineRecord);
            this.editingLineItem = false;
            this.errors = null;
        }

        saveLineItem(li: Models.ShipmentLineItem) {
            // set actual and estimated for backend model
            if (li.quantityIsEstimated) {
                li.actualQuantityShipped = null;
                li.estimatedQuantityShipped = li.quantityShipped;
            }
            else {
                li.estimatedQuantityShipped = null;
                li.actualQuantityShipped = li.quantityShipped;
            }

            return this.record.save(true).then(() => {
                this.editingLineItem = false;
                this.toaster.success('Line Item Saved');
                this.errors = null;
            }, (errors) => {
                if (errors.length) {
                    this.errors = errors;
                }
                this.toaster.error('Line Item Save Failed');
                return this.$q.reject(errors);
            });
        }

        editLineItem(lineItem: { id: number, currentlyEditing: boolean }) {
            this._prepareForAddEdit();
            lineItem.currentlyEditing = true;
        }

        deleteLineItem(lineItem: Models.ReceiptLineItem) {
            // note that _.findIndex doesn't like the intersect type
            for (var idx = 0; idx < this.lineItems.length; idx++) {
                if (this.lineItems[idx] === lineItem) {
                    break;
                }
            }

            this.lineItems.splice(idx, 1);
            this.save();
        }

        canAddLineItem(): boolean {
            if (this.isInEditMode) {
                return false;
            } else if (this.editingLineItem) {
                return false;
            } else if (!this.record.record || !this.record.record.canEdit) {
                return false;
            } else {
                return true;
            }
        }

        canEditLineItem(lineItem: { currentlyEditing: boolean }) {
            if (!this.record.record.canEdit) {
                return false;
            }
            if (this.isInEditMode) {
                return false;
            }
            if (this.editingLineItem) {
                return false;
            }
            if (lineItem.currentlyEditing) {
                return false;
            }
            return true;
        }

        getQuantity(lineItem: Models.ReceiptLineItem): string {
            let quantity = '';
            if (lineItem.actualQuantityReceived) {
                quantity = (lineItem.actualQuantityReceived + '') + ' ' + lineItem.quantityReceivedUnitOfMeasurement;
            }
            return quantity;
        }
    }
}