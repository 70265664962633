module App.Controllers {
    export class StorageRecordController extends BaseRecordController<Models.StorageRecord> {
        model = Models.StorageRecord;

        record: Models.StorageRecord;
        controls: {
            disposalMethods: any[];
        }

        editingLineItem: boolean;

        static $inject = [
            'record',
            'accounts',
            'controls',
            'AccountService',
            'RecordService',
            '$state',
            '$scope',
            '$modal',
            'toaster',
            '$q'
        ];
        constructor(
            record: Models.StorageRecord,
            accounts: Models.Account[],
            controls,
            private AccountService: Services.AccountService,
            private RecordService: Services.RecordService,
            $state: ng.ui.IStateService,
            $scope: ng.IScope,
            $modal: ng.ui.bootstrap.IModalService,
            toaster: ng.toaster.IToasterService,
            private $q: ng.IQService
        ) {
            super(record, accounts, controls, $state, $modal, toaster);
        }

        /**
         * Resolve all promises before loading the controller
         * 
         * @Param isCreateMode  boolean
         */
        resolve(isCreateMode = false): { record: Function; accounts: Function; controls: Function; } {
            getRecord.$inject = ['$stateParams', 'RecordService'];
            function getRecord($stateParams: ng.ui.IStateParamsService, RecordService: Services.RecordService): Models.StorageRecord | ng.IPromise<Models.StorageRecord> {
                if (isCreateMode) {
                    var record = new Models.StorageRecord().load({});
                    record.treatAsRecord = true;
                    return record;
                } else {
                    return RecordService.getRecord<Models.StorageRecord>($stateParams['id'], Models.StorageRecord, 'storage');
                }
            }

            getAccounts.$inject = ['AccountService', '$rootScope'];
            function getAccounts(AccountService: Services.AccountService, $rootScope: ng.IRootScopeService): Models.Account[] | ng.IPromise<Models.Account[]> {
                if ($rootScope.user.can.viewAccountList) {
                    return AccountService.getAccounts();
                }
                else {
                    return [$rootScope.user.account];
                }
            }

            getControls.$inject = ['LookupService', '$q'];
            function getControls(LookupService: Services.LookupService, $q: ng.IQService) {
                return $q.all([LookupService.getDisposalMethods('Storage'), LookupService.getGenericLookup('SigningMessage', true)]).then((data) => {
                    return {
                        disposalMethods: data[0],
                        signingMessage: data[1]
                    };
                });
            }

            return {
                record: getRecord,
                accounts: getAccounts,
                controls: getControls
            }
        }

        // Only one estimated quantity can have a value, so check the others
        // Found this to be the best consistent test for editing new and existing storage records
        shouldDisableQuantity(otherQuantity1: any, otherQuantity2: any): boolean {
            if (otherQuantity1 !== undefined && otherQuantity1 !== null && otherQuantity1.toString().length > 0) return true;
            if (otherQuantity2 !== undefined && otherQuantity2 !== null && otherQuantity2.toString().length > 0) return true;
            return false;
        }

        // allowed unless editing a destination
        toggleLineItem(lineItem: Models.StorageLineItem) {
            return !this.editingLineItem;
        }

        public addLineItem() {
            this.editingLineItem = true;

            let lineItem = new Models.StorageLineItem();
            lineItem["currentlyEditing"] = true;

            this.record.lineItems.push(lineItem);
        }

        get lineItems(): Models.StorageLineItem[] { return this.record.lineItems; }

        private _prepareForAddEdit() {
            this.editingLineItem = true;
            this.lineItems.forEach(li => { li['currentlyEditing'] = false; });
        }

        resetRecord() {
            let pristineRecord = angular.copy(this.record.copy);
            this.record.load(pristineRecord);
            this.editingLineItem = false;
            this.errors = null;
        }

        saveLineItem() {
            return this.record.save(true).then(() => {
                this.editingLineItem = false;
                this.toaster.success('Line Item Saved');
                this.errors = null;
            }, (errors) => {
                if (errors.length) {
                    this.errors = errors;
                }
                this.toaster.error('Line Item Save Failed');
                return this.$q.reject(errors);
            });
        }

        editLineItem(lineItem: { id: number, currentlyEditing: boolean }) {
            this._prepareForAddEdit();
            lineItem.currentlyEditing = true;
        }

        deleteLineItem(lineItem: Models.StorageLineItem) {
            // note that _.findIndex doesn't like the intersect type
            for (var idx = 0; idx < this.lineItems.length; idx++) {
                if (this.lineItems[idx] === lineItem) {
                    break;
                }
            }

            this.lineItems.splice(idx, 1);
            this.save();
        }

        canAddLineItem(): boolean {
            if (this.isInEditMode) {
                return false;
            } else if (this.editingLineItem) {
                return false;
            } else if (!this.record.record || !this.record.record.canEdit) {
                return false;
            } else {
                return true;
            }
        }

        canEditLineItem(lineItem: { currentlyEditing: boolean }) {
            if (!this.record.record.canEdit) {
                return false;
            }
            if (this.isInEditMode) {
                return false;
            }
            if (this.editingLineItem) {
                return false;
            }
            if (lineItem.currentlyEditing) {
                return false;
            }
            return true;
        }

        varietyFilter(item: Models.Variety): boolean {
            return item.active;
        }
    }
}