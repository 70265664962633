module App.Controllers {
    export abstract class BaseRecordController<T extends Models._BaseRecord> {
        model: { new (): T };

        record: Models._BaseRecord;
        accounts: Models.Account[];

        isInEditMode: boolean = false;
        errors: string[];
        errorReason: string;
        controls: any;

        save(mute = false) {
            return this.record.save<Models._BaseRecord>(mute, false).then((record) => {
                this.isInEditMode = false;
                // always reload so the calendar controls are reset properly
                this.$state.go(('records.' + [this.record.recordType] + '.record'), { id: record.id }, { reload: true });
                this.errors = null;
            }, (errors) => {
                if (errors.length) {
                    this.errors = errors;
                }
            });
        }

        cancel() {
            if (this.$state.current.name.indexOf('create') >= 0) {
                this.$state.go('records.' + [this.record.recordType] + '.index');
            } else {
                // always reload so the calendar controls are reset properly
                this.$state.go(('records.' + [this.record.recordType] + '.record'), { id: this.record.id }, { reload: true });
                //this.errors = null;
                //this.record = new this.model().load(this.record.copy);
            }
            this.isInEditMode = false;
        }

        signAndLock(signingMessage: string) {
            if (this.record.record.canSign) {
                var modalConfig = SignRecord.getControllerConfig(this.record.record.signingNotes, signingMessage);
                var modal = this.$modal.open(modalConfig);
                modal.result.then((signingNotes) => {
                    this.record.record.signingNotes = signingNotes;
                    this.record.signAndLock().then((record) => {
                        this.record.load(record);
                        this.errors = null;
                        this.isInEditMode = false;
                    }, (errors) => {
                        if (errors.data.errors.length) {
                            this.toaster.warning('Signing failed due to validation errors');
                            this.errors = errors.data.errors;
                            this.errorReason = 'Sign & Lock';
                        }
                    });
                }, reason => {
                    // dismissed
                });
            }
        }

        unlock() {
            if (this.record.record.canUnlock) {
                this.record.unlock().then((record) => {
                    this.record.load(record);
                },(errors) => {
                    if (errors.data.errors.length) {
                        this.errors = errors.data.errors;
                        console.log(this.errors);
                        this.errorReason = 'Unlock';
                    }
                });
            }
        }

        remove(mute = false) {
            return this.record.remove<Models._BaseRecord>(mute).then((record) => {
                this.$state.go('records.' + [this.record.recordType] + '.index');
                this.errors = null;
            }, (errors) => {
                if (errors.length) {
                    this.errorReason = 'Delete';
                    this.errors = errors;
                }
            });

        }

        varietyFilter(item: Models.Variety): boolean {
            // TODO - would be better to include this.record.record.varietyID right here, but can't see it when this is called
            return item.active;
        }

        static getDisposalNotesLabel(disposalMethod: Deregulated.IDisposalMethod) {
            if (disposalMethod == null)
                return "Disposal Notes";
            else if (disposalMethod.name === 'Other')
                return "Disposal Notes*";
            else if (disposalMethod.name === 'Livestock Feed')
                return 'Name of Cattle Feeder/Coordinates*';
            else
                return "Disposal Notes";
        }

        constructor(
            record,
            accounts,
            controls,
            protected $state: ng.ui.IStateService,
            protected $modal: ng.ui.bootstrap.IModalService,
            protected toaster: ng.toaster.IToasterService
        ) {
            this.record = record;
            this.accounts = accounts;
            this.controls = controls;

            // If we dont have a Record ID and the account list length is 1, set the records account to our 1 account
            if (this.accounts.length === 1 && !this.record.id) {
                this.record.record.Account = this.accounts[0];
            }

            if ($state.current.name.indexOf('create') >= 0) {
                this.isInEditMode = true;
            }
        }
    }

    export class recordControllerRoute {
        static $inject = ['$stateProvider'];

        constructor($stateProvider: angular.ui.IStateProvider) {
            $stateProvider
                    .state('records.planting.create', {
                        url: '/add',
                        templateUrl: 'views/record-planting.html',
                        controller: PlantingRecordController,
                        controllerAs: 'vm',
                        title: 'Create Crop Production Report – Planting',
                        resolve: PlantingRecordController.prototype.resolve(true)
                    })
                    .state('records.lateblightipm.create', {
                        url: '/add/:plantingId',
                        templateUrl: 'views/record-lateblightipm.html',
                        controller: LateBlightIPMRecordController,
                        controllerAs: 'vm',
                        title: 'Create Late Blight IPM Report',
                        resolve: LateBlightIPMRecordController.prototype.resolve(true)
                    })
                    .state('records.harvest.create', {
                        url: '/add',
                        templateUrl: 'views/record-harvest.html',
                        controller: HarvestRecordController,
                        controllerAs: 'vm',
                        title: 'Create Crop Production Report – Harvest',
                        resolve: HarvestRecordController.prototype.resolve(true)
                    })
                    .state('records.shipment.create', {
                        url: '/add',
                        templateUrl: 'views/record-shipment.html',
                        controller: ShipmentRecordController,
                        controllerAs: 'vm',
                        title: 'Create Crop Movement Report – Shipment',
                        resolve: ShipmentRecordController.prototype.resolve(true)
                    })
                    .state('records.receipt.create', {
                        url: '/add',
                        templateUrl: 'views/record-receipt.html',
                        controller: ReceiptRecordController,
                        controllerAs: 'vm',
                        title: 'Create Crop Movement Report – Receipt',
                        resolve: ReceiptRecordController.prototype.resolve(true)
                    })
                    .state('records.storage.create', {
                        url: '/add',
                        templateUrl: 'views/record-storage.html',
                        controller: StorageRecordController,
                        controllerAs: 'vm',
                        title: 'Create Storage/Inventory Adjustment Report',
                        resolve: StorageRecordController.prototype.resolve(true)
                    })
                    .state('records.processing.create', {
                        url: '/add',
                        templateUrl: 'views/record-processing.html',
                        controller: ProcessingRecordController,
                        controllerAs: 'vm',
                        title: 'Create Processing Report',
                        resolve: ProcessingRecordController.prototype.resolve(true)
                    })
                    .state('records.postharvest.create', {
                        url: '/add',
                        templateUrl: 'views/record-postharvest.html',
                        controller: PostHarvestRecordController,
                        controllerAs: 'vm',
                        title: 'Create Post Harvest Report',
                        resolve: PostHarvestRecordController.prototype.resolve(true)
                    })
                    .state('records.seedplanting.create', {
                        url: '/add',
                        templateUrl: 'views/record-seedplanting.html',
                        controller: SeedPlantingRecordController,
                        controllerAs: 'vm',
                        title: 'Create Seed Crop Planting Report',
                        resolve: SeedPlantingRecordController.prototype.resolve(true)
                    })
                    .state('records.seedharvest.create', {
                        url: '/add',
                        templateUrl: 'views/record-seedharvest.html',
                        controller: SeedHarvestRecordController,
                        controllerAs: 'vm',
                        title: 'Create Seed Crop Harvest Report',
                        resolve: SeedHarvestRecordController.prototype.resolve(true)
                    })
                    .state('records.planting.record', {
                        url: '/:id',
                        templateUrl: 'views/record-planting.html',
                        controller: PlantingRecordController,
                        controllerAs: 'vm',
                        title: 'Crop Production Report – Planting',
                        resolve: PlantingRecordController.prototype.resolve()
                    })
                    .state('records.lateblightipm.record', {
                        url: '/:id',
                        templateUrl: 'views/record-lateblightipm.html',
                        controller: LateBlightIPMRecordController,
                        controllerAs: 'vm',
                        title: 'Late Blight IPM Report',
                        resolve: LateBlightIPMRecordController.prototype.resolve()
                    })
                    .state('records.harvest.record', {
                        url: '/:id',
                        templateUrl: 'views/record-harvest.html',
                        controller: HarvestRecordController,
                        controllerAs: 'vm',
                        title: 'Crop Production Report – Harvest',
                        resolve: HarvestRecordController.prototype.resolve()
                    })
                    .state('records.shipment.record', {
                        url: '/:id',
                        templateUrl: 'views/record-shipment.html',
                        controller: ShipmentRecordController,
                        controllerAs: 'vm',
                        title: 'Crop Movement Report – Shipment',
                        resolve: ShipmentRecordController.prototype.resolve()
                    })
                    .state('records.receipt.record', {
                        url: '/:id',
                        templateUrl: 'views/record-receipt.html',
                        controller: ReceiptRecordController,
                        controllerAs: 'vm',
                        title: 'Crop Movement Report – Receipt',
                        resolve: ReceiptRecordController.prototype.resolve()
                    })
                    .state('records.storage.record', {
                        url: '/:id',
                        templateUrl: 'views/record-storage.html',
                        controller: StorageRecordController,
                        controllerAs: 'vm',
                        title: 'Storage/Inventory Adjustment Report',
                        resolve: StorageRecordController.prototype.resolve()
                    })
                    .state('records.processing.record', {
                        url: '/:id',
                        templateUrl: 'views/record-processing.html',
                        controller: ProcessingRecordController,
                        controllerAs: 'vm',
                        title: 'Processing Report',
                        resolve: ProcessingRecordController.prototype.resolve()
                    })
                    .state('records.postharvest.record', {
                        url: '/:id',
                        templateUrl: 'views/record-postharvest.html',
                        controller: PostHarvestRecordController,
                        controllerAs: 'vm',
                        title: 'Post Harvest Report',
                        resolve: PostHarvestRecordController.prototype.resolve()
                    })
                    .state('records.seedplanting.record', {
                        url: '/:id',
                        templateUrl: 'views/record-seedplanting.html',
                        controller: SeedPlantingRecordController,
                        controllerAs: 'vm',
                        title: 'Seed Crop Planting Report',
                        resolve: SeedPlantingRecordController.prototype.resolve()
                    })
                    .state('records.seedharvest.record', {
                        url: '/:id',
                        templateUrl: 'views/record-seedharvest.html',
                        controller: SeedHarvestRecordController,
                        controllerAs: 'vm',
                        title: 'Seed Crop Harvest Report',
                        resolve: SeedHarvestRecordController.prototype.resolve()
                    })

                ;
        }
    }
}

angular.module('app').config(App.Controllers.recordControllerRoute);
