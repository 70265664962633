module App.Models {
    export class MonitoringTransaction {
        id: number;
        monitoringDate: Date;
        contactName: string;
        currentCropType: string;
        anyVolunteerPlantsFound: boolean;
        volunteerControlMethodId: number;
        volunteerControlMethod: Deregulated.IControlMethod;
        notes: string;
    }

    export class PostHarvestRecord extends _BaseRecord {
        monitoringTransactions: Models.MonitoringTransaction[];

        volunteerMonitoringStartDate: Date;
        volunteerMonitoringEndDate: Date;

        /**
         * Overriding super's 'load' method so correct 'this' is returned.
         */
        load(data: {}) {
            super.load(data);
            return this;
        }

        constructor() {
            super();
            this.recordType = 'postharvest';
        }
    }
} 