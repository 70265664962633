module App.Models {
    export class StorageLineItem {
        id: number;
        storageDate: Date;
        varietyId: number;
        variety: Models.Variety;
        lotNumber: string;
        estimatedQuantityIn: number;
        estimatedQuantityOut: number;
        estimatedQuantityCurrent: number;
        quantityUnitOfMeasurement: string = 'cwt';
        adjustmentReasonNotes: string;

        /**
         * Getter for variety
         */
        get Variety() {
            return this.variety;
        }
        /**
         * Setter for variety
         */
        set Variety(v: Models.Variety) {
            this.variety = v || null;
            this.varietyId = v ? v.id : null;
        }

        load(data: {}) {
            if (data) {
                for (var prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        this[prop] = data[prop];
                    }
                }
            }
            return this;
        }
    }

    export class StorageRecord extends Models._BaseRecord{
        treatAsRecord: boolean;
        id: number;
        harvestRecordId: number;
        record: Record;
        destinationNotes: string;

        storageAreasCleanedFullyPriorToStorage: boolean;
        segregationMaintained: boolean;
        innateStorageAreasClearlyIdentified: boolean;
        storageLocationsSecure: boolean;

        lineItems: Models.StorageLineItem[];

        load(data: {}) {
            super.load(data);
            if (data) {
                for (var prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        if (prop === 'lineItems' && data[prop].length) {
                            data[prop] = <any[]>data[prop].map((li: Models.StorageLineItem) => {
                                li = new Models.StorageLineItem().load(li);
                                return li;
                            });
                        }
                        this[prop] = data[prop];
                    }
                }
            }
            return this;
        }

        constructor() {
            super();
            this.recordType = 'storage';
        }
    }

}