module App.Models {
    export class ProcessingLineItem {
        id: number;
        varietyId: number;
        variety: Models.Variety;
        processorLotNumber: string;
        quantityProcessed: number;
        quantityUnitOfMeasurement: string = 'pounds';

        /**
         * Getter for variety
         */
        get Variety() {
            return this.variety;
        }
        /**
         * Setter for variety
         */
        set Variety(v: Models.Variety) {
            this.variety = v || null;
            this.varietyId = v ? v.id : null;
        }

        load(data: {}) {
            if (data) {
                for (var prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        this[prop] = data[prop];
                    }
                }
            }
            return this;
        }

    }

    export class ProcessingRecord extends Models._BaseRecord {
        id: number;
        record: Record;

        equipmentCleanedBeforeProcessing: boolean;
        equipmentCleanedAfterProcessing: boolean;
        verificationOfCleaningAfterProcessing: boolean;
        verificationUserId: number;
        verificationUser: Models.User;
        verificationDate: Date;

        startDate: Date;
        endDate: Date;

        lineItems: Models.ProcessingLineItem[];

        verify() {
            return this.recordService.verifyCleaningAfterProcessing(this);
        }

        load(data: {}) {
            super.load(data);
            if (data) {
                for (var prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        if (prop === 'lineItems' && data[prop].length) {
                            data[prop] = <any[]>data[prop].map((li: Models.ProcessingLineItem) => {
                                li = new Models.ProcessingLineItem().load(li);
                                return li;
                            });
                        }
                        this[prop] = data[prop];
                    }
                }
            }
            return this;
        }

        constructor() {
            super();
            this.recordType = 'processing';
        }
    }
}