module App.Models {
    export class ContactInformation implements Deregulated.IContactInformation {
        email: string;
        phone: string;
        phone2: string;
        fax: string;
        address1: string;
        address2: string;
        city: string;
        stateProvinceId: number;
        stateProvince: Deregulated.IStateProvince;
        postalCode: string;
        countyId: number;
        county: Deregulated.ICounty;
        countryId: number;
        country: Deregulated.ICountry;

        /**
         * Getter for county
         */
        get County() {
            return this.county;
        }
        /**
         * Setter for county
         */
        set County(county: Deregulated.ICounty) {
            this.county = county || null;
            this.countyId = county ? county.id : null;
        }

        constructor(data?: {}) {
            if (data) {
                for (var prop in data) {
                    if (data.hasOwnProperty(prop)) {
                        this[prop] = data[prop];
                    }
                }
            }
        }
    }
}