module App.Controllers {
    export class ShipmentRecordController extends BaseRecordController<Models.ShipmentRecord> {
        model = Models.ShipmentRecord;

        record: Models.ShipmentRecord;
        controls: {
            disposalMethods: any[];
            transferDestinationAccounts: Models.Account[]
        }
        disposalNotesLabel: string = 'Disposal Notes';

        editingLineItem: boolean;

        clearWasteQuestions() {
            this.record.WasteDisposalMethod = null;
            this.record.wasteProperlyDisposed = null;
            this.record.wasteDisposalNotes = null;
        }

        generateReport() {
            this.record.generateReport();
        }

        openAvailableSitesModal(): void {
            var modal = this.$modal.open(AvailableSites.getControllerConfig(this.record.sites, angular.copy(this.record.record.Location.sites)));

            modal.result.then(sites => this.record.sites = sites, reason => {
                // Dismissed.
            });
        }

        // Start a new report from current one
        copyReport() {
            // Blank out what we don't want to carry over
            this.record.id = null;
            this.record.carrierName = null;
            this.record.sealTrackingNumbers = null;
            this.record.equipmentCleanedAfterLoading = null;
            this.record.equipmentCleanedBeforeLoading = null;
            this.record.wasteProperlyDisposed = null;
            this.record.wasteDisposalMethodId = null;
            this.record.wasteDisposalMethod = null;
            this.record.wasteDisposalNotes = null;
            this.record.receiptRecords = [];
            this.record.shipmentDate = null;

            this.record.lineItems = [];

            this.record.record.id = null;
            this.record.record.signingNotes = null;
            this.record.record.isArchived = false;
            this.record.record.lastArchivedOn = null;
            this.record.record.statusId = null;

            this.record.number = null;

            this.record.record.originallySignedOn = null;
            this.record.record.originallySignedBy = null;
            this.record.record.lastSignedOn = null;
            this.record.record.lastSignedBy = null;
            this.record.record.numberOfSignings = null;
            this.record.record.status = null;
            this.record.record.canUnlock = null;
            this.record.record.history = []; // serializer needs the array to exist

            this.isInEditMode = true;

            // Change state without all the razzmatazz
            this.$state.go(('records.shipment.create'), { }, { notify: false });

            this.toaster.success('Copy Prepared');
        }

        static $inject = [
            'record',
            'accounts',
            'controls',
            'AccountService',
            '$state',
            '$scope',
            '$modal',
            '$window',
            'toaster',
            '$q'
        ];
        constructor(
            record: Models.ShipmentRecord,
            accounts: Models.Account[],
            controls,
            private AccountService: Services.AccountService,
            protected $state: ng.ui.IStateService,
            $scope: ng.IScope,
            $modal: ng.ui.bootstrap.IModalService,
            private $window: ng.IWindowService,
            toaster: ng.toaster.IToasterService,
            private $q: ng.IQService
        ) {
            super(record, accounts, controls, $state, $modal, toaster);

            this.record.WasteDisposalMethod = <Deregulated.IDisposalMethod>_.findWhere(controls.disposalMethods, { id: record.wasteDisposalMethodId });

            $scope.$watch(() => { return this.record.record.accountId },(newAccountId, oldAccountId) => {
                if (!newAccountId) {
                    this.record.ShipToAccount = null;
                    this.controls.transferDestinationAccounts = [];
                    return;
                };

                if (parseInt(newAccountId) !== parseInt(oldAccountId) && oldAccountId) {
                    this.record.ShipToAccount = null;
                }

                this.AccountService.getAccounts({ 'forTransferDestination': true, 'baseAccountId': newAccountId, 'includeBase': true }).then((accounts) => {
                    if (this.record.ShipToAccount && !_.find(accounts, { id: this.record.ShipToAccount.id })) {
                        accounts.unshift(<Models.Account>this.record.ShipToAccount);
                    }
                    this.controls.transferDestinationAccounts = accounts;

                    // if only one, select it
                    if (newAccountId !== oldAccountId && this.controls.transferDestinationAccounts.length === 1) {
                        this.record.ShipToAccount = this.controls.transferDestinationAccounts[0];
                    }
                });
            });

            $scope.$watch(() => { return this.record.record.locationId },(newLocationId, oldLocationId) => {
                if (!newLocationId || (parseInt(newLocationId) !== parseInt(oldLocationId) && oldLocationId)) {
                    this.record.sites = [];
                } 
            });

            $scope.$watch(() => {
                return this.record.WasteDisposalMethod;
            }, (newValue: Deregulated.IDisposalMethod, oldValue) => {
                this.disposalNotesLabel = BaseRecordController.getDisposalNotesLabel(newValue);
            });
        }

        /**
         * Resolve all promises before loading the controller
         * 
         * @Param isCreateMode  boolean
         */
        resolve(isCreateMode = false): { record: Function; accounts: Function; controls: Function; } {
            getRecord.$inject = ['$stateParams', 'RecordService'];
            function getRecord($stateParams: ng.ui.IStateParamsService, RecordService: Services.RecordService): Models.ShipmentRecord | ng.IPromise<Models.ShipmentRecord> {
                if (isCreateMode) {
                    var record = new Models.ShipmentRecord().load({});
                    record.treatAsRecord = true;
                    return record;
                } else {
                    return RecordService.getRecord<Models.ShipmentRecord>($stateParams['id'], Models.ShipmentRecord, 'shipment');
                }
            }

            getAccounts.$inject = ['AccountService', '$rootScope'];
            function getAccounts(AccountService: Services.AccountService, $rootScope: ng.IRootScopeService): Models.Account[] | ng.IPromise<Models.Account[]> {
                if ($rootScope.user.can.viewAccountList) {
                    return AccountService.getAccounts();
                }
                else {
                    return [$rootScope.user.account];
                }
            }

            getControls.$inject = ['LookupService', '$q'];
            function getControls(LookupService: Services.LookupService, $q: ng.IQService) {
                return $q.all([LookupService.getDisposalMethods(), LookupService.getGenericLookup('SigningMessage', true)]).then((data) => {
                    return {
                        disposalMethods: data[0],
                        signingMessage: data[1]
                    };
                });
            }

            return {
                record: getRecord,
                accounts: getAccounts,
                controls: getControls
            }
        }

        // allowed unless editing a destination
        toggleLineItem(lineItem: Models.ShipmentLineItem) {
            return !this.editingLineItem;
        }

        public addLineItem() {
            this.editingLineItem = true;

            let lineItem = new Models.ShipmentLineItem();
            lineItem["currentlyEditing"] = true;
            lineItem.lineItemNumber = this.record.nextLineItemNumber;

            this.record.lineItems.push(lineItem);
        }

        get lineItems(): Models.ShipmentLineItem[] { return this.record.lineItems; }

        private _prepareForAddEdit() {
            this.editingLineItem = true;
            this.lineItems.forEach(li => { li['currentlyEditing'] = false; });
        }

        resetRecord() {
            let pristineRecord = angular.copy(this.record.copy);
            this.record.load(pristineRecord);
            this.editingLineItem = false;
            this.errors = null;
        }

        saveLineItem(li: Models.ShipmentLineItem) {
            // set actual and estimated for backend model
            if (li.quantityIsEstimated) {
                li.actualQuantityShipped = null;
                li.estimatedQuantityShipped = li.quantityShipped;
            }
            else {
                li.estimatedQuantityShipped = null;
                li.actualQuantityShipped = li.quantityShipped;
            }

            return this.record.save(true).then(() => {
                this.editingLineItem = false;
                this.toaster.success('Line Item Saved');
                this.errors = null;
            }, (errors) => {
                if (errors.length) {
                    this.errors = errors;
                }
                this.toaster.error('Line Item Save Failed');
                return this.$q.reject(errors);
            });
        }

        editLineItem(lineItem: { id: number, currentlyEditing: boolean }) {
            this._prepareForAddEdit();
            lineItem.currentlyEditing = true;
        }

        deleteLineItem(lineItem: Models.ShipmentLineItem) {
            for (var idx = 0; idx < this.lineItems.length; idx++) {
                if (this.lineItems[idx] === lineItem) {
                    break;
                }
            }

            this.lineItems.splice(idx, 1);
            this.save();
        }

        canAddLineItem(): boolean {
            if (this.isInEditMode) {
                return false;
            } else if (this.editingLineItem) {
                return false;
            } else if (!this.record.record || !this.record.record.canEdit) {
                return false;
            } else {
                return true;
            }
        }

        canEditLineItem(lineItem: { currentlyEditing: boolean }) {
            if (!this.record.record.canEdit) {
                return false;
            }
            if (this.isInEditMode) {
                return false;
            }
            if (this.editingLineItem) {
                return false;
            }
            if (lineItem.currentlyEditing) {
                return false;
            }
            return true;
        }

        getQuantity(lineItem: Models.ShipmentLineItem): string {
            let quantity = '';
            if (lineItem.quantityShipped) {
                quantity = (lineItem.quantityShipped + '') + ' ' + lineItem.quantityShippedUnitOfMeasurement;
                if (lineItem.quantityIsEstimated) {
                    quantity += ' (estimated)';
                }
            }
            return quantity;
        }

    }
}