module App.Controllers {
    interface IncidentRow {
        id: number;
        accountName: string;
        number: string;
        createdOn: Date;
        transactionDate: Date;
        recordStatus: string;
    }
    export class IncidentsController {
        incidents: IncidentRow[];

        includeArchived: boolean;
        filterDateOptions: { field: string; label: string; }[];

        friendlyRecordTypeName(recordTypeName: string): string {
            // call the global helper
            return friendlyRecordTypeName(recordTypeName);
        }

        changeFilter() {
            this.incidents = null;

            this.RecordService.getRecords<Models.IncidentRecord>(Models.IncidentRecord, 'incident', {
                includeArchived: this.includeArchived
            }).then(records => records.map(r => r.flattened)).then(data => this.incidents = data);
        }

        static $inject = ['incidents', 'RecordService', '$state', '$timeout'];
        constructor(incidents: Models.IncidentRecordFlattened[], private RecordService: Services.RecordService, private $state: ng.ui.IStateService, private $timeout: ng.ITimeoutService) {
            this.incidents = incidents.map(incident => {
                return {
                    id: incident.id,
                    accountName: incident.accountName,
                    number: incident.number,
                    createdOn: incident.createdOn,
                    transactionDate: incident.transactionDate,
                    recordStatus: incident.recordStatus
                }
            });
            this.filterDateOptions = [
                { field: 'createdOn', label: 'Create Date' },
                { field: 'transactionDate', label: 'Report Date' }
            ];
        }
    }

    IncidentsControllerRoute.$inject = ['$stateProvider'];
    export function IncidentsControllerRoute($stateProvider: angular.ui.IStateProvider) {
        $stateProvider
            .state('records.incident', {
                url: '/incidents',
                abstract: true,
                template: '<ui-view/>'
            })
            .state('records.incident.index', {
                url: '',
                templateUrl: 'views/incidents.html',
                controller: IncidentsController,
                controllerAs: 'vm',
                title: 'Incidents',
                resolve: {
                    incidents: ['RecordService', '$rootScope', (RecordService: Services.RecordService, $rootScope: ng.IRootScopeService) => {
                        return RecordService.getRecords<Models.IncidentRecord>(Models.IncidentRecord, 'incident')
                            .then(records => records.map(r => r.flattened));
                    }]
                }
            })
            .state('records.incident.create', {
                url: 'add',
                templateUrl: 'views/record-incident.html',
                controller: IncidentRecordController,
                controllerAs: 'vm',
                title: 'Create Incident Report',
                resolve: IncidentRecordController.prototype.resolve(true)
            })
            .state('records.incident.record', {
                url: '/:id',
                templateUrl: 'views/record-incident.html',
                controller: IncidentRecordController,
                controllerAs: 'vm',
                title: 'Incident Report',
                resolve: IncidentRecordController.prototype.resolve()
            })
        ;
    }
}

angular.module('app').config(App.Controllers.IncidentsControllerRoute);   