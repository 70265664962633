module App.Controllers {
    export class InventoryFilter {
        // Main filter for querying database
        accountId: number;
        statusId: number;
        locationId: number;
        siteId: number;
        varietyId: number;
        startDate: Date;
        endDate: Date;
        includeArchived: boolean = false;

        // Remainder is to drive the dropdowns
        account: Models.Account;
        location: Deregulated.ILocation;
        site: Deregulated.ISite;
        variety: Deregulated.ILookup;

        /**
         * Getter for account
         */
        get Account() {
            return this.account;
        }
        /**
         * Setter for account
         */
        set Account(account: Models.Account) {
            this.account = account || null;
            this.accountId = account ? account.id : null;
        }

        /**
         * Getter for location
         */
        get Location() {
            return this.location;
        }
        /**
         * Setter for location
         */
        set Location(location: Deregulated.ILocation) {
            this.location = location || null;
            this.locationId = location ? location.id : null;
        }

        /**
         * Getter for site
         */
        get Site() {
            return this.site;
        }
        /**
         * Setter for site
         */
        set Site(site: Deregulated.ISite) {
            this.site = site || null;
            this.siteId = site ? site.id : null;
        }

        /**
         * Getter for variety
         */
        get Variety() {
            return this.variety;
        }
        /**
         * Setter for variety
         */
        set Variety(variety: Deregulated.ILookup) {
            this.variety = variety || null;
            this.varietyId = variety ? variety.id : null;
        }
    }

    export class InventoryController {
        inventoryRows: Models.InventoryRow[];

        filter: InventoryFilter;
        exportFilter: InventoryFilter;

        noneFoundMessage: string;

        showArchivedColumn: boolean = false;

        errors: string[];
        controls = {
            accounts: <Models.Account[]>[]
        }

        clear() {
            // Clear selection criteria and results
            this.filter.Account    = null;
            this.filter.Location   = null;
            this.filter.Site       = null;
            this.filter.Variety    = null;
            this.filter.startDate  = null;
            this.filter.endDate = null;
            this.filter.includeArchived = false;

            this.inventoryRows     = null;
            this.noneFoundMessage = null;

            this.showArchivedColumn = false;
        }

        runReport() {
            let queryFilter: App.Models.Filters = this.getQueryFilter(this.filter);
            this.showArchivedColumn = this.filter.includeArchived;
            this.InventoryService.getInventory(queryFilter).then(inventory => {
                this.inventoryRows = inventory;
                if (inventory.length > 0) {
                    this.noneFoundMessage = null;
                }
                else {
                    this.noneFoundMessage = 'No signed reports match the filter';
                }
                // Save this for the export
                this.exportFilter = this.filter;
            });
        }

        exportExcel() {
            let queryFilter: App.Models.Filters = this.getQueryFilter(this.exportFilter);
            this.InventoryService.getInventoryReport(queryFilter).then(blob => {
                // filename suffix is date time
                saveAs(blob, 'Inventory-Report-' + getDateTimeString(new Date()) + '.xlsx');
            });
        }

        static $inject = [
            'accounts',
            'InventoryService',
            'AccountService',
            '$state',
            '$scope'];
        constructor(
            accounts: Models.Account[],
            private InventoryService: Services.InventoryService,
            private AccountService: Services.AccountService,
            private $state: ng.ui.IStateService,
            $scope: ng.IScope
        ) {
            this.filter = new InventoryFilter();
            this.controls.accounts = accounts;
            // We've disabled this if length === 1, so we need to set the model manually
            if (this.controls.accounts.length === 1) {
                this.filter.Account = this.controls.accounts[0];
            }
        }

        private getQueryFilter(inFilter: InventoryFilter): App.Models.Filters {
            let queryFilter: App.Models.Filters = {
                accountId: inFilter.accountId,
                statusId: inFilter.statusId,
                locationId: inFilter.locationId,
                siteId: inFilter.siteId,
                varietyId: inFilter.varietyId,
                startDate: (inFilter.startDate) ? inFilter.startDate.toISOString().slice(0, 10).replace(/-/g, '') : null, // just date part
                endDate: (inFilter.endDate) ? inFilter.endDate.toISOString().slice(0, 10).replace(/-/g, '') : null, // just date part
                includeArchived: inFilter.includeArchived
            };
            return queryFilter;
        }
    }

    InventoryControllerRoute.$inject = ['$stateProvider'];
    export function InventoryControllerRoute($stateProvider: angular.ui.IStateProvider) {
        $stateProvider
        .state('inventory', {
            url: '/inventory',
            templateUrl: 'views/inventory.html',
            controller: InventoryController,
            controllerAs: 'vm',
            title: 'Inventory Report',
            resolve: {
                accounts: ['AccountService', '$rootScope',
                    (AccountService: Services.AccountService, $rootScope: ng.IRootScopeService): Models.Account[] | ng.IPromise<Models.Account[]> => {
                        if ($rootScope.user.can.viewAccountList) {
                            return AccountService.getAccounts();
                        }
                        else {
                            return [$rootScope.user.account];
                        }
                    }]
            }
        });
    }
}

angular.module('app').config(App.Controllers.InventoryControllerRoute);  