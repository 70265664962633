module App.Controllers {
    export class ProcessingRecordController extends BaseRecordController<Models.ProcessingRecord> {
        model = Models.ProcessingRecord;

        record: Models.ProcessingRecord;
        accountList: Models.Account[];
        controls: any;

        editingLineItem: boolean;

        static $inject = [
            'record',
            'accountList',
            'controls',
            'AccountService',
            'RecordService',
            '$state',
            '$scope',
            '$modal',
            'toaster',
            '$q'
        ];
        constructor(
            record: Models.ProcessingRecord,
            accountList: Models.Account[],
            controls,
            private AccountService: Services.AccountService,
            private RecordService: Services.RecordService,
            $state: ng.ui.IStateService,
            $scope: ng.IScope,
            $modal: ng.ui.bootstrap.IModalService,
            toaster: ng.toaster.IToasterService,
            private $q: ng.IQService
        ) {
            super(record, accountList, controls, $state, $modal, toaster);
            this.accountList = accountList;

            // If the account was already on this record but is not in the list then add it
            if (this.record.record.account && !_.find(this.accountList, { id: this.record.record.account.id })) {
                this.accountList.push(this.record.record.account);
            }

        }

        /**
         * Resolve all promises before loading the controller
         * 
         * @Param isCreateMode  boolean
         */
        resolve(isCreateMode = false): { record: Function; accountList: Function; controls: Function; } {
            getRecord.$inject = ['$stateParams', 'RecordService'];
            function getRecord($stateParams: ng.ui.IStateParamsService, RecordService: Services.RecordService): Models.ProcessingRecord | ng.IPromise<Models.ProcessingRecord> {
                if (isCreateMode) {
                    var record = new Models.ProcessingRecord().load({});
                    return record;
                } else {
                    return RecordService.getRecord<Models.ProcessingRecord>($stateParams['id'], Models.ProcessingRecord, 'processing');
                }
            }
            
            getAccounts.$inject = ['AccountService', '$rootScope'];
            function getAccounts(AccountService: Services.AccountService, $rootScope: ng.IRootScopeService): Models.Account[] | ng.IPromise<Models.Account[]> {
                if ($rootScope.user.can.viewAccountList) {
                    return AccountService.getAccounts({ 'forProcessingRecord': true });
                }
                else {
                    // The button would not have been enabled if their account could not 
                    // create a processing report so that check was already done
                    return [$rootScope.user.account];
                }
            }

            getControls.$inject = ['LookupService', '$q'];
            function getControls(LookupService: Services.LookupService, $q: ng.IQService) {
                return $q.all([LookupService.getGenericLookup('IncidentSigningMessage', true)]).then((data) => {
                    return {
                        signingMessage: data[0]
                    };
                });
            }

            return {
                record: getRecord,
                accountList: getAccounts,
                controls: getControls
            }
        }

        verify() {
            if (this.record.record.canSign) { // same state needed for verify
                this.record.verify().then((record) => {
                    this.record.load(record);
                    this.errors = null;
                    this.isInEditMode = false;
                },(errors) => {
                    if (errors.data.errors.length) {
                        this.errors = errors.data.errors;
                        this.errorReason = 'Verify';
                    }
                });
            }
        }

        // allowed unless editing a destination
        toggleLineItem(lineItem: Models.ProcessingLineItem) {
            return !this.editingLineItem;
        }

        public addLineItem() {
            this.editingLineItem = true;

            let lineItem = new Models.ProcessingLineItem();
            lineItem["currentlyEditing"] = true;

            this.record.lineItems.push(lineItem);
        }

        get lineItems(): Models.ProcessingLineItem[] { return this.record.lineItems; }

        private _prepareForAddEdit() {
            this.editingLineItem = true;
            this.lineItems.forEach(li => { li['currentlyEditing'] = false; });
        }

        resetRecord() {
            let pristineRecord = angular.copy(this.record.copy);
            this.record.load(pristineRecord);
            this.editingLineItem = false;
            this.errors = null;
        }

        saveLineItem() {
            return this.record.save(true).then(() => {
                this.editingLineItem = false;
                this.toaster.success('Line Item Saved');
                this.errors = null;
            }, (errors) => {
                if (errors.length) {
                    this.errors = errors;
                }
                this.toaster.error('Line Item Save Failed');
                return this.$q.reject(errors);
            });
        }

        editLineItem(lineItem: { id: number, currentlyEditing: boolean }) {
            this._prepareForAddEdit();
            lineItem.currentlyEditing = true;
        }

        deleteLineItem(lineItem: Models.ProcessingLineItem) {
            // note that _.findIndex doesn't like the intersect type
            for (var idx = 0; idx < this.lineItems.length; idx++) {
                if (this.lineItems[idx] === lineItem) {
                    break;
                }
            }

            this.lineItems.splice(idx, 1);
            this.save();
        }

        canAddLineItem(): boolean {
            if (this.isInEditMode) {
                return false;
            } else if (this.editingLineItem) {
                return false;
            } else if (!this.record.record || !this.record.record.canEdit) {
                return false;
            } else {
                return true;
            }
        }

        canEditLineItem(lineItem: { currentlyEditing: boolean }) {
            if (!this.record.record.canEdit) {
                return false;
            }
            if (this.isInEditMode) {
                return false;
            }
            if (this.editingLineItem) {
                return false;
            }
            if (lineItem.currentlyEditing) {
                return false;
            }
            return true;
        }

        varietyFilter(item: Models.Variety): boolean {
            return item.active;
        }

    }
}