module App.Controllers {
    export class MarkupLookupController {
        markup: string;

        static $inject = ['markup'];
        constructor(markup) {
            this.markup = markup;
        }
    }

    MarkupLookupControllerRoute.$inject = ['$stateProvider'];
    export function MarkupLookupControllerRoute($stateProvider: angular.ui.IStateProvider) {
        $stateProvider
            .state('markup-lookup', {
                url: '/lookup/:lookup',
                title: 'Innate',
                templateUrl: 'views/markup-lookup.html',
                controllerAs: 'vm',
                controller: MarkupLookupController,
                resolve: {
                    markup: ['$stateParams', 'LookupService', ($stateParams: ng.ui.IStateParamsService, LookupService: Services.LookupService) => {
                        return LookupService.getGenericLookup($stateParams['lookup'], false).then((data) => {
                            return data;
                        });
                    }]
                }
            });
    }
}

angular.module('app').config(App.Controllers.MarkupLookupControllerRoute);