namespace App.Directives {
    class LocationSitesController {
        parentVm: Controllers.AccountController;

        location: Deregulated.ILocation;

        canEditSite = (site: Deregulated.ISite) => {
            return !(site.associatedSignedRecordNumbers.length);
        };

        addSite = (index) => {
            this._prepareForAddEdit();

            var site: Deregulated.ISite = {
                name: null,
                displayName: null,
                description: null,
                volunteerMonitoringMonths: 37,
                coordinates: [
                    { latitude: null, longitude: null, description: null },
                    { latitude: null, longitude: null, description: null },
                    { latitude: null, longitude: null, description: null },
                    { latitude: null, longitude: null, description: null }
                ],
                associatedSignedRecordNumbers: [],
                associatedUnsignedRecordNumbers: []
            };

            site['currentlyEditing'] = true;
            this.location.sites.push(site);
        }

        editSite = (site: Deregulated.ISite) => {
            this._prepareForAddEdit();
            this.parentVm.errors = null;
            site['currentlyEditing'] = true;
        }

        saveSite() {
            return this.parentVm.save(true).then(() => {
                this.toaster.success('Site Saved');
            }, (error) => {
                this.toaster.error('Site Save Failed');
                return this.$q.reject(error);
            });
        }

        deleteSite = (site: Deregulated.ISite) => {
            let index = _.findIndex(this.location.sites, (locationSite) => {
                return (site === locationSite)
            });

            this.location.sites.splice(index, 1);
            this.parentVm.save(true).then(() => {
                this.toaster.success('Site Removed');
            }, (errors) => {
                this.toaster.error('Site Delete Failed');
                // Add it back if error encountered
                this.location.sites.splice(index, 0, site);
            });
        }

        resetSite() {
            var activeLocationIdx = _.findIndex(this.parentVm.account.locations, location => {
                return location['active'];
            });

            this.parentVm.account.reset();
            this.parentVm.errors = null;
            this.parentVm.editingSite = false;
            this.parentVm.isInEditMode = false;

            // Reselect location
            if (this.parentVm.account.locations[activeLocationIdx])
                this.parentVm.account.locations[activeLocationIdx]['active'] = true;
        }

        // allowed unless editing a site
        toggleSite(site: Deregulated.ISite) {
            return !this.parentVm.editingSite;
        }

        lookupSiteName(input: number) {
            let siteType: Deregulated.ILookup = <Deregulated.ILookup>_.findWhere(this.parentVm.controls.siteTypes, { id: input });
            return siteType ? siteType.name : input;
        }

        getVolunteerMonitoringMonthsDisplay(site: Deregulated.ISite) {
            let retval: any;
            if (site.typeId !== this.parentVm.getFieldTypeId()) {
                retval = 'N/A';
            }
            else if (site.volunteerMonitoringMonths === 1) {
                retval = '1 month';
            }
            else if (site.volunteerMonitoringMonths === null || site.volunteerMonitoringMonths === undefined) {
                retval = '';
            }
            else {
                retval = site.volunteerMonitoringMonths + ' months';
            }
            //console.log(`[${value}] [${retval}]`);
            return retval;
        }

        private _prepareForAddEdit() {
            this.parentVm.editingSite = true;
            this.parentVm.isInEditMode = true;
            this.location.sites.forEach(site => {
                site['currentlyEditing'] = false;
            });
        }

        static $inject = ['$scope', 'toaster', '$q', '$filter'];
        constructor($scope: ng.IScope, private toaster: ng.toaster.IToasterService, private $q: ng.IQService, private $filter: ng.IFilterService) {
            this.parentVm = $scope['vm'];
            this.location = $scope['location'];
        }

    }

    export function LocationSitesDirective() {
        let template = `
            <div class="clearfix">
                <button class="btn btn-std-size btn-info pull-left" ng-click="ls.addSite()" ng-show="user.can.manageLocations" ng-disabled="ls.parentVm.isInEditMode">
                    <span class="glyphicon glyphicon-plus"></span> Add Site
                </button>
            </div>
            <div class="table-responsive clearfix">
                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Site Name </th>
                            <th>Site Type</th>
                            <th>Year</th>
                            <th>Volunteer Monitoring</th>
                            <th>Primary</th>
                            <th style="width: 100px;"></th>
                        </tr>
                    </thead>
                    <tbody drawers>
                    <tr ng-repeat="site in ls.location.sites" drawer="'views/site-drawer.html'" open-on="site.currentlyEditing">
                            <td>
                                <i ng-if="!ls.canEditSite(site)" 
                                   tooltip="Site Locked due to use on the following reports: {{ls.parentVm.prepareLockMessage(site.associatedSignedRecordNumbers)}}" 
                                   tooltip-append-to-body="true" tooltip-placement="top" class="glyphicon glyphicon-lock"></i>
                            </td>
                            <td toggle-drawer="ls.toggleSite(site)">
                                {{site.name}}
                            </td>
                            <td toggle-drawer="ls.toggleSite(site)">
                                {{ls.lookupSiteName(site.typeId)}}
                            </td>
                            <td toggle-drawer="ls.toggleSite(site)">
                                {{site.year}}
                            </td>
                            <td toggle-drawer="ls.toggleSite(site)">
                                {{ls.getVolunteerMonitoringMonthsDisplay(site)}}
                            </td>
                            <td toggle-drawer="ls.toggleSite(site)">
                                {{site.coordinates[0].latitude}}<span ng-show="site.coordinates[0].latitude">,</span> {{site.coordinates[0].longitude}}                               
                                <br/>
                                <small>{{site.coordinates[0].description | limitTo: 20}}<span ng-show="site.coordinates[0].description > 20">...</span></small>
                            </td>
                            <td >
                                <div ng-show="!site.currentlyEditing && user.can.manageLocations" style="text-align: right">
                                    <span tooltip="Cannot delete due to use on the following reports: {{ls.parentVm.prepareLockMessage(site.associatedUnsignedRecordNumbers)}}" 
                                        tooltip-append-to-body="true" tooltip-placement="top" tooltip-enable="site.associatedUnsignedRecordNumbers.length" style="display:inline-block;">
                                        <button on-confirm="ls.deleteSite(site)" class="btn btn-danger btn-sm" 
                                                ng-disabled="ls.parentVm.isInEditMode || site.associatedUnsignedRecordNumbers.length" ng-show="!site.associatedSignedRecordNumbers.length">
                                            <span class="glyphicon glyphicon-trash"></span>
                                        </button>
                                    </span>
                                    <button open-drawer="ls.editSite(site)" class="btn btn-edit btn-sm" ng-disabled="ls.parentVm.isInEditMode">
                                        <span class="glyphicon glyphicon-pencil"></span>
                                    </button>
                                </div>
                                <div ng-show="site.currentlyEditing && user.can.manageLocations" style="text-align: right">
                                    <button close-drawer="ls.resetSite()" class="btn btn-cancel btn-sm">
                                        <span class="glyphicon glyphicon-remove"></span>
                                    </button>
                                    <button close-drawer="ls.saveSite()" class="btn btn-save btn-sm">
                                        <span class="glyphicon glyphicon-ok"></span>
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td colspan="7" ng-show="!location.sites.length" class="text-center"> No Sites <span ng-show="ls.location.name">on</span> {{ls.location.name}} </td>
                        </tr>
                    </tbody>
                </table>
            </div><!-- .table-responsive -->
        `;
        return {
            restrict: 'E',
            controller: LocationSitesController,
            controllerAs: 'ls',
            bindToController: true,
            template: template
        }

    }
}
angular.module('app').directive('locationSites', App.Directives.LocationSitesDirective);

