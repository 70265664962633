module App.Models {
    export class BaseFlattened {
        id: number;
        recordId: number;
        accountName: string;
        number: string;
        transactionDate: Date;
        locationName: string;
        siteName: string;
        varietyName: string;
        createdOn: Date;
        recordStatus: string;
        recordType: string;
    }
    export abstract class _BaseRecord implements Abstractions.IDataLoader {
        recordType: string;

        id: number;
        recordId: number;
        record: Record = new Record();

        number: string; // e.g. PL_0001, HV_0004, etc
        transactionDate: Date;

        /**
         * Smart Table Filter Properties
         */
        get accountName(): string {
            return this.record.account ? this.record.account.name : '';
        }
        get locationName(): string {
            return this.record.location ? this.record.location.name : '';
        }
        get siteName(): string {
            return this.record.site ? this.record.site.name : '';
        }
        get varietyName(): string {
            return this.record.variety ? this.record.variety.name : '';
        }

        copy: _BaseRecord;

        recordService: App.Services.RecordService;

        save<T extends Models._BaseRecord>(mute = false, reload = true): ng.IPromise<any> {
            var saveRecord = this.id ? this.recordService.edit(this, mute) : this.recordService.add(this, mute);
            return saveRecord.then((record) => {
                if (record && record.id) this.id = record.id;
                if (reload) {
                    return this.recordService.getRecord(this.id, Models[this.recordType], this.recordType).then((_record) => {
                        this.load(_record);
                        return _record;
                    });
                }
                else {
                    return this;
                }
            });
        }

        remove<T extends Models._BaseRecord>(mute = false) {
            return this.recordService.remove(this, mute);
        }

        signAndLock() {
            return this.recordService.signRecord(this);
        }

        unlock() {
            return this.recordService.unlockRecord(this);
        }

        load(data: {}) {
            this.copy = <any>angular.copy(data);
            for (var prop in data) {
                if (data.hasOwnProperty(prop)) {
                    if (prop === 'record') {
                        data[prop] = new Models.Record().load(data[prop]);
                    }
                    if (prop === 'transactionDate' && data[prop] !== null) {
                        data[prop] = new Date(data[prop]);
                    }
                    this[prop] = data[prop];
                }
            }
            return this;
        }

        get flattened(): BaseFlattened {
            return {
                id: this.id,
                recordId: this.recordId,
                accountName: this.accountName,
                number: this.number,
                transactionDate: this.transactionDate,
                locationName: this.locationName,
                siteName: this.siteName,
                varietyName: this.varietyName,
                createdOn: this.record.createdOn,
                recordStatus: this.record.status.name,
                recordType: this.record.type.name
            }
        }
    }
}