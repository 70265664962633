module App.Controllers {
    export class NetworkController {
        network: Models.Network;
        accounts: Models.Account[];
        unselectedAccounts: Models.Account[];
        selectedAccount: Models.Account;

        isInEditMode: boolean = false;
        errors: string[];
        controls: {
            networkTypes: Models.NetworkType[];
        }

        addAccount = (account) => {
            var networkAccount: Models.NetworkAccount = {
                id: null,
                accountId: account.id,
                account: account,
                visibility: null,
                networkId: this.network.id
            };

            this.network.accounts.push(networkAccount);

            this.updateUnselectedAccounts();

            this.selectedAccount = undefined;
        }

        removeAccount = (accountIndex) => {
            this.network.accounts.splice(accountIndex, 1);

            this.updateUnselectedAccounts();
        }

        save = () => {
            this.network.save().then((network) => {
                if (this.$state.current.name === 'networks.create') {
                    this.$state.go('networks.network', { id: network.id });
                } else {
                    this.isInEditMode = false;
                }
                this.errors = null;
            },(errors) => {
                    if (errors.length) {
                        this.errors = errors;
                    }
                });
        }

        cancel = () => {
            if (this.$state.current.name === 'networks.create') {
                this.$state.go('networks.index');
            } else {
                this.errors = null;
                this.network.reset();
                this.isInEditMode = false;
                this.updateUnselectedAccounts();
            }
        }

        delete = () => {

        }

        updateUnselectedAccounts = () => {
            this.unselectedAccounts = [];

            for (var i = 0; i < this.accounts.length; i++) {
                var selected = false;
                for (var j = 0; j < this.network.accounts.length; j++) {
                    if (this.accounts[i].id === this.network.accounts[j].accountId) {
                        selected = true;
                    }
                }
                if (!selected) {
                    this.unselectedAccounts.push(this.accounts[i]);
                }
            }
        }

        static $inject = ['network', 'accounts', 'controls', 'NetworkService', '$state', '$scope'];
        constructor(network, accounts, controls, private NetworkService: Services.NetworkService, private $state: ng.ui.IStateService, $scope: ng.IScope) {
            this.network = network;
            this.accounts = accounts;
            this.controls = controls;

            this.updateUnselectedAccounts();

            if ($state.current.name === 'networks.create') {
                this.isInEditMode = true;
            }
            // filter out inactive network types unless it is being used here
            this.controls.networkTypes = this.controls.networkTypes.filter(at => at.active || at.id == this.network.typeId);

        }
    }

    NetworkControllerRoute.$inject = ['$stateProvider'];
    export function NetworkControllerRoute($stateProvider: angular.ui.IStateProvider) {
        $stateProvider
            .state('networks.create', {
            url: '/add',
            templateUrl: 'views/network.html',
            controller: NetworkController,
            controllerAs: 'vm',
            title: 'Create Network',
            resolve: {
                network: function () {
                    var network = new Models.Network().load({});
                    return network;
                },
                accounts: ['AccountService', (AccountService: Services.AccountService) => {
                    return AccountService.getAccounts().then((_accounts) => {
                        return _accounts;
                    });
                }],
                controls: ['LookupService', '$q', (LookupService: Services.LookupService, $q: ng.IQService) => {
                    return $q.all([LookupService.getNetworkTypes(false)]).then((data) => {
                        var _controls = {
                            networkTypes: data[0],
                        };

                        return _controls;
                    });
                }]
            }
        })
        .state('networks.network', {
            url: '/:id',
            templateUrl: 'views/network.html',
            controller: NetworkController,
            controllerAs: 'vm',
            title: 'Network Detail',
            resolve: {
                network: ['$stateParams', 'NetworkService', ($stateParams: ng.ui.IStateParamsService, NetworkService: Services.NetworkService) => {
                    return NetworkService.getNetwork($stateParams['id']).then((_network) => {
                        return _network;
                    });

                }],
                accounts: ['AccountService', (AccountService: Services.AccountService) => {
                    return AccountService.getAccounts().then((_accounts) => {
                        return _accounts;
                    });
                }],
                controls: ['LookupService', '$q', (LookupService: Services.LookupService, $q: ng.IQService) => {
                    return $q.all([LookupService.getNetworkTypes(false)]).then((data) => {
                        var _controls = {
                            networkTypes: data[0],
                        };

                        return _controls;
                    });
                }]
            }
        });
    }
}

angular.module('app').config(App.Controllers.NetworkControllerRoute);  