module App.Models {
    export class SiteType extends Models._BaseLookup {
        /**
         * Overriding super's 'load' method so correct 'this' is returned.
         */
        load(data: {}) {
            super.load(data);
            return this;
        }

        constructor() {
            super();
            this.lookupType = 'Site Type';
            this.lookupUrl = 'lookup/sitetypes';
        }
    }
}
   