module App.Models {
    export class NetworkAccount {
        id: number;
        accountId: number;
        account: Models.Account;
        visibility: number;
        networkId: number;
    }
    export class NetworkFlattened {
        id: number;
        name: string;
        number: number;
        type: string;
        description: string;
        effectiveDate: Date;
        expirationDate: Date;
    }
    export class Network implements Abstractions.IDataLoader {

        id: number;
        name: string;
        number: number;
        typeId: number;
        type: Models.NetworkType;
        description: string;
        accounts: NetworkAccount[] = [];
        effectiveDate: Date;
        expirationDate: Date;

        copy: {};

        NetworkService: App.Services.NetworkService;

        save(mute = false) {
            var saveNetwork: ng.IPromise<Models.Network> = this.id ? this.NetworkService.edit(this, mute) : this.NetworkService.add(this, mute);
            return saveNetwork.then((Network) => {
                var copy = angular.copy(this); // Copy the current values
                delete copy.copy; // Delete the expired copy
                this.copy = copy; // Store the new copy
                return Network;
            });
        }

        remove() {
            return this.NetworkService.remove(this);
        }

        reset() {
            this.load(this.copy);
        }

        load(data: {}) {
            this.copy = angular.copy(data);
            for (var prop in data) {
                if (data.hasOwnProperty(prop)) {
                    this[prop] = data[prop];
                    if (prop === 'effectiveDate' || prop === 'expirationDate') {
                        this[prop] = new Date(this[prop].toString());
                    }
                }
            }
            return this;
        }

        get flattened(): NetworkFlattened {
            return {
                id: this.id,
                name: this.name,
                number: this.number,
                type: this.type.name,
                description: this.description,
                effectiveDate: this.effectiveDate,
                expirationDate: this.expirationDate
            }
        }
    }
} 