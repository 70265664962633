module App.Models
{
    export class UserStatus {
        id: number;
        name: string;
    }

    export class User implements Abstractions.IDataLoader, Deregulated.IUser
    {
        id: number;
        username: string;
        firstName: string;
        lastName: string;
        status: UserStatus;
        statusId: number;
        fullName: string;
        roleId: number;
        account: Account;
        accountId: number;
        title: string;
        contactInformation: ContactInformation = new Models.ContactInformation();
        can: Deregulated.IUserPermissions

        UserService: App.Services.UserService;

        save() {
            var saveUser: ng.IPromise<Models.User> = this.id ? this.UserService.editUser(this) : this.UserService.addUser(this);
            return saveUser.then((user) => {
                var userId = user.id || this.id;
                return this.UserService.getUser(userId).then((_user) => {
                    this.load(_user);
                    return _user;
                });
            });
        }

        remove() {
            return this.UserService.removeUser(this);
        }

        load(data: {}) {
            for (var prop in data)
            {
                if (data.hasOwnProperty(prop))
                {
                    if (prop === 'contactInformation') {
                        data[prop] = new ContactInformation(data[prop]);
                    }
                    this[prop] = data[prop];
                }
            }
            return this;
        }
    }
} 