module App
{
    export class ConfigureApplication
    {
        static $inject = ['$urlRouterProvider', '$httpProvider'];
        constructor($urlRouterProvider: angular.ui.IUrlRouterProvider)
        {
            $urlRouterProvider.otherwise('/');
        }
    }
}

angular.module('app', ['config']).config(App.ConfigureApplication);