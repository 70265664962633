module App.Controllers
{
    export class LoginController
    {
        error: string = null;

        login = (username: string, password: string) => {
            this.AuthService.authenticateUser(username, password).then(
                (_token) =>
                {
                    // Handle success
                    this.AuthService.setAuthToken(_token);
                    this.$state.go('index');
                },
                (reason) =>
                {
                    // Handle rejection
                    if (reason.data && reason.data.length > 0) {
                        this.error = reason.data;
                    }
                    else {
                        this.error = 'Invalid Username or Password';
                    }
                });
        }

        static $inject = ['AuthService', '$state'];
        constructor(private AuthService: Services.AuthService, private $state: ng.ui.IStateService)
        {

        }
    }

    LoginControllerRoute.$inject = ['$stateProvider'];
    export function LoginControllerRoute($stateProvider: angular.ui.IStateProvider)
    {
        $stateProvider.state('login', {
            url: '/login',
            templateUrl: 'views/login.html',
            controller: LoginController,
            controllerAs: 'vm',
            title: 'Login'
        });
    }
}

angular.module('app').config(App.Controllers.LoginControllerRoute);