module App.Controllers
{
    export class MainController
    {
        currentUser: string;
        trainingVideoUrl: string;
        trainingVideoUrlSpanish: string;
        documentationUrl: string;

        static $inject = ['$rootScope', 'trainingVideoUrl', 'trainingVideoUrlSpanish', 'documentationUrl'];
        constructor($rootScope: ng.IRootScopeService, trainingVideoUrl: string, trainingVideoUrlSpanish: string, documentationUrl: string)
        {
            this.currentUser = $rootScope.user.fullName || 'Guest';
            this.trainingVideoUrl = trainingVideoUrl;
            this.trainingVideoUrlSpanish = trainingVideoUrlSpanish;
            this.documentationUrl = documentationUrl;
        }
    }

    MainControllerRoute.$inject = ['$stateProvider'];
    export function MainControllerRoute($stateProvider: angular.ui.IStateProvider)
    {
        $stateProvider.state('index', {
            url: '/',
            templateUrl: 'views/main.html',
            controller: MainController,
            controllerAs: 'vm',
            title: 'Home',
            resolve: {
                trainingVideoUrl: ['LookupService', (LookupService: Services.LookupService) => {
                    return LookupService.getGenericLookup('TrainingVideoUrl', true).then((data) => {
                        return data;
                    });
                }],
                trainingVideoUrlSpanish: ['LookupService', (LookupService: Services.LookupService) => {
                    return LookupService.getGenericLookup('TrainingVideoUrlSpanish', true).then((data) => {
                        return data;
                    });
                }],
                documentationUrl: ['LookupService', (LookupService: Services.LookupService) => {
                    return LookupService.getGenericLookup('DocumentationUrl', true).then((data) => {
                        return data;
                    });
                }]
            }
        });
    }
}

angular.module('app').config(App.Controllers.MainControllerRoute);