module App.Models {
    export class LateBlightManagementAction {
        id: number;
        actionDate: Date;
        actionTaken: string;
    }
    export class LateBlightIPMRecordFlattened extends BaseFlattened {
        plantingRecordNumber: string;
    }

    export class LateBlightIPMRecord extends _BaseRecord {
        plantingRecord: Models.PlantingRecord;
        equipmentCleanedSanitizedBeforeAfter: boolean;
        fieldFreeOfVolunteers: boolean;
        excessDestroyed: boolean;
        fieldScouted: boolean;
        lateBlightReportedThisSeason: boolean;
        lateBlightFoliarSymptomsObserved: boolean;
        seedTreatedBeforePlanting: boolean;
        vinesTreatedEarlySeason: boolean;
        vinesTreatedMidSeason: boolean;
        vinesTreatedLateSeason: boolean;
        cropStoredAfterHarvest: boolean;
        tubersTreatedPostHarvest: boolean;
        lessFungicideUsed: boolean;
        percentLessFungicide: number;
        lateBlightManagementActions: Models.LateBlightManagementAction[];

        /**
         * Getter for plantingRecord
         */
        get PlantingRecord() {
            return this.plantingRecord;
        }
        /**
         * Setter for plantingRecord
         */
        set PlantingRecord(plantingRecord: Models.PlantingRecord) {
            this.plantingRecord = plantingRecord || null;
        }

        /**
         * Overriding super's 'load' method so correct 'this' is returned.
         */
        load(data: {}) {
            super.load(data);
            if (this.plantingRecord) {
                this.plantingRecord = new Models.PlantingRecord().load(this.plantingRecord);
            }
            return this;
        }

        get flattened(): LateBlightIPMRecordFlattened {
            return {
                id: this.id,
                recordId: this.recordId,
                accountName: this.accountName,
                number: this.number,
                transactionDate: this.transactionDate,
                locationName: this.locationName,
                siteName: this.siteName,
                varietyName: this.varietyName,
                createdOn: this.record.createdOn,
                recordStatus: this.record.status.name,
                recordType: this.record.type.name,
                plantingRecordNumber: this.plantingRecord ? this.plantingRecord.number : null
            }
        }

        constructor() {
            super();
            this.recordType = 'lateblightipm';
        }
    }
}