function getId(item): string|number
{
    var id;
    for (var prop in item)
    {
        if (item.hasOwnProperty(prop))
        {
            if (_.endsWith(prop, 'id'))
            {
                id = item[prop];
            }
        }
    }
    return id;
}

function getErrorPopParams(reason: ng.IHttpPromiseCallbackArg<any>): ng.toaster.IPopParams
{
    var message: ng.toaster.IPopParams = { type: 'error' };
    // Something went wrong!
    if (reason.status === 500) {
        console.log(reason);
        message.title = 'Internal Server Error';
        message.body = 'Review error in console log for more details.';
    }
    else if (reason.data && reason.data.message) {
        message.title = reason.data.message;
    }
    else {
        message.title = (reason.statusText) ? reason.statusText : 'Something went wrong.';
    }
    return message;
}

function toCamelCase(s) {
    // remove all characters that should not be in a variable name
    // as well underscores an numbers from the beginning of the string
    s = s.replace(/([^a-zA-Z0-9_\- ])|^[_0-9]+/g, "").trim().toLowerCase();
    // uppercase letters preceeded by a hyphen or a space
    s = s.replace(/([ -]+)([a-zA-Z0-9])/g, function (a, b, c) {
        return c.toUpperCase();
    });
    // uppercase letters following numbers
    s = s.replace(/([0-9]+)([a-zA-Z])/g, function (a, b, c) {
        return b + c.toUpperCase();
    });
    return s;
}

// returns dt in the format yyyyMMddhhmm
function getDateTimeString(dt: Date) : string {
    function AddZero(num) {
        return (num >= 0 && num < 10) ? "0" + num : num + "";
    }

    let strDateTime = [dt.getFullYear(), AddZero(dt.getMonth() + 1), AddZero(dt.getDate()), AddZero(dt.getHours()), AddZero(dt.getMinutes())].join("");
    return strDateTime;
}

function getFnName(fn) {
    var f = typeof fn == 'function';
    var s = f && ((fn.name && ['', fn.name]) || fn.toString().match(/function ([^\(]+)/));
    return (!f && 'not a function') || (s && s[1] || 'anonymous');
}

function friendlyRecordTypeName(recordTypeName: string): string {
    // Camel Case to Words, capitalize first letter
    var words = recordTypeName
        .replace(/([A-Z]+)/g, " $1")
        .replace(/([A-Z][a-z])/g, " $1")
        .replace(/^./, function (str) { return str.toUpperCase(); })
    return words;
}

function manipulateFilterDates(filterDateChoice: string, filterByDateRangeStart: any, filterByDateRangeEnd: any): any {
    filterByDateRangeStart = (filterByDateRangeStart != null) ? filterByDateRangeStart.toISOString().slice(0, 10).replace(/-/g, '') : null;
    filterByDateRangeEnd = (filterByDateRangeEnd != null) ? filterByDateRangeEnd.toISOString().slice(0, 10).replace(/-/g, '') : null;

    if (filterDateChoice == 'createDate') {
        if (filterByDateRangeStart != null) {
            filterByDateRangeStart = new Date(filterByDateRangeStart.substr(0, 4), filterByDateRangeStart.substr(4, 2) - 1, filterByDateRangeStart.substr(6, 2));
        }
        // add 24 hours minus 1 second to end date so we cover the whole day
        if (filterByDateRangeEnd != null) {
            filterByDateRangeEnd = new Date(filterByDateRangeEnd.substr(0, 4), filterByDateRangeEnd.substr(4, 2) - 1, filterByDateRangeEnd.substr(6, 2));
            filterByDateRangeEnd.setSeconds(filterByDateRangeEnd.getSeconds() + 86399);
        }
    }
    return {filterByDateRangeStart, filterByDateRangeEnd};
}