module App.Controllers {
    export class SignRecord {
        signingNotes: string;
        signingMessage: string;

        cancel(): void {
            this.$modalInstance.dismiss('cancel');
        }

        ok(): void {
            this.$modalInstance.close(this.signingNotes);
        }

        static config = {
            animation: true,
            templateUrl: 'views/record-sign.html',
            controller: SignRecord,
            controllerAs: 'modal',
            resolve: null,
            size:'lg'
        }

        static $inject = ['signingNotes', 'signingMessage', '$modalInstance'];
        constructor(
            signingNotes,
            signingMessage,
            private $modalInstance: ng.ui.bootstrap.IModalServiceInstance
        ) {
            this.signingNotes = signingNotes;

            this.signingMessage = (signingMessage) ? signingMessage :
                    'By signing this report the user is agreeing that effective controls have been in place to ensure segregation between INNATE<sup>&#174</sup> Potatoes and all conventional potato varieties throughout the process for this report.';            
        }

        static getControllerConfig(signingNotes: string, signingMessage: string) {
            this.config.resolve = {
                signingNotes: function () {
                    return signingNotes;
                },
                signingMessage: function () {
                    return signingMessage;
                }
            };
            return this.config;
        }
    }


} 