module App.Controllers {
    class LookupTab {
        title: string;
        lookupItems: Models._BaseLookup[];
        emptyMessage: string;
    }

    export class ReferenceDataController {
        accountTypes: Models._BaseLookup[];
        networkTypes: Models._BaseLookup[];
        siteTypes: Models._BaseLookup[];
        varieties: Models._BaseLookup[];

        lookupItem: Models._BaseLookup;
        isInEditMode: boolean = false;
        errors: string[];

        lookupTabs: LookupTab[];

        // Add
        add(tabIdx: number) {
            switch (this.lookupTabs[tabIdx].title) {
                case 'Account Type':
                    this.lookupItem = new Models.AccountType();
                    break;
                case 'Network Type':
                    this.lookupItem = new Models.NetworkType();
                    break;
                case 'Site Type':
                    this.lookupItem = new Models.SiteType();
                    break;
                case 'Variety':
                    this.lookupItem = new Models.Variety();
                    break;
                default:
                    throw new Error('Invalid tab');
            }
            this.lookupItem.active = true;
            this.isInEditMode = true;
        }

        // Edit
        edit(tabIdx: number, lookupId: number) {
            if (!this.isInEditMode) {
                this.isInEditMode = true;
                let editThisOne = _.find(this.lookupTabs[tabIdx].lookupItems, { id: lookupId });
                this.lookupItem = angular.copy(editThisOne);
            }
        }

        // Save
        save(tabIdx: number) {
            let promise: ng.IPromise<Models._BaseLookup> = null;
            if (this.lookupItem.id) {
                // edit
                let itemIdx = _.findIndex(this.lookupTabs[tabIdx].lookupItems, x => x.id === this.lookupItem.id)
                if (itemIdx >= 0) {
                    this.LookupService.edit(this.lookupItem).then(() => {
                        // Need to update item in place so as not to disrupt the smart table binding
                        this.lookupTabs[tabIdx].lookupItems[itemIdx].name = this.lookupItem.name;
                        this.lookupTabs[tabIdx].lookupItems[itemIdx].description = this.lookupItem.description;
                        this.lookupTabs[tabIdx].lookupItems[itemIdx].active = this.lookupItem.active;

                        if (this.lookupItem instanceof Models.Variety) {
                            this.lookupTabs[tabIdx].lookupItems[itemIdx]['lateBlightProtected'] = this.lookupItem.lateBlightProtected;
                        }
                        this.reset();
                    }, (errors) => {
                        if (errors.length) {
                            this.errors = errors;
                        }
                    });
                }
            }
            else {
                // add
                let item = angular.copy(this.lookupItem);
                this.LookupService.add(item).then((data) => {
                    item.id = data.id;
                    this.lookupTabs[tabIdx].lookupItems.push(item);
                    this.reset();
                }, (errors) => {
                    if (errors.length) {
                        this.errors = errors;
                    }
                });
            }
        }

        // Remove
        remove(tabIdx: number, lookupId: number) {
            let itemIdx = _.findIndex(this.lookupTabs[tabIdx].lookupItems, x => x.id === lookupId)
            if (itemIdx >= 0) {
                var promise = this.LookupService.remove(this.lookupTabs[tabIdx].lookupItems[itemIdx]).then(() => {
                    // remove from display
                    this.lookupTabs[tabIdx].lookupItems =
                        this.lookupTabs[tabIdx].lookupItems.filter(v => { return v.id !== lookupId });
                    this.reset();
                }, (errors) => {
                    if (errors.length) {
                        this.errors = errors;
                    }
                });
            }            
        }

        // Reset
        reset() {
            this.isInEditMode = false;
            this.lookupItem = null;
            this.errors = null;
        }

        // Disables
        disableAdd(): boolean {
            return this.isInEditMode;
        }
        disableSave(): boolean {
            return !this.isInEditMode || !this.lookupItem.name || !this.lookupItem.description;
        }
        disableName(tabIdx: number): boolean {
            if (this.lookupTabs[tabIdx].title == 'Account Type' &&
                (this.lookupItem.name == 'Grower' || this.lookupItem.name == 'Processor' ||
                 this.lookupItem.name == 'Seed Producer' || this.lookupItem.name == 'Lab')) return true;
            if (this.lookupTabs[tabIdx].title == 'Site Type' && this.lookupItem.name == 'Field') return true;
            return false;
        }
        showDelete(tabIdx: number, value: string): boolean {
            if (this.lookupTabs[tabIdx].title == 'Account Type' &&
                (value == 'Grower' || value == 'Processor' || value == 'Seed Producer' || value == 'Lab')) return false;
            if (this.lookupTabs[tabIdx].title == 'Site Type' && value == 'Field') return false;
            return true;
        }

        static $inject = ['accountTypes', 'networkTypes', 'siteTypes', 'varieties', 'LookupService', '$state'];
        constructor(accountTypes, networkTypes, siteTypes, varieties, private LookupService: App.Services.LookupService, private $state: ng.ui.IStateService) {
            this.accountTypes = accountTypes;
            this.networkTypes = networkTypes;
            this.siteTypes = siteTypes;
            this.varieties = varieties;

            this.lookupTabs = [
                { title: 'Variety', emptyMessage: 'There are no Varieties. How about a nice game of chess?', lookupItems: this.varieties },
                { title: 'Account Type', emptyMessage: 'There are no Account Types', lookupItems: this.accountTypes },
                { title: 'Site Type', emptyMessage: 'There are no Site Types', lookupItems: this.siteTypes },
                { title: 'Network Type', emptyMessage: 'There are no Network Types', lookupItems: this.networkTypes },
            ]
        }

    }

    ReferenceDataControllerRoute.$inject = ['$stateProvider'];
    export function ReferenceDataControllerRoute($stateProvider: angular.ui.IStateProvider) {
        $stateProvider
            .state('reference-data', {
                url: '/reference-data',
                abstract: true,
                template: '<ui-view/>'
            })
            .state('reference-data.index', {
                url: '',
                templateUrl: 'views/reference-data.html',
                controller: ReferenceDataController,
                controllerAs: 'vm',
                title: 'Reference Data',
                resolve: {
                    accountTypes: ['LookupService', (LookupService: Services.LookupService) => {
                        return LookupService.getAccountTypes(false);
                    }],
                    networkTypes: ['LookupService', (LookupService: Services.LookupService) => {
                        return LookupService.getNetworkTypes(false);
                    }],
                    siteTypes: ['LookupService', (LookupService: Services.LookupService) => {
                        return LookupService.getSiteTypes(false);
                    }],
                    varieties: ['LookupService', (LookupService: Services.LookupService) => {
                        return LookupService.getVarieties(false);
                    }]
                }
            });
    }    
}
angular.module('app').config(App.Controllers.ReferenceDataControllerRoute);