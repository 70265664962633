module App.Directives {
    OnConfirmDirective.$inject = ['$parse', '$compile', '$templateCache', '$timeout'];
    export function OnConfirmDirective($parse: ng.IParseService, $compile: ng.ICompileService, $templateCache: ng.ITemplateCacheService, $timeout: ng.ITimeoutService) {
        var template = [
            '<div class="text-center"><div class="btn-group">',
                '<button class="btn btn-std-size btn-success" ng-click="yes()"><span class="glyphicon glyphicon-ok"></span> {{ yesLabel ? yesLabel : \'Yes\' }}</button>',
                '<button class="btn btn-std-size btn-danger" ng-click="no()"><span class="glyphicon glyphicon-remove"></span> {{ noLabel ? noLabel : \'No\' }}</button>',
            '</div></div>'
        ].join('');
        $templateCache.put('confirmDialog.html', template);
        var linkFn: ng.IDirectiveLinkFn = function (scope, element, attr) {
            var confirm = $parse(attr['onConfirm']);
            scope['yesLabel'] = attr['onConfirmYesLabel'];
            scope['noLabel'] = attr['onConfirmNoLabel'];
            scope['message'] = $parse(attr['onConfirmMessage'])(scope);
            scope['yes'] = function yes() {
                confirm(scope);
                closePopover();
            };
            scope['no'] = function no() {
                closePopover();
            };
            function closePopover() {
                $timeout(() => {
                    element.trigger('click');
                });
            }
            element.attr('popover-title', '{{ message ? message : \'Please Confirm\' }}');
            element.attr('popover-template', '\'confirmDialog.html\'');
            element.removeAttr('on-confirm');
            $compile(element)(scope);
        }
        return {
            scope: true,
            restrict: 'A',
            link: linkFn
        };
    }
}

angular.module('app').directive('onConfirm', App.Directives.OnConfirmDirective);