namespace App.Directives {

    class SeedCropLineItemsController {
        parentVm: any;//Controllers.SeedPlantingRecordController & Controllers.SeedHarvestRecordController;
        record: Models.SeedPlantingRecord & Models.SeedHarvestRecord;

        availableSeedPlantingLineItems: Models.SeedPlantingLineItem[];

        getTemplate(seedLineItem: Models.SeedPlantingLineItem & Models.SeedHarvestLineItem): string {
            if (this.parentVm instanceof Controllers.SeedPlantingRecordController) {
                return 'views/seedplantinglineitem-drawer.html';
            } else if (this.parentVm instanceof Controllers.SeedHarvestRecordController) {
                return 'views/seedharvestlineitem-drawer.html';
            } else {
                throw new Error('Unhandled SeedCropLineItemType: ' + getFnName(seedLineItem.constructor));
                //return null;
            }
        }

        getQuantity(seedLineItem: Models.SeedPlantingLineItem & Models.SeedHarvestLineItem): string {
            if (this.parentVm instanceof Controllers.SeedPlantingRecordController) {
                if (!seedLineItem.quantityPlanted) return '';
                return (seedLineItem.quantityPlanted + '') + ' ' + (seedLineItem.quantityPlanted ? seedLineItem.quantityUnitOfMeasurement : '');
            } else if (this.parentVm instanceof Controllers.SeedHarvestRecordController) {
                if (!seedLineItem.quantityHarvested) return '';
                return (seedLineItem.quantityHarvested + '') + ' ' + (seedLineItem.quantityHarvested ? seedLineItem.quantityUnitOfMeasurement : '');
            } else {
                throw new Error('Unhandled SeedCropLineItemType: ' + getFnName(seedLineItem.constructor));
                //return null;
            }
        }

        varietyFilter(item: Models.Variety): boolean {
            return item.active;
        }

        // allowed unless editing a destination
        toggleLineItem(lineItem: Models.SeedPlantingLineItem & Models.SeedHarvestLineItem) {
            if (this.parentVm instanceof Controllers.SeedHarvestRecordController) {
                this._setAvailableSeedPlantingLineItems(lineItem.id);
            }

            return !this.parentVm.editingLineItem;
        }

        private _setAvailableSeedPlantingLineItems(harvestLineItemId: number) {
            this.availableSeedPlantingLineItems = [];
            if (this.record.seedPlantingRecord && this.record.seedPlantingRecord.lineItems) {
                for (var x of this.record.seedPlantingRecord.lineItems) {
                    // Plantings can be used more than once, so include all
                    this.availableSeedPlantingLineItems.push(x);
                }
            }
        }

        private addLineItem() {
            this.parentVm.editingLineItem = true;

            let lineItem: any = (this.parentVm instanceof Controllers.SeedPlantingRecordController) ? new Models.SeedPlantingLineItem() : new Models.SeedHarvestLineItem();

            lineItem.lineItemNumber = this.record.nextLineItemNumber;
            lineItem['currentlyEditing'] = true;

            this.record.lineItems.push(lineItem);

            if (this.record.recordType === 'seedharvest') {
                this._setAvailableSeedPlantingLineItems(lineItem.id);
                // if only one, select it
                if (this.availableSeedPlantingLineItems.length === 1) {
                    lineItem.SeedPlantingLineItem = this.availableSeedPlantingLineItems[0];
                }
            }
        }

        get lineItems(): Models.SeedPlantingLineItem[] & Models.SeedHarvestLineItem[] { return this.record.lineItems; }

        private _prepareForAddEdit() {
            this.parentVm.editingLineItem = true;
            this.lineItems.forEach(li => { li['currentlyEditing'] = false; });
        }

        resetRecord() {
            let pristineRecord = angular.copy(this.record.copy);
            this.record.load(pristineRecord);
            this.parentVm.editingLineItem = false;
            this.parentVm.errors = null;
        }

        saveLineItem() {
            return this.parentVm.record.save(true).then(() => {
                this.parentVm.editingLineItem = false;
                this.toaster.success('Line Item Saved');
                this.parentVm.errors = null;
            }, (errors) => {
                if (errors.length) {
                    this.parentVm.errors = errors;
                }
                this.toaster.error('Line Item Save Failed');
                return this.$q.reject(errors);
            });
        }

        editLineItem(lineItem: { id: number, currentlyEditing: boolean }) {
            this._prepareForAddEdit();

            if (this.parentVm instanceof Controllers.SeedHarvestRecordController) {
                this._setAvailableSeedPlantingLineItems(lineItem.id);
            }

            lineItem.currentlyEditing = true;
        }

        deleteLineItem(lineItem: Models.SeedPlantingLineItem & Models.SeedHarvestLineItem) {
            // note that _.findIndex doesn't like the intersect type
            for (var idx = 0; idx < this.lineItems.length; idx++) {
                if (this.lineItems[idx] === lineItem) {
                    break;
                }
            }

            this.lineItems.splice(idx, 1);
            this.parentVm.save();
        }

        canAddLineItem(): boolean {
            if (this.parentVm.isInEditMode) {
                return false;
            } else if (this.parentVm.editingLineItem) {
                return false;
            } else if (!this.record.record || !this.record.record.canEdit) {
                return false;
            } else {
                return true;
            }
        }

        canEditLineItem(lineItem: { currentlyEditing: boolean }) {
            if (!this.record.record.canEdit) {
                return false;
            }
            if (this.parentVm.isInEditMode) {
                return false;
            }
            if (this.parentVm.editingLineItem) {
                return false;
            }
            if (lineItem.currentlyEditing) {
                return false;
            }
            return true;
        }
     
        static $inject = ['$scope', 'toaster', '$q'];
        constructor(
            private $scope: ng.IScope,
            private toaster: ng.toaster.IToasterService,
            private $q: ng.IQService) {
            this.parentVm = $scope['vm'];
            this.record = this.parentVm.record;
        }

    }

    export function SeedCropLineItemsDirective() {

        return {
            restrict: 'E',
            controller: SeedCropLineItemsController,
            controllerAs: 'lim',
            bindToController: true
        }
    }
}

angular.module('app').directive('seedCropLineItems', App.Directives.SeedCropLineItemsDirective);