module App.Services {
    export class RecordService {
        private _muted: boolean;
        private _message: string;

        getRecords<T extends Models._BaseRecord>(modelType?: { new (): T; }, recordType?: string, filters: Models.Filters = null): ng.IPromise<any[]> {
            var recordsUrl = (recordType && recordType != 'all') ? recordType + 'Records' : 'records';
            return this.ApiService.getItems<T>({ entity: recordsUrl, messageProperty: 'records', modelType: modelType, filters: filters });
        }

        getRecord<T extends Models._BaseRecord>(id: number, modelType: { new (): T; }, recordType: string): ng.IPromise<T> {
            var recordUrl = recordType + 'Records';
            return this.ApiService.getItem<T>({ entity: recordUrl, messageProperty: 'records', id: id, modelType: modelType });
        }

        // Only used by Shipment
        getRecordReport(recordType: string, recordId: number): ng.IPromise<any>  {
            let url = recordType + 'report/' + recordId;
            return this.ApiService.getPDFReport(url);
        }

        // Only used by Processing
        verifyCleaningAfterProcessing(record): ng.IPromise<any> {
            return this.ApiService.post(record.recordType + 'records/verifycleaningafterprocessing/' + record.id, record, true);
        }

        // Only used by Post Harvest
        calculateVolunteerMonitoringDates(siteId: number): ng.IPromise<any> {
            return this.ApiService.get('postharvestrecords/calculatevolunteermonitoringdates/' + siteId);
        }

        signRecord(record) {
            return this.ApiService.post(record.recordType + 'records/sign/' + record.id, record, true);
        }

        unlockRecord(record) {
            return this.ApiService.post(record.recordType + 'records/unlock/' + record.id, record, true);
        }

        archiveRecordsUpdate(strippedDownRecords: { id: number; isArchived: boolean }[]): ng.IPromise<{ id: number; isArchived: boolean }[]> {
            return this.ApiService.post('records/archive', strippedDownRecords)
                .then((response) => {
                    if (response !== undefined && response !== null) {
                        if (response === 0) 
                            var msg = 'No Reports Updated';
                        else if (response === 1)
                            msg = response + ' Report Updated';
                        else
                            msg = response + ' Reports Updated';

                        this.toaster.success(msg);
                    }
                    else {
                        this.toaster.warning('No Reports Updated');
                    }
                },
                reason => this._error(reason)).finally(this._finally);
        }

        archiveRecordsBulk(recordType: string, startDate: Date, endDate: Date) {
            let s: string = (startDate) ? startDate.toISOString().slice(0, 10).replace(/-/g, '') : null;
            let e: string = (endDate) ? endDate.toISOString().slice(0, 10).replace(/-/g, '') : null;
            return this.ApiService.post('records/archive?recordType=' + recordType + '&startDate=' + s + '&endDate=' + e, true)
                .then((response) => {
                    if (response !== undefined && response !== null) {
                        let msg = 'Archived ' + response + ' Report';
                        if (response !== 1) msg += 's';
                        this.toaster.success(msg);
                    }
                    else {
                        this.toaster.warning('Archived 0 Reports');
                    }
                },
                reason => this._error(reason)).finally(this._finally);
        }

        edit<T extends Models._BaseRecord>(record: T, mute = false): ng.IPromise<T> {
            this._muted = mute;
            this._message = 'Report Saved';
            var recordUrl = record.recordType + 'Records';
            return this.ApiService.editItem<T>(recordUrl, record).then(record => this._success(record), reason => this._error(reason)).finally(this._finally);
        }

        add<T extends Models._BaseRecord>(record: T, mute = false): ng.IPromise<T> {
            this._muted = mute;
            this._message = 'Report Created';
            var recordUrl = record.recordType + 'Records';
            return this.ApiService.addItem<T>(recordUrl, record).then(record => this._success(record), reason => this._error(reason)).finally(this._finally);
        }

        remove<T extends Models._BaseRecord>(record: T, mute = false): ng.IPromise<T> {
            this._muted = mute;
            this._message = 'Report ' + record.number + ' Deleted';
            var recordUrl = record.recordType + 'Records';
            return this.ApiService.removeItem<T>(recordUrl, record).then(record => this._success(record), reason => this._error(reason)).finally(this._finally);
        }

        private _success<T>(Record: T): T {
            if (!this._muted) {
                this.toaster.success(this._message ? this._message : 'Done!');
            }
            return Record;
        }

        private _error = (reason) => {
            if (reason.data && reason.data.errors) {
                return (this.$q.reject(reason.data.errors));
            }
            return (this.$q.reject(reason));
        }

        private _finally = () => {
            this._muted = false;
            this._message = null;
        }

        static ID = 'RecordService';
        static $inject = ['ApiService', 'toaster', '$q'];
        constructor(private ApiService: ApiService, private toaster: ng.toaster.IToasterService, private $q: ng.IQService) {
            Models._BaseRecord.prototype.recordService = this;
        }
    }
    angular.module('app').service('RecordService', RecordService);
}