module App.Controllers {
    export class SeedHarvestRecordController extends BaseRecordController<Models.SeedHarvestRecord> {
        model = Models.SeedHarvestRecord;

        record: Models.SeedHarvestRecord;

        seedPlantingRecords: Models.SeedPlantingRecord[];

        editingLineItem: boolean;

        controls: {
            disposalMethods: any[];
        }

        disposalNotesLabel: string = 'Disposal Notes';

        clearWasteQuestions() {
            this.record.WasteDisposalMethod = null;
            this.record.wasteProperlyDisposed = null;
            this.record.wasteDisposalNotes = null;
        }

        static $inject = ['record', 'accounts', 'controls', 'RecordService', '$state', '$scope', '$modal', 'toaster'];
        constructor(
            record: Models.SeedHarvestRecord,
            accounts: Models.Account[],
            controls,
            private RecordService: Services.RecordService,
            $state: ng.ui.IStateService,
            $scope: ng.IScope,
            $modal: ng.ui.bootstrap.IModalService,
            toaster: ng.toaster.IToasterService
        ) {
            super(record, accounts, controls, $state, $modal, toaster);

            this.record.WasteDisposalMethod = <Deregulated.IDisposalMethod>_.findWhere(controls.disposalMethods, { id: record.wasteDisposalMethodId });

            // Add watch to Site (this implies Account and Location are selected) to drive Seed Planting Report options
            /**
             * watch record.siteId for changes and get Seed Planting options
             */
            
            $scope.$watch(() => {
                return this.record.record.siteId;
            }, (newSiteId, oldSiteId) => {
                if (newSiteId) {
                    this.RecordService
                        .getRecords(Models.SeedPlantingRecord, 'seedplanting', { siteId: newSiteId, includeExistingForSeedHarvest: this.record.id })
                        .then(seedPlantingRecords => {
                            this.seedPlantingRecords = seedPlantingRecords;
                            if (parseInt(newSiteId) !== parseInt(oldSiteId)) {
                                if (this.seedPlantingRecords.length === 1) {
                                    this.record.seedPlantingRecord = this.seedPlantingRecords[0];
                                }
                            }
                        });
                }
                else {
                    this.seedPlantingRecords = [];
                    this.record.seedPlantingRecord = null;
                }
            });
            
            $scope.$watch(() => {
                return this.record.WasteDisposalMethod;
            }, (newValue: Deregulated.IDisposalMethod, oldValue) => {
                this.disposalNotesLabel = BaseRecordController.getDisposalNotesLabel(newValue);
            });

        }

        /**
         * Resolve all promises before loading the controller
         * 
         * @Param isCreateMode  boolean
         */
        resolve(isCreateMode = false): { record: Function; accounts: Function; controls: Function; } {
            getRecord.$inject = ['$stateParams', 'RecordService'];
            function getRecord($stateParams: ng.ui.IStateParamsService, RecordService: Services.RecordService): Models.SeedHarvestRecord | ng.IPromise<Models.SeedHarvestRecord> {
                if (isCreateMode) {
                    return new Models.SeedHarvestRecord().load({});
                } else {
                    return RecordService.getRecord<Models.SeedHarvestRecord>($stateParams['id'], Models.SeedHarvestRecord, 'seedharvest');
                }
            }

            getAccounts.$inject = ['AccountService', '$rootScope'];
            function getAccounts(AccountService: Services.AccountService, $rootScope: ng.IRootScopeService): Models.Account[] | ng.IPromise<Models.Account[]> {
                if ($rootScope.user.can.viewAccountList) {
                    return AccountService.getAccounts({ 'recordTypeName': 'SeedHarvest' });
                }
                else {
                    return [$rootScope.user.account];
                }
            }

            getControls.$inject = ['LookupService', '$q'];
            function getControls(LookupService: Services.LookupService, $q: ng.IQService) {
                return $q.all([LookupService.getDisposalMethods(), LookupService.getFieldGenerations(), LookupService.getGenericLookup('SigningMessage', true)]).then((data) => {
                    return {
                        disposalMethods: data[0],
                        fieldGenerations: data[1],
                        signingMessage: data[2]
                    };
                });
            }

            return {
                record: getRecord,
                accounts: getAccounts,
                controls: getControls
            }
        }
    }
}