module App.Controllers {
    export class AccountLocations {
        locations: Deregulated.ILocation[];
        selectedLocation: Deregulated.ILocation;

        cancel(): void {
            this.$modalInstance.dismiss('cancel');
        }

        ok(row): void {
            this.$modalInstance.close(row);
        }

        static $inject = ['locations', '$modalInstance'];
        constructor(
            locations,
            private $modalInstance: ng.ui.bootstrap.IModalServiceInstance
        ) {
            this.locations = locations || [];
        }

        static config = {
            animation: true,
            templateUrl: 'views/account-select-location.html',
            controller: AccountLocations,
            controllerAs: 'modal',
            resolve: null,
            size: 'lg'
        }

        static getControllerConfig(locations: Deregulated.ILocation[]) {
            this.config.resolve = {
                locations: function () {
                    return locations;
                }
            };

            return this.config;
        }
    }
}