module App.Models
{
    export class Account implements Abstractions.IDataLoader{

        id: number;
        name: string;
        number: string;
        website: string;
        typeId: number;
        type: Deregulated.ILookup;
        contactInformation: Deregulated.IContactInformation;
        locations: Deregulated.ILocation[];
        users: Deregulated.IUser[];
        isSimplotCompany: boolean;
        includesLateBlightProtectedVariety: boolean;
        seasonalityStatus: string;

        copy: Models.Account;

        AccountService: App.Services.AccountService;

        /**
         * Smart Table Filter Properties
         */
        typeName: string;

        save(mute = false)
        {
            var saveAccount: ng.IPromise<Models.Account> = this.id ? this.AccountService.edit(this, mute) : this.AccountService.add(this, mute);
            return saveAccount.then((Account) =>
            {
                var accountId = Account.id || this.id;
                return this.AccountService.getAccount(accountId).then((_account) => {
                    this.load(_account);

                    return _account;
                });
            });
        }

        remove()
        {
            return this.AccountService.remove(this);
        }

        reset()
        {
            this.load(this.copy);
        }

        load(data: {})
        {
            this.copy = <Models.Account>angular.copy(data);
            for (var prop in data)
            {
                if (data.hasOwnProperty(prop))
                {
                    if (prop === 'type' && data[prop]) {
                        this.typeName = data[prop].name;
                    }
                    if (prop === 'contactInformation') {
                        data[prop] = new Models.ContactInformation(data[prop]);
                    }
                    if (prop === 'locations' && data[prop].length) {
                        data[prop] = <any[]>data[prop].map((location: Deregulated.ILocation) => {
                            location.contactInformation = new Models.ContactInformation(location.contactInformation);
                            return location;
                        });
                    }
                    if (prop === 'users' && data[prop].length) {
                        data[prop] = <any[]>data[prop].map((user: Models.User) => {
                            user = new Models.User().load(user);
                            return user;
                        });
                    }

                    this[prop] = data[prop];
                }
                if (!this.contactInformation) {
                    this.contactInformation = new Models.ContactInformation();
                }
            }
            if (!this.contactInformation) {
                this.contactInformation = new Models.ContactInformation();
            }

            return this;
        }
    }
}